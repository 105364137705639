import { Divider } from "@mui/material";
import React, { useState } from "react";
import styles from "../../styles/Views.module.css";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";

const ViewsCPS = ({
  setViewFilter,
  setTables,
  setColumnsFilter,
}) => {
  const [selectedParagraph, setSelectedParagraph] = useState("tabla-principal");

  const handleViews = (e) => {
    let view = e.currentTarget.getAttribute("value");
    setSelectedParagraph(view);
    setColumnsFilter({ id: false });
    switch (view) {
      case "tabla-principal":
        setColumnsFilter({
          revenueRetargeting: false,
          id: false,
        });
        setViewFilter({ items: [] });
        setTables(["Offer ID", "Pixel Integration", "Inactive"]);
        break;
      case "afiliados":
        setViewFilter({
          items: [
            {
              id: 1,
              field: "offerStatus",
              operator: "isAnyOf",
              value: ["ACTIVE", "RUNNING", "AON", "BAT/PREVIEW"],
            },
          ],
        });
        setColumnsFilter({
          id: false,
          affiseLink: false,
          offerStatus: false,
          advertiserName: false,
          BAT_Preview: false,
          revenue: false,
          monedaAnunciante: false,
          csmStatus: false,
          typeOfClient: false,
          affiliacionStatus: false,
          encargadaCsm: false,
          threads: false,
          pixelStatus: false,
          revMaximo: false,
          revenueRetargeting: false,
        });
        setTables(["Offer ID"]);
        break;
      case "bsuero@aluna.co":
        setViewFilter({
          items: [
            {
              id: 1,
              field: "offerStatus",
              operator: "isAnyOf",
              value: [
                "ACTIVE",
                "RUNNING",
                "AON",
                "PAUSED",
                "BAT/PREVIEW",
                "NOT PROFITABLE",
                "PAUSED - CAP REACHED",
              ],
            },
          ],
        });
        setColumnsFilter({
          id: false,
          revenue: false,
          monedaAnunciante: false,
          revenueRetargeting: true,
        });
        setTables(["Offer ID"]);
        break;
      case "fcasado@aluna.co":
        setViewFilter({
          items: [
            {
              field: "encargadaCsm",
              operator: "equals",
              value: "Felicitas Casado",
            },
          ],
        });
        setColumnsFilter({
          id: false,
          revenueRetargeting: true,
        });
        setTables(["Offer ID", "Inactive"]);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.globalContainer}>
      <div
        className={`${styles.paragraph} ${
          selectedParagraph === "tabla-principal" ? styles.selected : ""
        }`}
        style={{ display: "flex" }}
      >
        <CottageOutlinedIcon
          sx={{ marginRight: "6px", fontSize: "20px", marginTop: "2px" }}
        />
        <p value="tabla-principal" onClick={(e) => handleViews(e)}>
          Tabla principal
        </p>
      </div>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="afiliados"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedParagraph === "afiliados" ? styles.selected : ""
        }`}
      >
        PARA AFILIADOS
      </p>
      <p
        value="bsuero@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedParagraph === "bsuero@aluna.co" ? styles.selected : ""
        }`}
      >
        Belén
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />

      <p
        value="fcasado@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedParagraph === "fcasado@aluna.co" ? styles.selected : ""
        }`}
      >
        Feli
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
    </div>
  );
};

export default ViewsCPS;
