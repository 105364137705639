import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemButton,
  ListItemText,
  Menu,
  Modal,
  Badge,
} from "@mui/material";
import { useRef, useState, memo, useContext, useEffect } from "react";
import {
  postReporteInvalidacion,
  deleteReporteInvalidacion,
} from "../../utils/ApiCall";
import { UserContext } from "../../UserContext";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FileViewer from "./FileViewer";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { IconButton, Breadcrumbs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NoRowsOverlay from "./Loading";
import { FileUploader } from "react-drag-drop-files";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { saveAs } from "file-saver";
import styles from "../../styles/Threads.module.css";
import CustomFileUploader from "./CustomFileUploader";
import { styled } from "@mui/material/styles";
import { fileTypes } from "../../json/FileTypes";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid var(--blueTwo)`,
    padding: "0 4px",
    backgroundColor: "var(--blueTwo)",
    cursor: "pointer",
  },
}));

const ReporteInvalidaciones = memo(
  ({
    value,
    row,
    backendUrl,
    initialValue,
    pathname,
    typeColumn,
    field,
    deleteTypeColumn,
    fileCount,
  }) => {
    const [windowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 600;
    const [{ open, selectedItem }, setState] = useState({
      open: false,
      selectedItem: null,
    });
    const [loadingFile, setLoadingFile] = useState(false);

    const [reporteInvalidacion, setReporteInvalidacion] =
      useState(initialValue);
    const [modalImage, setModalImage] = useState("");
    const [iconColor] = useState("var(--grayTwo)");
    const [currentFileCount, setCurrentFileCount] = useState(fileCount);

    const cellRef = useRef(null);

    const { key, setKey } = useContext(UserContext);
    useEffect(() => {
      setKey(localStorage?.getItem("jwt"));
    }, [setKey]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event, item) => {
      setAnchorEl(event.currentTarget);
      setState((prevState) => ({
        ...prevState,
        open: true,
        selectedItem: item,
      }));
    };
    const handleClose = () => {
      setAnchorEl(null);
      setState((prevState) => ({ ...prevState, open: false }));
    };

    const menuStyle = {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      width: 540,
      maxHeight: 500,
      overflowY: "scroll",
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: "49%",
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    };

    const menuProps = {
      anchorEl,
      id: "account-menu",
      open,
      onClose: handleClose,
      PaperProps: {
        elevation: 0,
        sx: {
          ...menuStyle,
          marginLeft:
            isMobile || field === "purchaseOrder"
              ? "140px"
              : field === "receipt"
              ? "0"
              : "200px",
        },
      },
      transformOrigin: { horizontal: "right", vertical: "top" }, //vertical en bottom pone el menu arriba de la celda
      anchorOrigin: { horizontal: "right", vertical: "bottom" }, //vertical en top acomoda el menu en el borde top de la celda. si esta en bottom hace overlaping
    };

    const handleMenuOpen = () => {
      cellRef?.current?.getBoundingClientRect();
    };

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      // Actualiza el estado del conteo de archivos solo si no hay una acción de agregar o eliminar archivos
      if (fileCount === currentFileCount) {
        setCurrentFileCount(fileCount);
      }
    }, [fileCount, currentFileCount]);

    const handleUploadFile = async (file) => {
      setLoadingFile(true);
      const fileJoined = Array.from(files).concat(Array.from(file));
      setFiles(fileJoined);
      const newFiles = Array.from(file);
      const showFiles = selectedFile.concat(newFiles);
      const response = await postReporteInvalidacion(
        key,
        showFiles,
        row.id,
        pathname,
        typeColumn,
        field
      );
      setSelectedFile(file);
      setReporteInvalidacion(response.data[field]);
      setLoadingFile(false);
      setCurrentFileCount((prevCount) => prevCount + newFiles.length);
    };

    const handleDelete = async (e) => {
      const response = await deleteReporteInvalidacion(
        e,
        row.id,
        pathname,
        deleteTypeColumn,
        field
      );
      setSelectedFile(e);
      setReporteInvalidacion(response.data[field]);
      setCurrentFileCount((prevCount) => prevCount - 1);
    };

    const handleOpen = (item) => {
      setState((prevState) => ({
        ...prevState,
        open: true,
        selectedItem: item,
      }));
      setOpenModal(true);
      setModalImage(item);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
      setSelectedFile([]);
      // setFiles([])
    };

    useEffect(() => {
      setSelectedFile([]);
    }, [reporteInvalidacion]);

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      overflowX: "hidden",
      bgcolor: "#fcfaff",
      borderRadius: "25px",
      boxShadow: 24,
      alignItems: "center",
      width: "95vw",
      height: "95vh",
    };

    const handleDownload = async () => {
      const { url, name } = modalImage;
      const fileUrl = `${backendUrl}${url}`;
      saveAs(fileUrl, name);
    };

    return (
      <>
        <div className="cellStyle" onClick={handleClick} ref={cellRef}>
          <StyledBadge badgeContent={currentFileCount} color={"info"} max={99}>
            <AttachFileOutlinedIcon
              style={{ color: iconColor, cursor: "pointer" }}
            />
          </StyledBadge>
        </div>

        <Menu {...menuProps} onMouseEnter={handleMenuOpen}>
          <Grid container spacing={1} direction="column">
            {loadingFile && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1000,
                }}
              >
                <NoRowsOverlay />
              </div>
            )}
            {reporteInvalidacion &&
              reporteInvalidacion.map((el, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ListItemButton
                    selected={selectedFile === el.name}
                    style={{
                      padding: "16px",
                      marginRight: "24px",
                      borderRadius: "2px",
                      color: "black",
                      backgroundColor: "var(--blueLow)",
                    }}
                  >
                    <ListItemText
                      onClick={() => handleOpen(el)}
                      primary={el.name}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    <Button onClick={() => handleDelete(el.id)}>
                      <DeleteIcon />
                    </Button>
                  </ListItemButton>
                </Grid>
              ))}
            <Divider sx={{ margin: "60px 24px 0px 12px" }} />
            <div
              style={{
                marginTop:
                  row?.reporteInvalidacion?.length > 0 ? "10px" : "6px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FileUploader
                  handleChange={handleUploadFile}
                  name="file"
                  types={fileTypes}
                  multiple={true}
                  label="Elegir archivos o arrastrar aqui"
                  hoverTitle="Arrastrar aqui"
                  className="fileUpload"
                  children={<CustomFileUploader isMobile={isMobile} />}
                />
              </div>
            </div>
          </Grid>
        </Menu>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          sx={{
            "& .MuiPopover-root": {
              zIndex: "1000",
            },
          }}
        >
          <Box sx={style}>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  backgroundColor: "white",
                  borderBottom: "1px solid #dee2ff",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  zIndex: "10",
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: "var(--blue)", fontSize: "50px" }}
                />
                <div style={{ marginLeft: "8px" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ fontWeight: "600" }}>{selectedItem?.name}</p>
                    <CloudDownloadOutlinedIcon
                      onClick={handleDownload}
                      className={styles.downloadIcon}
                    />
                  </div>
                  <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" separator="›">
                      <div underline="hover" color="inherit" href="/" disabled>
                        {pathname}
                      </div>
                      <div underline="hover" color="inherit" href="/">
                        {row.offerID}
                      </div>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <IconButton onClick={() => setOpenModal(false)}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {selectedItem && (
                  <div style={{ flex: "1" }}>
                    <FileViewer
                      key={selectedItem.name}
                      url={`${backendUrl}${selectedItem.url}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  }
);

export default ReporteInvalidaciones;
