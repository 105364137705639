import { Box, Card, CardContent, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { format } from "date-fns";

const LineChartComparison = ({
  xAxisData,
  series,
  isLoading,
  progress,
  timeIcon,
  chartData,
}) => {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (chartData?.lastUpdated) {
      const date = new Date(chartData?.lastUpdated);
      const formattedDate = format(date, "yyyy-MM-dd 'at' hh:mm a");
      setFormattedDate(formattedDate);
    }
  }, [chartData]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          borderRadius: "10px",
          minHeight: "120px",
          padding: "8px",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            width: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: isLoading ? "center" : "space-between",
            alignItems: isLoading ? "center" : "inherit",
          }}
        >
          {isLoading ? (
            <Box>{progress}</Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  sx={{ fontSize: 12 }}
                  color="text.secondary"
                  display="flex"
                >
                  {timeIcon} {formattedDate}
                </Typography>
              </Box>
              <LineChart
                xAxis={[{ scaleType: "point", data: xAxisData }]}
                series={series}
                responsive
              />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default LineChartComparison;
