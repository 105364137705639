import React, { useEffect, useState } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { fetchData } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { infoTraficoAfiliadoStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/ColumnStatus.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple.jsx";
import EditableSelectCountry from "../../../../components/global/EditableSelectCountry.jsx";
import { sortAlphabetically } from "../../../../json/CampaignCreator.js";
import { continents } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const InfoTraficoAfiliado = () => {
  const [groups] = useState(["Afiliado"]);
  const apiUrl = `${backendUrl}/api/info-trafico-afiliado/items`;
  const apiService = `${backendUrl}/api/offer-service`;
  const [status, setStatus] = useState("Afiliado");
  const [selectionRow, setSelectionRow] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [affiliatesManager, setAffiliatesManager] = useState([]);
  const [trafficSources, setTrafficSources] = useState([]);
  const [items, setItems] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });

  const usernamesSet = new Set();

  useEffect(() => {
    const fetchApi = async () => {
      const resSources = await fetchData(`${apiService}/getSources`);
      setTrafficSources(sortAlphabetically(resSources, "title"));
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    items.forEach((item) => {
      usernamesSet.add(item.owner.username);
    });
    const usernamesUnicos = [...usernamesSet];
    setAffiliatesManager(usernamesUnicos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Afiliado" ? items : [];
  };

  const filterItemsByStatus = (items, status, condition) => {
    if (condition) {
      return items?.filter((item) => item.status === status);
    }
    return items?.filter((item) => item.status !== status);
  };

  const filterItemsAndSetGroups = (items) => {
    setItems(filterItemsByStatus(items, "Afiliado", false));
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "owner.username",
      headerName: "Affiliate Manager",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: affiliatesManager,
      valueGetter: (params) => params.row.owner.username,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={infoTraficoAfiliadoStatus}
        />
      ),
    },
    {
      field: "vertical",
      headerName: "Verticales",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"vertical"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.vertical}
        />
      ),
    },
    {
      field: "country",
      headerName: "Geos Habilitadas",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={continents}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Tipo de Compra",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPL", "CPI", "CPC", "CPM", "CPS", "CPA"]}
          getOptionLabelFunc={(client) => client}
          initialValue={params.row.conversionType}
          label="Tipo de Compra"
        />
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Source",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={trafficSources.map((item) => item.title)}
          getOptionLabelFunc={(traffic) => traffic}
          initialValue={params.row.trafficSources}
          label="Traffic Source"
        />
      ),
    },
    {
      field: "wishList",
      headerName: "Wishlist de campañas",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"wishList"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.wishList}
        />
      ),
    },
    {
      field: "runningCampaigns",
      headerName: "Campañas que corre",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"runningCampaigns"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.runningCampaigns}
        />
      ),
    },
    {
      field: "additionalComments",
      headerName: "Comentarios Adicionales",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"additionalComments"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.additionalComments}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        viewFilter={viewFilter}
        setViewFilter={setViewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default InfoTraficoAfiliado;
