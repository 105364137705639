import React, { Suspense } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { useState, useEffect } from "react";
import {
  editBoardField,
  duplicateRowsValidaciones,
  fetchData,
} from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import ReporteInvalidaciones from "../../../../components/global/ReporteInvalidaciones.jsx";
import { validacionesStatus } from "../../../../json/StatusOptions.js";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/ColumnStatus.jsx";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import EditableField from "../../../../components/global/EditableField.jsx";
import EditableSelect from "../../../../components/global/EditableSelect.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple.jsx";
import { Currencies } from "../../../../json/Currencies.js";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { ThemeProvider } from "styled-components";
import { Button, createTheme } from "@mui/material";
import { encargadaCsm } from "../../../../json/CampaignCreator.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const Validaciones = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const location = useLocation();
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const tableByParams = searchParams.get("table");
  const [statusByParams] = useState(tableByParams);
  const [status, setStatus] = useState(statusByParams ?? currentYear);
  const apiUrl = `${backendUrl}/api/validaciones/items?table=202`;
  const [totalBill, setTotalBill] = useState("");
  const [groups] = useState([currentYear, "Previous year"]);
  const [items, setItems] = useState([]);
  const [itemsDone, setItemsDone] = useState([]);
  const [selectionRow, setSelectionRow] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: [],
  });

  const [posting, setPosting] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [monthSpanish, setMonthSpanish] = useState("");
  const [year, setYear] = useState("");
  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    const monthsSpanish = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const today = new Date();
    const monthNumber = today.getMonth() - 1;

    setMonthSpanish(monthsSpanish[monthNumber]);
    setYear(today.getFullYear());

    const fetchApi = async () => {
      const resAdvertisers = await fetchData(
        `${backendUrl}/api/advertiser/getAllAdvertisers`
      );
      setAdvertisers(resAdvertisers);
    };

    const fetchDataAndUpdate = async () => {
      // call the function
      await fetchApi()
        // make sure to catch any error
        .catch(console.error);

      setTimeout(window.unlock, 100);
    };
    fetchDataAndUpdate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsByYear = (items, year, condition) => {
    if (condition) {
      return items?.filter((item) => item.table.includes(year));
    }
    return items?.filter((item) => !item.table.includes(year));
  };

  const filterItemsAndSetGroups = (items) => {
    setItemsDone(filterItemsByYear(items, currentYear, false));
    setItems(filterItemsByYear(items, currentYear, true));
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  function generarNombresClientesActivos() {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const fechaActual = new Date();
    const resultado = [];

    for (let i = 0; i < 12; i++) {
      const mesActual = fechaActual.getMonth() - i;
      const añoActual = fechaActual.getFullYear();
      const ajusteAño = mesActual < 0 ? -1 : 0;

      let nombreMes = meses[(mesActual + 12) % 12];
      let nombreCompleto = `Clientes Activos ${nombreMes} ${
        añoActual + ajusteAño
      }`;

      resultado.push(nombreCompleto);
    }

    return resultado;
  }

  const opcionesMeses = generarNombresClientesActivos();

  const getAdvertiserValue = (field) => {
    let values = [];

    advertisers.forEach((advertiser) => {
      let value = advertiser[`${field}`];
      values.push(value);
    });
    return values;
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "table",
      headerName: "Tabla",
      width: 250,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"table"}
          handleEditResponseChange={handleEditResponseChange}
          options={opcionesMeses}
          spanText={params.row.table}
          initialValue={params.row.table}
          label="Table"
        />
      ),
    },
    {
      field: "name",
      headerName: "Advertiser",
      width: 160,
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          options={getAdvertiserValue("name")}
          spanText={params.row.name}
          initialValue={params.row.name}
          label="Advertisers"
        />
      ),
    },
    {
      field: "businessUnit",
      headerName: "Business unit",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"businessUnit"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.businessUnit}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "typeOfClient",
      headerName: "Tipo de Cliente",
      width: 120,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"typeOfClient"}
          handleEditResponseChange={handleEditResponseChange}
          options={["A", "B", "C"]}
          spanText={params.row.typeOfClient}
          initialValue={params.row.typeOfClient}
          label="Tipo de Cliente"
        />
      ),
    },
    {
      field: "encargadaCsm",
      headerName: "Encargada CSM",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"encargadaCsm"}
          handleEditResponseChange={handleEditResponseChange}
          options={encargadaCsm.map((encargada) => encargada.name)}
          getOptionLabelFunc={(encargada) => encargada}
          initialValue={params.row.encargadaCsm}
          label="Encargada CSM"
        />
      ),
    },
    {
      field: "status",
      headerName: "Estado",
      width: 280,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"status"}
          columnWidth={"900px"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={validacionesStatus}
        />
      ),
    },
    {
      field: "comments",
      headerName: "Comentario",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"comments"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.comments}
        />
      ),
    },
    {
      field: "totalBill",
      headerName: "Total a Facturar",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderEditCell: (params) => (
        <EditableField {...params} setState={setTotalBill} />
      ),
      editable: "true",
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"currency"}
          handleEditResponseChange={handleEditResponseChange}
          options={Currencies}
          spanText={params.row.currency}
          initialValue={params.row.currency}
          label={"Currency"}
        />
      ),
    },
    {
      field: "detailClient",
      headerName: "Detalle Cliente",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Suspense fallback={<p>Cargando</p>}>
          <ReporteInvalidaciones
            {...params}
            backendUrl={backendUrl}
            initialValue={params?.row?.detailClient}
            pathname={pathname}
            typeColumn={"postColumnMedia"}
            field={"detailClient"}
            deleteTypeColumn={"deleteColumnMedia"}
            fileCount={params?.row?.detailClient?.length || 0}
          />
        </Suspense>
      ),
    },
    {
      field: "socialReason",
      headerName: "Razón Social",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"socialReason"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params?.row?.socialReason}
        />
      ),
    },
    {
      field: "testigos",
      headerName: "Testigos",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Suspense fallback={<p>Cargando</p>}>
          <ReporteInvalidaciones
            {...params}
            backendUrl={backendUrl}
            initialValue={params?.row?.testigos}
            pathname={pathname}
            typeColumn={"postColumnMedia"}
            field={"testigos"}
            deleteTypeColumn={"deleteColumnMedia"}
            fileCount={params?.row?.testigos?.length || 0}
          />
        </Suspense>
      ),
    },
    {
      field: "purchaseOrder",
      headerName: "Orden de Compra",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Suspense fallback={<p>Cargando</p>}>
          <ReporteInvalidaciones
            {...params}
            backendUrl={backendUrl}
            initialValue={params?.row?.purchaseOrder}
            pathname={pathname}
            typeColumn={"postColumnMedia"}
            field={"purchaseOrder"}
            deleteTypeColumn={"deleteColumnMedia"}
            fileCount={params?.row?.purchaseOrder?.length || 0}
          />
        </Suspense>
      ),
    },
    {
      field: "receipt",
      headerName: "Factura",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Suspense fallback={<p>Cargando</p>}>
          <ReporteInvalidaciones
            {...params}
            backendUrl={backendUrl}
            initialValue={params?.row?.receipt}
            pathname={pathname}
            typeColumn={"postColumnMedia"}
            field={"receipt"}
            deleteTypeColumn={"deleteColumnMedia"}
            fileCount={params?.row?.receipt?.length || 0}
          />
        </Suspense>
      ),
    },
  ];

  const controlEdit = async (params) => {
    let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
    switch (params.field) {
      case "totalBill":
        await editBoardField(
          "/validaciones-cierre-aluna",
          ids,
          params.field,
          totalBill
        );
        break;
      default:
        break;
    }
  };

  const getGroupRows = (groupName) => {
    return groupName === currentYear ? items : itemsDone;
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const duplicateRows = async () => {
    setPosting(true);
    const rows = items.filter((item) => selectionRow.includes(item.id));
    await duplicateRowsValidaciones(rows);
    setRefreshState(true);
    setPosting(false);
  };

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          onClick={duplicateRows}
          disabled={posting ? posting : selectionRow.length > 0 ? false : true}
        >
          Duplicar para "Clientes Activos {monthSpanish} {year}"
        </Button>
      </ThemeProvider>
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        refreshState={refreshState}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        controlEdit={controlEdit}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default Validaciones;
