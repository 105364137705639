import {
  Alert,
  Badge,
  Box,
  Button,
  CardContent,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import inlineStyles from "../../inlineStyles/threadsStyles";
import {
  getThreads,
  handleSubmitLikes,
  handleSubmitComment,
  handleSubmitThread,
  putAnchor,
  putSeen,
  handleDeleteThread,
  handleDeleteComment,
} from "../../utils/ApiCall";
import styles from "../../styles/Threads.module.css";
import { UserContext } from "../../UserContext";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/material/styles";
import { Resizable } from "re-resizable";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import SeenBy from "../others/SeenBy";
import Swal from "sweetalert2";
import ModalImage from "./ModalImage";
import ThreadContent from "./ThreadContent";
import NewThread from "./NewThread";
import HeaderContent from "./HeaderContent";
import ActionsButtons from "./ActionsButtons";
import Comments from "./Comments";
import CommentsResponse from "./CommentsResponse";
import Media from "./Media";
import { useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Notes from "./Notes";
import ActivityLog from "./ActivityLog";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ display: value === index ? "block" : "none" }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Threads = ({ row }) => {
  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;
  const [currentRowData, setCurrentRowData] = useState(null);
  const [threads, setThreads] = useState([]);
  const [newThread, setNewThread] = useState(true);
  const [titleNewThread, setTitleNewThread] = useState("");
  const [response, setResponse] = useState(false);
  const [position, setPosition] = useState();

  const [newThreadIsClicked, setNewThreadIsClicked] = useState(false);
  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const startedThread = JSON.parse(localStorage.getItem("threadsDraft"));
  const [valueWritten, setValueWritten] = useState("");

  const [commentWritten, setCommentWritten] = useState("");
  const [iconColor, setIconColor] = useState("var(--grayTwo)");
  const { key, setKey } = useContext(UserContext);
  const [numberOfThreads, setNumberOfThreads] = useState(row?.threads?.count);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [width, setWidth] = useState(
    () => localStorage.getItem("myComponentWidth") || "50%"
  ); // initial width

  const [commentResponse, setCommentResponse] = useState(false);
  const [threadID, setThreadID] = useState(null);
  const [commentID, setCommentID] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [positionDeleteComment, setPositionDeleteComment] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const [mouseOverThread, setMouseOverThread] = useState(false);
  const [positionSeenThread, setPositionSeenThread] = useState([]);
  const [mouseOverComment, setMouseOverComment] = useState(false);
  const [positionSeenComment, setPositionSeenComment] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const updateValue = searchParams.get("update");
  const navigate = useNavigate();

  const initiatorValue = searchParams.get("initiator");
  const [threadByParams] = useState(initiatorValue);

  const [update] = useState(updateValue);

  let pathname = window.location.pathname.replace("/", "");

  let userId = localStorage.getItem("id");

  const [itemMedia, setItemMedia] = useState([]);
  const [notes, setNotes] = useState([]);

  setKey(localStorage?.getItem("jwt"));

  useEffect(() => {
    setIconColor(
      row?.threads?.length > 0 ? "var(--blueTwo)" : "var(--grayTwo)"
    );
  }, [row?.threads?.length, width, threads]);

  const handleCloseModal = () => {
    window.modalThreadOpen = false;
    setCurrentRowData(null);
    setThreads([]);
    setTitleNewThread();
    setResponse();
    setPosition();
    setNewThread(!newThread);

    if (update !== null) {
      navigate(`/${pathname}`);
    }

    if (valueWritten) {
      let id = row.id;
      let oldThreads = JSON.parse(localStorage.getItem("threadsDraft"));
      let threadsDraft = {
        ...oldThreads,
        [`${id}`]: valueWritten,
      };
      localStorage.setItem("threadsDraft", JSON.stringify(threadsDraft));
    }
  };

  const handleThreads = useCallback(
    async (id) => {
      let header;
      header = {
        Accept: "/",
        Authorization: `Bearer ${key}`,
      };

      // setModalThreadOpen(true);
      window.modalThreadOpen = true;
      setCurrentRowData(id);

      const response = await getThreads(pathname, id, header);
      setItemMedia(response.media);
      setThreads(response.threads);
      setNotes(response.notes);

      if (startedThread[`${id}`]) {
        setValueWritten(startedThread[`${id}`]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );

  useEffect(() => {
    if (+row.id === +update) {
      handleThreads(+update);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (threadByParams) {
      const element = document.getElementById(threadByParams);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [threadByParams, threads]);

  const handleFormSubmitThread = async () => {
    const response = await handleSubmitThread(
      pathname,
      key,
      file,
      row,
      titleNewThread,
      valueWritten,
      selectedUsers,
      selectedGroup
    );
    let rowThread = response.data;
    let thread = rowThread.threads;

    setThreads(thread);
    setValueWritten("");
    setTitleNewThread("");
    setFile([]);
    setSelectedFile([]);
    setNewThreadIsClicked(!newThreadIsClicked);
    setSelectedUsers([]);
    setSelectedGroup([]);
    setIconColor(thread.length > 0 ? "var(--blueTwo)" : "var(--grayTwo)");
    setNumberOfThreads(thread.length);
  };

  const [editThread, setEditThread] = useState(false);
  const [editCommentForm, setEditCommentForm] = useState(false);

  const [positionEdit, setPositionEdit] = useState();
  const [threadState, setThreadState] = useState({
    threads: [],
    currentIndex: null,
    editThread: false,
  });
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);

  const handleFormEditThread = (thread) => {
    const selectedIndex = threads.indexOf(thread);
    setPositionEdit(selectedIndex);
    setEditingThreadId(thread);
    setEditThread(true);
    setThreadState({
      threads: thread,
      currentIndex: selectedIndex,
      editThread: true,
    });

    setAnchorEl(null);
  };

  const handleFormDeleteThread = async (thread) => {
    setAnchorEl(null);
    Swal.fire({
      title: "¿Está seguro que desea eliminar este thread?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await handleDeleteThread(pathname, thread, row);

        let newThreads = response.data.threads;

        setThreads(newThreads);
        setNumberOfThreads(newThreads.length);
        setIconColor(
          newThreads.length > 0 ? "var(--blueTwo)" : "var(--grayTwo)"
        );
        setAnchorEl(null);
        Swal.fire("Tu thread ha sido eliminado", "", "success");
      }
    });
  };

  const handleFormSubmitComment = async (id) => {
    const response = await handleSubmitComment(
      pathname,
      id,
      key,
      file,
      row,
      commentWritten,
      selectedUsers,
      selectedGroup
    );

    if (response && response.data && response.data.threads) {
      const { threads } = response.data;
      setThreads(threads);
    }

    setFile([]);
    setSelectedFile([]);
    setResponse();
    setCommentWritten("");
    setSelectedUsers([]);
    setSelectedGroup([]);
  };

  const handleFormEditComment = (comment) => {
    setEditCommentForm(true);
    setEditingCommentId(comment);
  };
  const handleFormDeleteComment = async (commentId) => {
    const response = await handleDeleteComment(pathname, commentId, row);
    let newThreads = response.data.threads;
    setThreads(newThreads);
  };

  const handleFormSubmitLike = async (thread, comment) => {
    const response = await handleSubmitLikes(pathname, thread, comment, row);

    setThreads(response);
  };

  const handleUploadFile = (file) => {
    setFile(file);
    const newFiles = Array.from(file);
    const showFiles = selectedFile.concat(newFiles);
    setSelectedFile(showFiles);
  };

  const handleInputTitle = (e) => {
    setTitleNewThread(e.target.value);
  };

  const handleResponse = (e, index) => {
    const user = threads[index]?.user;
    setCommentWritten(
      `<p> <span class="mention" data-index="0" data-denotation-char="@" data-id=${user?.id} data-value=${user?.username}> <span contenteditable="false"> <span class="ql-mention-denotation-char"> @ </span>${user?.username} </span> </span> &nbsp;</p> `
    );
    setSelectedUsers([user]);
    e.preventDefault();
    setResponse(!response);
    setPosition(index);
    setCommentResponse(false);
  };

  const handleCommentResponse = (e, index, indexComment) => {
    e.preventDefault();
    const user = threads[index].comments[indexComment].user;
    setCommentWritten(
      `<p> <span class="mention" data-index="0" data-denotation-char="@" data-id=${user?.id} data-value=${user?.username}> <span contenteditable="false"> <span class="ql-mention-denotation-char"> @ </span>${user?.username} </span> </span> &nbsp;</p> `
    );
    setSelectedUsers([user]);
    setCommentResponse(!commentResponse);
    setPosition(index);
    setResponse(false);
    // if (!commentResponse) { // falla al habilitarlo, hay que revisar esto
    //   window.location.href = `#response${index}`;
    // }
  };

  const handleOnClickThread = () => {
    setNewThreadIsClicked(!newThreadIsClicked);
  };

  const handleAnchor = async (thread) => {
    const response = await putAnchor(
      thread.anchored,
      thread.id,
      pathname,
      row.id
    );

    if (response && response.data && response.data.threads) {
      const { threads } = response.data;
      // setAnchorColor(!anchored == true ? "var(--blueTwo)" : "var(--grayTwo)");
      setThreads(threads);
      setAnchorEl(null);
      setThreadID(null);
    }
  };

  const contentRef = useRef(null);
  const contenidoRef = useRef(null);
  const imgRefs = useRef({});
  const quillRef = useRef(null);

  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: function (searchTerm, renderList, mentionChar) {
        if (mentionChar === "@") {
          fetch(
            `${backendUrl}/api/users?filters[username][$containsi]=${searchTerm}`
          )
            .then((response) => response.json())
            .then((data) => {
              const mentions = data.map((user) => ({
                id: user.id,
                value: user.username,
                email: user.email,
              }));
              renderList(mentions, searchTerm);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (mentionChar === "#") {
          fetch(
            `${backendUrl}/api/groups?filters[name][$containsi]=${searchTerm}`
          )
            .then((response) => response.json())
            .then((data) => {
              const hashtag = data.data.map((group) => ({
                value: group.attributes.name,
                id: group.id,
              }));
              renderList(hashtag, searchTerm);
            });
        }
      },
      onSelect: function (item, insertItem) {
        if (item.denotationChar === "@") {
          setSelectedUsers((prevMentions) => [...prevMentions, item]);
          insertItem(item);
        }
        if (item.denotationChar === "#") {
          setSelectedGroup((prevMentions) => [...prevMentions, item]);
          insertItem(item);
        }
      },
    },
  }).current;

  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const month = date.toLocaleString("default", {
      month: "long",
    });
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedDate = `${month} ${day}, ${hour}:${formattedMinutes}`;
    return formattedDate;
  }

  const handleResizeStop = (event, direction, ref, delta) => {
    const newWidth = ref.style.width;
    localStorage.setItem("myComponentWidth", newWidth);
    setWidth(newWidth);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid var(--blueTwo)`,
      padding: "0 4px",
      backgroundColor: "var(--blueTwo)",
      cursor: "pointer",
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, thread) => {
    setAnchorEl(event.currentTarget);
    setThreadID(thread);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeen = async (message, messageType) => {
    let usersMails = await message.seen_by?.map((e) => e.email);
    if (!usersMails.includes(localStorage.email)) {
      const response = await putSeen(message.id, pathname, messageType, row.id);
      if (response && response.data && response.data.threads) {
        const { threads } = response.data;
        setThreads(threads);
      }
    }
  };

  const handleImageClick = (e, file) => {
    e.preventDefault();
    setModalImage(file);
  };

  const handleMouseOnSeenThread = async (index) => {
    setMouseOverThread(true);
    setPositionSeenThread(index);
  };
  const handleMouseLeftSeenThread = async () => {
    setMouseOverThread(false);
    setPositionSeenThread([]);
  };

  const handleMouseOnSeenComment = async (index, i) => {
    setMouseOverComment(true);
    setPositionSeenComment(index);
    setCommentID(i.id);
  };
  const handleMouseLeftSeenComment = async () => {
    setMouseOverComment(false);
    setPositionSeenComment([]);
  };

  const handleMouseDeleteComment = (index, i) => {
    setShowDeleteIcon(true);
    setPositionDeleteComment(index);
    setCommentID(i.id);
  };
  const handleMouseDeleteCommenLeave = () => {
    setShowDeleteIcon(false);
    setPositionDeleteComment([]);
  };

  const [valueTab, setValueTab] = useState(0);

  const handleTab = (event, newValue) => {
    event.preventDefault();
    setValueTab(newValue);
  };

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);
  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <StyledBadge
        badgeContent={numberOfThreads}
        color={"info"}
        max={99}
        onClick={() => handleThreads(row.id)}
      >
        <ChatBubbleOutlineRoundedIcon
          style={{
            color: iconColor,
            cursor: "pointer",
          }}
        />
      </StyledBadge>
      {+currentRowData === +row.id ? (
        <Modal
          open={window.modalThreadOpen}
          onClose={handleCloseModal}
          sx={{
            "& .MuiPopover-root": {
              zIndex: "1000",
            },
          }}
        >
          <div key={row.id}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Alert
                elevation={6}
                variant="filled"
                severity="success"
                onClose={handleSnackbarClose}
              >
                Texto copiado
              </Alert>
            </Snackbar>

            <Resizable
              style={inlineStyles.resizable}
              defaultSize={{
                width: isMobile ? "100%" : width,
                height: "100%",
              }}
              onResizeStop={handleResizeStop}
              minWidth={"50%"}
              maxWidth={isMobile ? "100vw" : "85vw"}
              enable={{ left: true }}
            >
              <Box>
                <div>
                  <div className={styles.topContainer}>
                    <CloseIcon
                      onClick={handleCloseModal}
                      style={{ cursor: "pointer", color: "var(--gray)" }}
                    />
                    <h1 className={styles.id}>{row.offerID}</h1>
                    {!isMobile ? (
                      <Tabs value={valueTab} onChange={handleTab}>
                        <Tab label="Actualizaciones" {...a11yProps(0)} />
                        <Tab
                          label={
                            pathname === "it-request"
                              ? "Archivos"
                              : pathname === "master-branding"
                              ? "INFO CLIENTE/MATERIALES"
                              : pathname === "crm"
                              ? "Propuestas Enviadas"
                              : "Reporte Invalidaciones"
                          }
                          {...a11yProps(1)}
                        />
                        {pathname === "crm" ? null : (
                          <Tab
                            label={
                              pathname === "it-request"
                                ? "Notas"
                                : pathname === "master-branding"
                                ? "COSTOS/REPORTES"
                                : "CSM"
                            }
                            {...a11yProps(2)}
                          />
                        )}

                        <Tab label="Activity Log" {...a11yProps(3)} />
                      </Tabs>
                    ) : (
                      <div>
                        <Button
                          id="basic-button"
                          aria-controls={openMenu ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openMenu ? "true" : undefined}
                          onClick={handleClickMenu}
                        >
                          Dashboard
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorElMenu}
                          open={openMenu}
                          onClose={handleCloseMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleCloseMenu}>Profile</MenuItem>
                          <MenuItem onClick={handleCloseMenu}>
                            My account
                          </MenuItem>
                          <MenuItem onClick={handleCloseMenu}>Logout</MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                  <TabPanel value={valueTab} index={0} sx={{ padding: "0px" }}>
                    <NewThread
                      modules={modules}
                      quillRef={quillRef}
                      handleFormSubmitThread={handleFormSubmitThread}
                      handleOnClickThread={handleOnClickThread}
                      handleUploadFile={handleUploadFile}
                      selectedFile={selectedFile}
                      newThreadIsClicked={newThreadIsClicked}
                      handleInputTitle={handleInputTitle}
                      titleNewThread={titleNewThread}
                      valueWritten={valueWritten}
                      setValueWritten={setValueWritten}
                      setSelectedFile={setSelectedFile}
                      setFile={setFile}
                      file={file}
                      // fileDrag={fileDrag}
                      // setFileDrag={setFileDrag}
                    />

                    <div className={styles.threadsContainer}>
                      {threads.length > 0 ? (
                        threads.map((thread, index) => {
                          const formattedDate = formatCreatedAt(
                            thread.createdAt
                          );

                          return (
                            <div className={styles.content} key={thread.id}>
                              {thread.anchored === true ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "28px",
                                  }}
                                >
                                  <div
                                    style={{
                                      margin:
                                        thread.anchored === true
                                          ? "30px 0px 0px 0px"
                                          : "0px",
                                      backgroundColor: "var(--violetThree)",
                                      padding: "4px 8px",
                                      borderTopLeftRadius: "4px",
                                      borderTopRightRadius: "4px",
                                    }}
                                  >
                                    {thread.anchored === true ? (
                                      <p style={{ color: "white" }}>Anclado</p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : null}
                              <div
                                className={styles.card}
                                style={{
                                  margin:
                                    thread.anchored === true
                                      ? "0px"
                                      : "30px 0px",
                                }}
                                id={thread.id}
                              >
                                <CardContent
                                  sx={{
                                    padding: "0px",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <HeaderContent
                                    open={open}
                                    userId={userId}
                                    thread={thread}
                                    formattedDate={formattedDate}
                                    handleClick={handleClick}
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                    handleAnchor={handleAnchor}
                                    threadID={threadID}
                                    handleFormDeleteThread={
                                      handleFormDeleteThread
                                    }
                                    handleFormEditThread={handleFormEditThread}
                                    index={index}
                                  />
                                  <ThreadContent
                                    contenidoRef={contenidoRef}
                                    imgRefs={imgRefs}
                                    thread={thread}
                                    handleImageClick={handleImageClick}
                                    mouseOverThread={mouseOverThread}
                                    positionSeenThread={positionSeenThread}
                                    index={index}
                                    SeenBy={SeenBy}
                                    handleMouseOnSeenThread={
                                      handleMouseOnSeenThread
                                    }
                                    handleMouseLeftSeenThread={
                                      handleMouseLeftSeenThread
                                    }
                                    handleSeen={handleSeen}
                                    editThread={editThread}
                                    setEditThread={setEditThread}
                                    threadState={threadState}
                                    setThreadState={setThreadID}
                                    positionEdit={positionEdit}
                                    editingThreadId={editingThreadId}
                                    row={row}
                                    key={key}
                                    setSelectedUsers={setSelectedUsers}
                                    setSelectedGroup={setSelectedGroup}
                                    selectedUsers={selectedUsers}
                                    selectedGroup={selectedGroup}
                                    valueWritten={valueWritten}
                                    handleSnackbarOpen={handleSnackbarOpen}
                                    // contenidoScroll={thread.id}
                                  />
                                </CardContent>

                                <ActionsButtons
                                  thread={thread}
                                  handleFormSubmitLike={handleFormSubmitLike}
                                  handleResponse={handleResponse}
                                  response={response}
                                  index={index}
                                />

                                {response && +index === +position ? (
                                  <CommentsResponse
                                    index={index}
                                    commentWritten={commentWritten}
                                    setCommentWritten={setCommentWritten}
                                    modules={modules}
                                    quillRef={quillRef}
                                    handleUploadFile={handleUploadFile}
                                    handleFormSubmitComment={
                                      handleFormSubmitComment
                                    }
                                    thread={thread}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    setFile={setFile}
                                    response={response}
                                  />
                                ) : (
                                  <></>
                                )}

                                <div
                                  style={{
                                    margin: "0 14px",
                                  }}
                                >
                                  {thread.comments.length > 0 ? (
                                    thread.comments.map((i, indexComment) => {
                                      const formattedDate = formatCreatedAt(
                                        i.createdAt
                                      );

                                      return (
                                        <div
                                          className={styles.commentOutside}
                                          key={i.id}
                                        >
                                          <Comments
                                            messageType={"comment"}
                                            i={i}
                                            indexComment={indexComment}
                                            handleFormDeleteComment={
                                              handleFormDeleteComment
                                            }
                                            handleSeen={handleSeen}
                                            index={index}
                                            handleMouseDeleteComment={
                                              handleMouseDeleteComment
                                            }
                                            handleCommentResponse={
                                              handleCommentResponse
                                            }
                                            handleImageClick={handleImageClick}
                                            handleMouseDeleteCommenLeave={
                                              handleMouseDeleteCommenLeave
                                            }
                                            handleMouseLeftSeenComment={
                                              handleMouseLeftSeenComment
                                            }
                                            imgRefs={imgRefs}
                                            userId={userId}
                                            key={key}
                                            commentID={commentID}
                                            showDeleteIcon={showDeleteIcon}
                                            formattedDate={formattedDate}
                                            mouseOverComment={mouseOverComment}
                                            positionDeleteComment={
                                              positionDeleteComment
                                            }
                                            contenidoRef={contenidoRef}
                                            contentRef={contentRef}
                                            handleMouseOnSeenComment={
                                              handleMouseOnSeenComment
                                            }
                                            positionSeenComment={
                                              positionSeenComment
                                            }
                                            handleFormSubmitLike={
                                              handleFormSubmitLike
                                            }
                                            handleFormEditComment={
                                              handleFormEditComment
                                            }
                                            editCommentForm={editCommentForm}
                                            setEditCommentForm={
                                              setEditCommentForm
                                            }
                                            editingCommentId={editingCommentId}
                                            setEditingCommentId={
                                              setEditingCommentId
                                            }
                                            row={row}
                                            setSelectedUsers={setSelectedUsers}
                                            setSelectedGroup={setSelectedGroup}
                                            selectedUsers={selectedUsers}
                                            selectedGroup={selectedGroup}
                                            commentWritten={commentWritten}
                                          />
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                                <div id={`response${index}`}></div>
                                {commentResponse && +index === +position ? (
                                  <CommentsResponse
                                    index={index}
                                    commentWritten={commentWritten}
                                    setCommentWritten={setCommentWritten}
                                    modules={modules}
                                    quillRef={quillRef}
                                    handleUploadFile={handleUploadFile}
                                    handleFormSubmitComment={
                                      handleFormSubmitComment
                                    }
                                    thread={thread}
                                    selectedFile={selectedFile}
                                    response={response}
                                  />
                                ) : null}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value={valueTab} index={1}>
                    <Media
                      id={row.id}
                      media={itemMedia}
                      backendUrl={backendUrl}
                      handleImageClick={handleImageClick}
                      contenidoRef={contenidoRef}
                      imgRefs={imgRefs}
                      setItemMedia={setItemMedia}
                      itemMedia={itemMedia}
                    />
                  </TabPanel>
                  <TabPanel value={valueTab} index={2}>
                    <Notes
                      id={row.id}
                      notes={notes}
                      setNotes={setNotes}
                      backendUrl={backendUrl}
                      imgRefs={imgRefs}
                      contenidoRef={contenidoRef}
                      handleImageClick={handleImageClick}
                      row={row}
                    />
                  </TabPanel>

                  <TabPanel value={valueTab} index={3}>
                    <ActivityLog row={row} />
                  </TabPanel>
                </div>
              </Box>
            </Resizable>

            {modalImage && (
              <ModalImage
                setModalImage={setModalImage}
                modalImage={modalImage}
                row={row}
                backendUrl={backendUrl}
                pathname={pathname}
              />
            )}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Threads;
