import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  conversionTypeKala,
  divStyle,
  encargadaCsm,
  formatos,
  meses,
  otherOptions,
  pixelVerification,
  plataformas,
  responsables,
  testOptions,
  tiposContratacion,
  typeOfClient,
} from "../../../json/CampaignCreator";
import { Currencies } from "../../../json/Currencies";

const Kala = ({
  dataForm,
  setDataForm,
  setBat,
  checkboxBat,
  setCheckboxBat,
}) => {
  const [formType, setFormType] = useState("MASTER PERFORMANCE");

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAutocompleteChange = (name, newValue) => {
    setDataForm((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue : "",
    }));
  };

  const handleSetBat = (e) => {
    setBat(e.target.value);
    setCheckboxBat(e.target.checked);
  };

  const handleSetBoard = (name, newValue) => {
    setFormType(newValue);
    setDataForm((prevState) => ({
      ...prevState,
      board: newValue ? newValue : "",
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "800px",
      }}
    >
      <Autocomplete
        disableClearable
        disablePortal
        options={["MASTER PERFORMANCE", "MASTER CPS", "MASTER BRANDING"]}
        getOptionLabel={(option) => option}
        sx={{ width: "385px", marginBottom: "20px" }}
        onChange={handleSetBoard}
        value={formType}
        renderInput={(params) => (
          <TextField {...params} label="BOARD" variant="standard" required />
        )}
        required
      />

      {formType === "MASTER PERFORMANCE" ? (
        <>
          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={typeOfClient}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("typeOfClient", newValue.value)
              }
              value={
                typeOfClient.find(
                  (client) => client.name === dataForm.typeOfClient
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of client"
                  variant="standard"
                  value={dataForm.typeOfClient}
                />
              )}
            />
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={encargadaCsm}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "385px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("encargadaCsm", newValue.value)
              }
              value={
                encargadaCsm.find(
                  (encargada) => encargada.name === dataForm.encargadaCsm
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Encargada CSM"
                  variant="standard"
                  value={dataForm.encargadaCsm}
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={Currencies}
              getOptionLabel={(option) => option}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("monedaAnunciante", newValue)
              }
              value={
                Currencies.find(
                  (moneda) => moneda === dataForm.monedaAnunciante
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Moneda Anunciante"
                  variant="standard"
                  value={dataForm.monedaAnunciante}
                />
              )}
            />

            <TextField
              label="Revenue Moneda Anunciante"
              value={dataForm.revenueMAnunciante}
              onChange={handleChangeDataForm}
              name="revenueMAnunciante"
              type="number"
              variant="standard"
              sx={{ width: "385px" }}
            />
          </div>

          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={pixelVerification}
              getOptionLabel={(option) => option.name}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("pixelStatus", newValue.value)
              }
              value={
                pixelVerification.find(
                  (pixel) => pixel.name === dataForm.pixelStatus
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Verificación de Pixel"
                  variant="standard"
                  value={dataForm.pixelStatus}
                />
              )}
            />

            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={conversionTypeKala}
              getOptionLabel={(option) => option.name}
              sx={{ width: "385px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("conversionTypeKALA", newValue.value)
              }
              value={
                conversionTypeKala.find(
                  (conversion) =>
                    conversion.name === dataForm.conversionTypeKALA
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Conversion Type KALA"
                  variant="standard"
                  value={dataForm.conversionTypeKALA}
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <TextField
              sx={{ width: "385px", marginRight: "30px" }}
              label="Ticket Promedio"
              name="ticketPromedio"
              value={dataForm.ticketPromedio}
              onChange={handleChangeDataForm}
              type="text"
              variant="standard"
            />

            <TextField
              label="Cap estimado"
              value={dataForm.capEstimado}
              name="capEstimado"
              onChange={handleChangeDataForm}
              type="number"
              sx={{ width: "385px" }}
              variant="standard"
            />
          </div>

          <Autocomplete
            size="small"
            multiple
            disableClearable
            disablePortal
            id="combo-box-demo"
            options={otherOptions}
            getOptionLabel={(option) => option.title}
            sx={{ width: "385px", marginRight: "30px", marginBottom: "15px" }}
            onChange={(event, newValues) =>
              handleAutocompleteChange(
                "other",
                newValues.map((value) => value.value).join(", ")
              )
            }
            value={otherOptions?.filter((option) =>
              dataForm?.other?.includes(option.value)
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Other"
                variant="standard"
                value={dataForm.other}
              />
            )}
          />

          <Box sx={{ margin: "15px 0px" }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                value="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                checked={checkboxBat}
                onChange={handleSetBat}
              />
            </FormGroup>
          </Box>
        </>
      ) : formType === "MASTER CPS" ? (
        <>
          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={typeOfClient}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("typeOfClient", newValue.value)
              }
              value={
                typeOfClient.find(
                  (client) => client.name === dataForm.typeOfClient
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of client"
                  variant="standard"
                  value={dataForm.typeOfClient}
                />
              )}
            />
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={encargadaCsm}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "385px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("encargadaCsm", newValue.value)
              }
              value={
                encargadaCsm.find(
                  (encargada) => encargada.name === dataForm.encargadaCsm
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Encargada CSM"
                  variant="standard"
                  value={dataForm.encargadaCsm}
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <TextField
              label="Revenue Moneda Anunciante"
              value={dataForm.revenueMAnunciante}
              onChange={handleChangeDataForm}
              name="revenueMAnunciante"
              type="number"
              variant="standard"
              sx={{ width: "385px", marginRight: "30px" }}
            />

            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={pixelVerification}
              getOptionLabel={(option) => option.name}
              sx={{ width: "385px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("pixelStatus", newValue.value)
              }
              value={
                pixelVerification.find(
                  (pixel) => pixel.name === dataForm.pixelStatus
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Verificación de Pixel"
                  variant="standard"
                  value={dataForm.pixelStatus}
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <TextField
              sx={{ width: "385px", marginRight: "30px" }}
              label="Ticket Promedio"
              name="ticketPromedio"
              value={dataForm.ticketPromedio}
              onChange={handleChangeDataForm}
              type="text"
              variant="standard"
            />

            <Autocomplete
              size="small"
              multiple
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={otherOptions}
              getOptionLabel={(option) => option.title}
              sx={{ width: "385px" }}
              onChange={(event, newValues) =>
                handleAutocompleteChange(
                  "other",
                  newValues.map((value) => value.value).join(", ")
                )
              }
              value={otherOptions?.filter((option) =>
                dataForm?.other?.includes(option.value)
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Other"
                  variant="standard"
                  value={dataForm.other}
                />
              )}
            />
          </div>

          <Box sx={{ margin: "15px 0px" }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                value="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                checked={checkboxBat}
                onChange={handleSetBat}
              />
            </FormGroup>
          </Box>
        </>
      ) : (
        <>
          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="responsible"
              options={responsables}
              getOptionLabel={(option) => option.username}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("responsible", newValue.value)
              }
              value={
                responsables.find(
                  (responsable) => responsable.username === dataForm.responsible
                ) || null
              }
              renderInput={(params) => (
                <TextField {...params} label="Responsable" variant="standard" />
              )}
            />
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={encargadaCsm}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "385px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("owner", newValue.value)
              }
              value={
                encargadaCsm.find(
                  (encargada) => encargada.name === dataForm.owner
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Owner"
                  variant="standard"
                  value={dataForm.owner}
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <TextField
              label="Estado"
              value="ACTIVE"
              variant="standard"
              sx={{ width: "100%", marginRight: "30px" }}
              InputProps={{
                readOnly: true,
              }}
            />

            <Autocomplete
              size="small"
              disableClearable
              disablePortal
              multiple
              id="trafficSources"
              options={formatos}
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange(
                  "trafficSources",
                  newValue.map((source) => source.value)
                )
              }
              value={formatos.find(
                (source) => source.value === dataForm.trafficSources
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Traffic Sources Allowed"
                  variant="standard"
                />
              )}
            />
          </div>

          <div style={divStyle}>
            <Autocomplete
              size="small"
              disableClearable
              disablePortal
              multiple
              id="platform"
              options={plataformas}
              getOptionLabel={(option) => option}
              sx={{ width: "100%", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("platform", newValue.join(", "))
              }
              value={dataForm.platform ? dataForm.platform.split(", ") : []}
              renderInput={(params) => (
                <TextField {...params} label="Plataforma" variant="standard" />
              )}
            />

            <Autocomplete
              disableClearable
              disablePortal
              id="typeOfContract"
              options={tiposContratacion}
              getOptionLabel={(option) => option}
              sx={{ width: "100%" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("typeOfContract", newValue)
              }
              value={dataForm.typeOfContract || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de Contratación"
                  variant="standard"
                />
              )}
            />
          </div>

          <div style={divStyle}>
            {/* <Autocomplete
              disableClearable
              disablePortal
              id="trafficSources"
              options={formatos}
              getOptionLabel={(option) => option}
              sx={{ width: "100%", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("trafficSources", newValue)
              }
              value={formatos.find(
                (source) => source === dataForm.trafficSources
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Traffic Sources Allowed"
                  variant="standard"
                />
              )}
            /> */}

            <Autocomplete
              disableClearable
              disablePortal
              id="billingMonth"
              options={meses}
              getOptionLabel={(option) => option}
              sx={{ width: "100%" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("billingMonth", newValue)
              }
              value={dataForm.billingMonth || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mes de Facturación"
                  variant="standard"
                />
              )}
            />
          </div>
          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="test"
              options={testOptions}
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("test", newValue.value)
              }
              value={testOptions.find((test) => test.name === dataForm.test)}
              renderInput={(params) => (
                <TextField {...params} label="¿Test?" variant="standard" />
              )}
            />

            <TextField
              label="Revenue Moneda Anunciante"
              value={dataForm.revenueMAnunciante || ""}
              onChange={handleChangeDataForm}
              name="revenueMAnunciante"
              type="text"
              variant="standard"
              sx={{ width: "100%" }}
            />
          </div>

          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="monedaAnunciante"
              options={Currencies}
              getOptionLabel={(option) => option}
              sx={{ width: "100%", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("monedaAnunciante", newValue)
              }
              value={dataForm.monedaAnunciante || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Moneda Anunciante"
                  variant="standard"
                />
              )}
            />

            <TextField
              label="Revenue USD"
              value={dataForm.revenueUSD || ""}
              onChange={handleChangeDataForm}
              name="revenueUSD"
              type="text"
              variant="standard"
              sx={{ width: "100%" }}
            />
          </div>

          <div style={divStyle}>
            <TextField
              label="Payout USD"
              value={dataForm.payoutUSD || ""}
              onChange={handleChangeDataForm}
              name="payoutUSD"
              type="text"
              variant="standard"
              sx={{ width: "100%", marginRight: "30px" }}
            />

            <Autocomplete
              disableClearable
              disablePortal
              id="conversionTypeKALA"
              options={conversionTypeKala}
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("conversionTypeKALA", newValue.value)
              }
              value={conversionTypeKala.find(
                (conversion) => conversion.name === dataForm.conversionTypeKALA
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Conversion Type KALA"
                  variant="standard"
                />
              )}
            />
          </div>
        </>
      )}
    </Box>
  );
};

export default Kala;
