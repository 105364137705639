import { Divider } from "@mui/material";
import React from "react";
import styles from "../../styles/Views.module.css";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const ViewsPerformance = ({
  setViewFilter,
  setTables,
  setColumnsFilter,
  setSelectedView,
  selectedView,
  setDensity,
  density,
  setPinnedColumns,
}) => {
  const handleViews = (e) => {
    let view = e.currentTarget.getAttribute("value");
    setSelectedView(view);
    setColumnsFilter({ id: false });

    if (localStorage.email === view && localStorage.getItem("views")) {
      let localView = JSON.parse(localStorage.getItem("views"))[0];
      setViewFilter(localView.filters);
      setColumnsFilter(localView.columns);
      setDensity(localView.density || density);
      setPinnedColumns(localView.pinnedColumns);
    } else {
      switch (view) {
        case "tabla-principal":
          setColumnsFilter({ id: false });
          setViewFilter({ items: [] });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          // navigate(`/${pathname}`);
          break;
        case "afiliados":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "offerStatus",
                operator: "isAnyOf",
                value: ["ACTIVE", "RUNNING", "AON", "NOT PROFITABLE"],
              },
              {
                id: 2,
                field: "conversionType",
                operator: "isAnyOf",
                value: [
                  "CPA",
                  "CPL",
                  "CPI",
                  "CPL, CPA",
                  "CPL, CPI",
                  "CPI, CPA",
                ],
              },
            ],
            logicOperator: GridLogicOperator.And,
          });
          setColumnsFilter({
            id: false,
            typeOfClient: false,
            offerStatus: false,
            csmStatus: false,
            affiliacionStatus: false,
            advertiserName: false,
            affiseLink: false,
            cap: false,
            timeframe: false,
            cumplimiento: false,
            BAT_Preview: false,
            direct: false,
            revenueUSD: false,
            revenueMAnunciante: false,
            monedaAnunciante: false,
            reporteDeInvalidacion: false,
            encargadaCsm: false,
            estimadoCapMensual: false,
            payoutAceptado: false,
            maximoProfit: false,
            maximoProfitPayAceptado: false,
            threads: false,
            pixelStatus: false,
            duration: false,
            reporteInvalidacion: false,
            affiseClicks: false,
            pendingPayout: false,
            pendingRevenue: false,
            pendingQty: false,
            createdAt: false,
          });
          setTables(["Offer ID"]);
          setDensity(density);
          break;
        case "bsuero@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "offerStatus",
                operator: "is",
                value: "ACTIVE",
              },
            ],
          });
          setColumnsFilter({
            id: false,
            revenueUSD: false,
            revenueMAnunciante: false,
            monedaAnunciante: false,
          });
          setTables(["Offer ID"]);
          setDensity(density);
          break;
        case "vcamacho@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "typeOfClient",
                operator: "equals",
                value: "A",
              },
            ],
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          break;
        case "msclavo@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "offerStatus",
                operator: "is",
                value: "AFFISE SETUP",
              },
              {
                id: 2,
                field: "csmStatus",
                operator: "isAnyOf",
                value: [
                  "Nuevo CAP",
                  "Hacer Seguimiento",
                  "Verificando con cliente",
                  "Nueva Campaña",
                  "Tracking Issues",
                  "Global Postback",
                  "Reporte Invalidaciones",
                  "Pending Test",
                  "Pixel Required",
                  "Nuevos Creativos",
                  "Nuevo Tracking",
                  "Nuevo Revenue",
                  "Conversiones Manuales",
                  "Ajuste Conversiones",
                  "Ajuste BAT",
                  "Bat Preview",
                ],
              },
              {
                id: 3,
                field: "affiliacionStatus",
                operator: "isAnyOf",
                value: [
                  "Discrepancia",
                  "Verificar Integracion",
                  "Reporte Invalidaciones",
                  "Declined-Pending",
                  "Landing No Funciona",
                  "Cambiar Pay de Afiliado",
                  "Flow",
                ],
              },
            ],
            logicOperator: GridLogicOperator.Or,
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          break;
        case "viktoriia@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "country",
                operator: "is",
                value: "MX",
              },
              {
                id: 2,
                field: "typeOfClient",
                operator: "equals",
                value: "A",
              },
            ],
            logicOperator: GridLogicOperator.And,
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          break;
        case "marango@aluna.co":
          setViewFilter({
            items: [
              {
                id: 5,
                field: "encargadaCsm",
                operator: "equals",
                value: "Maria Camila Arango",
              },
            ],
            logicOperator: GridLogicOperator.And,
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          break;
        case "fcasado@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "encargadaCsm",
                operator: "equals",
                value: "Felicitas Casado",
              },
            ],
          });
          setColumnsFilter({
            id: false,
            vertical: false,
            timeframe: false,
            direct: false,
            reporteDeInvalidacion: false,
            estimadoCapMensual: false,
            maximoProfit: false,
            maximoProfitPayAceptado: false,
            previewLink: false,
            revenueMAnunciante: false,
            monedaAnunciante: false,
          });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          break;
        case "aiemmi@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "encargadaCsm",
                operator: "equals",
                value: "Agustina Iemmi",
              },
            ],
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          setPinnedColumns({
            left: ["__check__", "offerID", "threads", "offerTitle"],
            right: [],
          });
          break;
        case "nillera@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "encargadaCsm",
                operator: "equals",
                value: "Natalia Illera Quintero",
              },
            ],
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          setPinnedColumns({
            left: ["__check__", "offerID", "threads", "offerTitle"],
            right: [],
          });
          break;
        case "ldelacruz@aluna.co":
          setViewFilter({
            items: [
              {
                id: 1,
                field: "encargadaCsm",
                operator: "equals",
                value: "Laura De La Cruz",
              },
            ],
          });
          setColumnsFilter({ id: false });
          setTables(["Offer ID", "Inactive", "Not profitable"]);
          setDensity(density);
          setPinnedColumns({
            left: ["__check__", "offerID", "threads", "offerTitle"],
            right: [],
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={styles.globalContainer}>
      <div
        className={`${styles.paragraph} ${
          selectedView === "tabla-principal" ? styles.selected : ""
        }`}
        style={{ display: "flex" }}
      >
        <CottageOutlinedIcon
          sx={{ marginRight: "6px", fontSize: "20px", marginTop: "2px" }}
        />
        <p value="tabla-principal" onClick={(e) => handleViews(e)}>
          Tabla principal
        </p>
      </div>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="afiliados"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "afiliados" ? styles.selected : ""
        }`}
      >
        PARA AFILIADOS
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="bsuero@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "bsuero@aluna.co" ? styles.selected : ""
        }`}
      >
        Belén
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />

      <p
        value="vcamacho@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "vcamacho@aluna.co" ? styles.selected : ""
        }`}
      >
        Vane
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />

      <p
        value="msclavo@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "msclavo@aluna.co" ? styles.selected : ""
        }`}
      >
        Martin
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />

      <p
        value="viktoriia@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "viktoriia@aluna.co" ? styles.selected : ""
        }`}
      >
        Victor
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />

      <p
        value="marango@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "marango@aluna.co" ? styles.selected : ""
        }`}
      >
        Cami
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="fcasado@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "fcasado@aluna.co" ? styles.selected : ""
        }`}
      >
        Feli
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="aiemmi@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "aiemmi@aluna.co" ? styles.selected : ""
        }`}
      >
        Agus
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="nillera@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "nillera@aluna.co" ? styles.selected : ""
        }`}
      >
        Nati
      </p>
      <Divider orientation="vertical" flexItem sx={{ margin: "8px 0" }} />
      <p
        value="ldelacruz@aluna.co"
        onClick={(e) => handleViews(e)}
        className={`${styles.paragraph} ${
          selectedView === "ldelacruz@aluna.co" ? styles.selected : ""
        }`}
      >
        Lau
      </p>
    </div>
  );
};

export default ViewsPerformance;
