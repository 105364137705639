import { Grid, ListItemButton, ListItemText, Menu, Tooltip } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { COLORS } from "../../json/StatusOptions";
import { editBoardField } from "../../utils/ApiCall";

const ColumnStatus = ({
  value,
  row,
  selectionRow,
  field,
  columnWidth,
  columnPosition,
  optionsAmount,
  disabled,
  handleEditResponseChange,
  statusOption,
}) => {
  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;

  let currentUrl = window.location.pathname;
  let statusOptions = statusOption;

  const [{ open, selectValue }, setState] = useState({
    open: false,
    selectValue: value,
  });

  const cellRef = useRef(null);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectValue: value,
      open: false,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSelectChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      selectValue: value,
      open: false,
    }));

    const newValue = value;

    if (selectionRow.length !== 0) {
      editBoardField(currentUrl, selectionRow, field, newValue);
      selectionRow.forEach((row) =>
        handleEditResponseChange(newValue, row, field)
      );
    } else {
      editBoardField(currentUrl, [row.id], field, newValue);
      handleEditResponseChange(newValue, row.id, field);
    }
  };

  const cellStyle = {
    backgroundColor: COLORS[selectValue] || "rgb(196, 196, 196)",
    position: "relative",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: columnWidth ? "900px" : optionsAmount === "low" ? "400px" : "700px",
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    onClick: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : `${columnPosition}px`,
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  return (
    <>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        <Tooltip
          title={selectValue}
        >
        <span className="paragraph">{selectValue}</span>
        </Tooltip>
      </p>

      <Menu
        {...menuProps}
        style={{ marginLeft: columnWidth ? "50px" : "inherit" }}
      >
        <Grid container spacing={1}>
          {statusOptions?.map((el) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={statusOptions.length <= 3 ? 12 : 4}
              key={el.value}
            >
              <ListItemButton
                selected={selectValue === el.value}
                onClick={() => handleSelectChange(el.value)}
                style={{
                  color: "white",
                  backgroundColor: COLORS[el.value]
                    ? COLORS[el.value]
                    : "white",
                  padding: "4px",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={el.label}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </ListItemButton>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

export default ColumnStatus;
