import { Box, Button, useMediaQuery } from "@mui/material";

import React, { useState } from "react";
import styles from "../../styles/Threads.module.css";

import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import CustomFileUploader from "../../components/global/CustomFileUploader";
import { fileTypes } from "../../json/FileTypes";

const CommentsResponse = ({
  index,
  commentWritten,
  setCommentWritten,
  modules,
  quillRef,
  handleUploadFile,
  handleFormSubmitComment,
  thread,
  selectedFile,
  setSelectedFile,
  setFile,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await handleFormSubmitComment(thread.id);
    setIsSubmitting(false);
  };

  const handleDelete = async (file) => {
    let files = selectedFile.filter(
      (e) => e.lastModified !== file.lastModified
    );
    setFile(files);
    setSelectedFile(files);
  };

  return (
    <Box id={index} component="form" className={styles.mentionContainer}>
      <ReactQuill
        id={index}
        theme="snow"
        value={commentWritten}
        onChange={setCommentWritten}
        placeholder="Escribir comentario"
        modules={modules}
        ref={quillRef}
      />

      <div className={styles.commentFile}>
        <FileUploader
          handleChange={handleUploadFile}
          name="file"
          types={fileTypes}
          multiple={true}
          label="Elegir archivos o arrastrar aqui"
          hoverTitle="Arrastrar aqui"
          children={<CustomFileUploader isMobile={isMobile} />}
        />

        {/* <Button>
          <label htmlFor="file" className={styles.uploadLabel}>
            <AttachFileOutlinedIcon className={styles.uploadIcon} />
            Subir Archivo
          </label>
        </Button> */}

        <input
          hidden
          id="file"
          name="file"
          type="file"
          onChange={handleUploadFile}
          accept="*"
          multiple
          className={styles.input}
        />

        <Button
          onClick={(e) => handleSubmit(e)}
          disabled={isSubmitting}
          sx={{
            backgroundColor: "var(--blueFour)",
            color: "#fcfaff",
            textTransform: "none",
            padding: "4px 8px",
            minWidth: "80px",
            height: "35px",
            fontSize: "16px",
            "&: hover": {
              backgroundColor: "var(--blue)",
            },
          }}
        >
          {isSubmitting ? "Respondiendo" : "Responder"}
        </Button>
      </div>
      {selectedFile &&
        selectedFile.map((file) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p key={file.name}>{file.name}</p>
            <Button onClick={() => handleDelete(file)}>
              <DeleteIcon style={{ width: "23px" }} />
            </Button>
          </div>
        ))}
    </Box>
  );
};

export default CommentsResponse;
