import React from "react";

const EditableField = ({ value, row, api, setState, field }) => {
  const handleChange = (e) => {
    const updatedValue = e.target.value;
    e.preventDefault();
    api.setEditCellValue({
      id: row.id,
      field: field,
      value: updatedValue,
    });
  };
  setState(value);
  
  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      style={{
        width: "100%",
        padding: "10px",
        outline: "none",
        border: "none",
      }}
    />
  );
};

export default EditableField;
