import { Box, IconButton } from "@mui/material";
import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const style = {
  position: "fixed",
  top: "800px",
  left: "50%",
  transform: "translate(-30%, -50%)",
  width: "900px",
  height: "80px",
  overflow: "hidden",
  bgcolor: "white",
  boxShadow: 5,
  alignItems: "center",
  zIndex: "1000",
  display: "flex",
};

const CheckboxRow = ({
  isModalOpen,
  selectionRow,
  setDeleteInput,
  setIsDeleteModalOpen,
  isDeleteModalOpen,
}) => {
  const handleOpenDeleteRow = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setDeleteInput("");
  };

  return (
    <div>
      {isModalOpen && (
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--blueTwo)",
                width: "80px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "white", fontSize: "36px" }}>
                {selectionRow.length}
              </p>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <p style={{ fontSize: "26px", color: "inherit" }}>
                {selectionRow.length === 1
                  ? "Elemento seleccionado"
                  : "Elementos seleccionados"}
              </p>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              right: "0",
              padding: "0 20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              title="Eliminar"
              onClick={handleOpenDeleteRow}
              sx={{ margin: "0 10px" }}
            >
              <DeleteOutlineOutlinedIcon sx={{ fontSize: "36px" }} />
            </IconButton>
          </div>
        </Box>
      )}
    </div>
  );
};

export default CheckboxRow;
