import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import styles from "../../../styles/CampaignCreator.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FolderIcon from "@mui/icons-material/Folder";
import { deleteCreative } from "../../../utils/ApiCall";

const Creatives = ({
  oldCreatives,
  setCreatives,
  newCreatives,
  setNewCreatives,
  offerId,
}) => {
  const handleCreatives = (e) => {
    let creativesToUpload = newCreatives?.concat(e.target.files[0]);
    if (!oldCreatives) {
      setCreatives(creativesToUpload);
    } else {
      setNewCreatives(creativesToUpload);
    }
  };

  const handleDeleteOldCreatives = async (oldCreative) => {
    await deleteCreative(offerId, oldCreative.id);
    let filteredCreatives = oldCreatives.filter((e) => e.id !== oldCreative.id);
    setCreatives(filteredCreatives);
  };

  const handleDeleteNewCreatives = async (creative) => {
    let filteredCreatives = newCreatives.filter((e) => e !== creative);
    if (!oldCreatives) {
      setCreatives(filteredCreatives);
    } else {
      setNewCreatives(filteredCreatives);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "800px",
        }}
      >
        <div className={styles.uploadForm}>
          <DriveFolderUploadIcon sx={{ fontSize: "100px", color: "#784af4" }} />
          <input
            accept=".zip, .rar"
            type="file"
            name="file"
            id="file"
            className={styles.input}
            onChange={handleCreatives}
          />
          <label for="">
            <p style={{ color: "#784af4", marginTop: "15px" }}>
              Seleccionar carpeta
            </p>
          </label>
        </div>
        <div
          style={{
            backgroundColor: "var(--blueLow)",
            height: "400px",
            width: "100%",
            padding: "20px",
            overflowY: "scroll",
            border: "5px 5px 5px 0px dotted #784af4",
            marginLeft: "5px",
          }}
        >
          <div>
            {oldCreatives ? (
              oldCreatives.map((oldCreative) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    margin: "0px 0px 20px 0px",
                    padding: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FolderIcon
                      sx={{
                        fontSize: "50px",
                        color: "#eaeaf0",
                        marginRight: "10px",
                      }}
                    />

                    <p
                      style={{
                        width: "100%",
                        maxWidth: "350px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {oldCreative.title}
                    </p>
                  </div>

                  <div className={styles.logoTitle}>
                    <Tooltip
                      title="Eliminar creatives"
                      sx={{ marginLeft: "6px" }}
                      onClick={() => handleDeleteOldCreatives(oldCreative)}
                    >
                      <IconButton>
                        <DeleteIcon className={styles.deleteIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div>
            {newCreatives ? (
              newCreatives.map((creative) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    margin: "0px 0px 20px 0px",
                    padding: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FolderIcon
                      sx={{
                        fontSize: "50px",
                        color: "#eaeaf0",
                        marginRight: "10px",
                      }}
                    />

                    <p
                      style={{
                        width: "100%",
                        maxWidth: "350px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {creative.name}
                    </p>
                  </div>

                  <div className={styles.logoTitle}>
                    <Tooltip
                      title="Eliminar creatives"
                      sx={{ marginLeft: "6px" }}
                      onClick={() => handleDeleteNewCreatives(creative)}
                    >
                      <IconButton>
                        <DeleteIcon className={styles.deleteIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))
            ) : (
              <FolderIcon
                sx={{
                  fontSize: "100px",
                  color: "#eaeaf0",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Creatives;
