import React from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { editBoardField, getStatus } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { prospectingStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/ColumnStatus.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import SingleDuration from "../../../../components/global/SingleDuration.jsx";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProspectingAfiliados = () => {
  const [groups] = useState([
    "Belén Suero",
    "Camila Velloso",
    "Carolina Racana",
    "Katalina Palomino",
    "Sara López Graciano",
  ]);
  const [belenSuero, setBelenSuero] = useState([]);
  const [mariaCamila, setMariaCamila] = useState([]);
  const [carolinaRacana, setCarolinaRacana] = useState([]);
  const [katalinaPalomino, setKatalinaPalomino] = useState([]);
  const [saraLopez, setSaraLopez] = useState([]);
  const [statusByParams] = useState("Belén Suero");
  const [status, setStatus] = useState(statusByParams);
  const apiUrl = `${backendUrl}/api/prospecting-afiliado/items?owner=${status}`;
  const [capEstimado] = useState("");
  const [selectionRow, setSelectionRow] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });

  const filterByOwner = (rows, status) => {
    let itemsFiltered = rows.filter(item => {
        if (item.owner?.username === status) {
            return true;
        } else if (status === "Camila Velloso" && item.owner == null) {
            return true;
        }
        return false;
    });
    return itemsFiltered;
}

  const filterItemsAndSetGroups = async (items) => {

      setBelenSuero(filterByOwner(await items,"Belén Suero"));

      setMariaCamila(filterByOwner(items,"Camila Velloso"));

      setCarolinaRacana(filterByOwner(items,"Carolina Racana"));

      setKatalinaPalomino(filterByOwner(items,"Katalina Palomino"));

      setSaraLopez(filterByOwner(items,"Sara López Graciano"));

  };


  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status = await getStatus(rowID, "prospecting-afiliado");

    if (status === "Belén Suero") {
      const updatedRowsTerminados = belenSuero.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setBelenSuero(updatedRowsTerminados);
    } else if (status === "Carolina Racana") {
      const updatedRowsTerminados = carolinaRacana.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setCarolinaRacana(updatedRowsTerminados);
    } else if (status === "Katalina Palomino") {
      const updatedRowsTerminados = katalinaPalomino.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setKatalinaPalomino(updatedRowsTerminados);
    } else if (status === "Sara López Graciano") {
      const updatedRowsTerminados = saraLopez.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setSaraLopez(updatedRowsTerminados);
    } else {
      const updatedRowsTerminados = mariaCamila.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setMariaCamila(updatedRowsTerminados);
    }
  };

  const [updateRows, setUpdateRows] = useState([]);
  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={prospectingStatus}
        />
      ),
    },
    {
      field: "date",
      headerName: "Fecha de último contacto",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={selectionRow}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Explicación explayada de acciones",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"actions"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.actions}
        />
      ),
    },
  ];

  const controlEdit = async (params) => {
    let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
    switch (params.field) {
      case "date":
        await editBoardField(
          "/prospecting-afiliado",
          ids,
          params.field,
          capEstimado
        );
        break;
      default:
        break;
    }
  };

  const getGroupRows = (t) => {
    return t === "Belén Suero"
      ? belenSuero
      : t === "Camila Velloso"
      ? mariaCamila
      : t === "Carolina Racana"
      ? carolinaRacana
      : t === "Katalina Palomino"
      ? katalinaPalomino
      : t === "Sara López Graciano"
      ? saraLopez
      : [];
  };

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        controlEdit={controlEdit}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default ProspectingAfiliados;
