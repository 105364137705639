import React, { useRef } from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import fotoPerfil from "../../assets/perfilionvo.png";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SeenBy = ({ seenby, id }) => {
  const users = seenby[0];

  const seenContainerRef = useRef(null);
  useEffect(() => {
    const containerHeight = seenContainerRef.current.offsetHeight;
    const topValue = -(containerHeight + 20);

    seenContainerRef.current.style.top = `${topValue}px`;
  }, [users]);

  return (
    <div
      className="seen-container"
      ref={seenContainerRef}
      style={{
        position: "absolute",
        left: id === "position" ? "-78px" : "670px",
        top:
          users.length < 4
            ? "-130px"
            : users.length > 4 && users.length < 7
            ? "-170px"
            : users.length > 7 && users.length < 10
            ? "-210px"
            : users.length > 10
            ? "-210px"
            : "",
        right: "200px",
      }}
    >
      <Typography style={{ marginBottom: "8px" }}>Visto por:</Typography>
      <div className="seen-by-container">
        {users?.map((user) => (
          <div key={user.id}>
            <img
              src={
                user?.avatar?.url
                  ? `${backendUrl}${user?.avatar?.url}`
                  : fotoPerfil
              }
              className="user-seen"
              alt="Perfil"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default SeenBy;
