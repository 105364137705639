import React, { useContext } from "react";
import container from "../../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useState } from "react";
import Threads from "../../../../Threads/Threads.jsx";
import { Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditableText from "../../../../../components/global/EditableText";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import ModalCreate from "./ModalCreate/ModalCreate";
import ModalEdit from "./ModalEdit/ModalEdit";
import { UserContext } from "../../../../../UserContext";
import BaseBoard from "../../../../global/BoardTemplate/BaseBoard";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid var(--blueTwo)`,
    padding: "0 4px",
    backgroundColor: "var(--blueTwo)",
    cursor: "pointer",
  },
}));

const MasterTag = () => {
  const [groups] = useState(["Master Tag"]);
  const [status, setStatus] = useState("Master Tag");
  
  const apiUrl = `${backendUrl}/api/master-tag-roundler/getAll`;
  const [selectionRow, setSelectionRow] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  
  const [createId, setCreateId] = useState(null);
  const [offerFields, setOfferFields] = useState({
    id: "",
    place: [
      {
        name: "",
        basket: { content: "", type: "" },
        category: { content: "", type: "" },
        home: { content: "", type: "" },
        product: { content: "", type: "" },
        thankyou: { content: "", type: "" },
      },
    ],
    offerTitle: "",
    OfferId: "",
  });
  const [items, setItems] = useState([]);
  
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarIsEdit, setSnackbarIsEdit] = useState(false);
  
  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });

  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));

  let header = {
    Accept: "/",
    Authorization: `Bearer ${key}`,
  };

  const filterItemsAndSetGroups = (items) => {
    setItems(items);
  };

  const openModalEditTag = (rowData) => {
    setModalEdit(true);
    setOfferFields(rowData);
  };

  const closeModalEditTag = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/master-tag-roundler/getAll`,
        {
          headers: header,
        }
      );
      if (response.data) {
        setItems(response.data);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
    setModalEdit(!modalEdit);
    setOfferFields({
      id: "",
      place: [
        {
          name: "",
          basket: { content: "", type: "" },
          category: { content: "", type: "" },
          home: { content: "", type: "" },
          product: { content: "", type: "" },
          thankyou: { content: "", type: "" },
        },
      ],
      offerTitle: "",
      OfferId: "",
    });
  };

  const openModalCreateTag = (data) => {
    setModalCreate(true);
    setCreateId(data.id);
  };

  const closeModalCreateTag = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/master-tag-roundler/getAll`,
        {
          headers: header,
        }
      );
      if (response.data) {
        setItems(response.data);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }

    setModalCreate(false);
    setCreateId(null);
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  const copyToClipboard = async (offer) => {
    const textElement = document.createElement("textarea");
    const id = offer.OfferId;
    const name = offer.offerTitle;
    const cadena = id;
    const encoder = new TextEncoder();
    const cadenaBytes = encoder.encode(cadena);

    const hashBuffer = await crypto.subtle.digest("SHA-256", cadenaBytes);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const uuid = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    let urlId =
      uuid.slice(0, 8) +
      "-" +
      id +
      "-" +
      uuid.slice(8, 12) +
      "-" +
      uuid.slice(12, 16) +
      "-" +
      uuid.slice(16, 24);
    const tags = `
  Offer Name: ${name}
  Offer ID: ${id}
  
  Home
  <script src="https://mastertag.roundler.com.br/api/${urlId}/home"></script>
  
  Category
  <script src="https://mastertag.roundler.com.br/api/${urlId}/category"></script>
  
  Product
  <script src="https://mastertag.roundler.com.br/api/${urlId}/product"></script>
  
  Cart
  <script src="https://mastertag.roundler.com.br/api/${urlId}/basket"></script>
      `;
    textElement.value = tags;
    document.body.appendChild(textElement);
    textElement.select();
    textElement.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textElement);

    showSnackbar("success", "Copiado exitosamente");
  };

  const showSnackbar = (severity, message) => {
    if (severity === "success") {
      setSnackbarSeverity(severity);
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      setSnackbarIsEdit(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Master Tag" ? items : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const columns = [
    {
      field: "OfferId",
      headerName: "Offer ID",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"OfferId"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.OfferId}
        />
      ),
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 500,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"offerTitle"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.offerTitle}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "actionAdd",
      headerName: "Add Tags",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <AddIcon
          {...params}
          onClick={() => openModalCreateTag(params.row)}
          style={{ cursor: "pointer", color: "var(--blackLow)" }}
        />
      ),
    },
    {
      field: "actionEdit",
      headerName: "Edit Tags",
      width: 100,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        const itemCount = params.row.place?.length;
        return params.row.place?.length > 0 ? (
          <StyledBadge
            badgeContent={itemCount}
            color={"info"}
            max={99}
            onClick={() => openModalEditTag(params.row)}
          >
            <Edit
              {...params}
              style={{ cursor: "pointer", color: "var(--blackLow)" }}
            />
          </StyledBadge>
        ) : null;
      },
    },
    {
      field: "viewTags",
      headerName: "Copy Tags",
      width: 100,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return params.row.OfferId !== "" ? (
          <ContentCopyIcon
            {...params}
            onClick={() => copyToClipboard(params.row)}
            style={{ cursor: "pointer", color: "var(--blackLow)" }}
          />
        ) : null;
      },
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setViewFilter={setViewFilter}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        height={"80vh"}
        maxHeight={"80vh"}
      />
      {modalEdit && (
        <ModalEdit
          handleClose={closeModalEditTag}
          modalOpen={modalEdit}
          offerFields={offerFields}
          setOfferFields={setOfferFields}
        />
      )}

      {modalCreate && (
        <ModalCreate
          handleClose={closeModalCreateTag}
          modalOpen={modalCreate}
          id={createId}
        />
      )}

      {snackbarSeverity === "success" && snackbarIsEdit && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={snackbarSeverity}
            onClose={handleSnackbarClose}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      )}
    </div>
  );
};

export default MasterTag;
