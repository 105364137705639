import React, { useEffect, useState } from "react";
import Header from "../../../../components/global/Header";
import container from "../../../../styles/Global.module.css";
import Threads from "../../../Threads/Threads";
import { pedidosItStatus, pedidosItTags } from "../../../../json/StatusOptions";
import ColumnStatus from "../../../../components/global/ColumnStatus";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import SingleDuration from "../../../../components/global/SingleDuration";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PedidosIt = () => {
  const [status, setStatus] = useState("Tasks");
  const userStorage = localStorage.getItem("email");
  const userRole = localStorage.getItem("role");
  const apiUrl = `${backendUrl}/api/it-request/items?role=${userRole}`;
  const apiItGroup = `${backendUrl}/api/getAllGroups`;
  const [selectionRow, setSelectionRow] = useState([]);
  const [itAllowed, setItAllowed] = useState([]);
  const [groups] = useState(["Tasks", "Done"]);
  const [items, setItems] = useState([]);
  const [itemsDone, setItemsDone] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({});
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resGroups = await fetch(apiItGroup);
      const response = await resGroups.json();
      const itGroup = response.filter((group) => group.name === "IT");

      setItAllowed(itGroup[0].users);
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsByStatus = (items, status, condition) => {
    if (condition) {
      return items?.filter((item) => item.status === status);
    }
    return items?.filter((item) => item.status !== status);
  };

  const filterItemsAndSetGroups = (items) => {

    setItemsDone(filterItemsByStatus(items, "Done", true));
    setItems(filterItemsByStatus(items, "Done", false));
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Tasks" ? items : itemsDone;
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const isUserInAllowedGroup = itAllowed.some((user) =>
    user.email.includes(userStorage)
  );

  const columns = [
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Threads {...params} />;
      },
    },
    {
      field: "id",
      headerName: "Ticket ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 400,
      headerAlign: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },

    {
      field: "status",
      headerName: "Estado",
      width: 160,
      headerAlign: "center",
      cellClassName: "no-padding",
      renderCell: (params) => (
        <div style={{ width: "100%", height: "100%", cursor: "not-allowed" }}>
          <ColumnStatus
            {...params}
            selectionRow={selectionRow}
            handleEditResponseChange={handleEditResponseChange}
            field={"status"}
            columnPosition={280}
            disabled={!itAllowed.some((user) => user.email === userStorage)}
            statusOption={pedidosItStatus}
          />
        </div>
      ),
    },

    {
      field: "trafficSources",
      headerName: "Etiquetas",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"tags"}
          handleEditResponseChange={handleEditResponseChange}
          options={pedidosItTags.map((item) => item.title)}
          getOptionLabelFunc={(tag) => tag}
          initialValue={params.row.tags}
          label="Etiquetas"
        />
      ),
    },
    {
      field: "owner.username",
      headerName: "Pedido por",
      width: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.owner?.username,
    },
    {
      field: "publishedAt",
      headerName: "Fecha de solicitud",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={selectionRow}
          approvedUser={userStorage}
        />
      ),
    },
    isUserInAllowedGroup && {
      field: "area",
      headerName: "Area",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => <p>{row?.owner?.role?.name || "N/A"}</p>,
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default PedidosIt;
