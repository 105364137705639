import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { deleteRow } from "../../../utils/ApiCall";

const ModalRowDelete = ({
  setIsDeleteModalOpen,
  isDeleteModalOpen,
  setDeleteInput,
  deleteInput,
  selectionRow,
  pathname,
  updateBoard,
}) => {
  const handleInputDelete = (e) => {
    setDeleteInput(e.target.value);
  };

  const handleConfirmDeleteRow = async () => {
    if (deleteInput.trim().toLowerCase() === "eliminar") {
      const response = await deleteRow(selectionRow, pathname);
      updateBoard(response);
      setIsDeleteModalOpen(!isDeleteModalOpen);
      setDeleteInput("");
    }
  };

  return (
    <div>
      {isDeleteModalOpen && (
        <Dialog
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <DialogTitle
            sx={{ display: "flex", alignItems: "center", fontSize: "20px" }}
          >
            <WarningIcon sx={{ color: "red", marginRight: "10px" }} />
            ¿Estás seguro/a que deseas eliminar este elemento?
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "12px" }}>
              Al presionar eliminar entiendes que esta decisión no se podra
              rehacer.
            </DialogContentText>
            <DialogContentText>
              Para confirmar escribe{" "}
              <span style={{ fontWeight: "bold" }}>"eliminar"</span> debajo.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="eliminar"
              type="email"
              fullWidth
              variant="standard"
              value={deleteInput}
              onChange={handleInputDelete}
              error={deleteInput !== "eliminar" ? "Texto invalido" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteRow} sx={{ color: "red" }}>
              Eliminar
            </Button>
            <Button onClick={() => setIsDeleteModalOpen(false)}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ModalRowDelete;
