import * as React from "react";
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material";
import { fetchData } from "../../../../utils/ApiCall";
import { useEffect } from "react";
import CustomToolBar from "../../../../components/global/CustomToolBar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


export const isNavigationKey = (key) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick}>
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <span
              tabIndex={-1}
              size="small"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ marginRight: "6px" }}
              />
              {props?.value}
            </span>
          ) : (
            <span
              tabIndex={-1}
              size="small"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <ArrowForwardIosIcon
                fontSize="small"
                sx={{ marginRight: "6px" }}
              />{" "} */}
              {props?.value}
            </span>
          )}
        </div>
      </Box>
    </div>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const getTodaysDay = () => {
  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);

  return { firstOfMonth, today }
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function TreeDataCustomGroupingColumn() {
  const apiRef = useGridApiRef();

  const apiService = `${backendUrl}/api/offer-service`;
  const [offers, setOffers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [affiliates, setAffiliates] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [enebleButton, setEnableButton] = useState(false);
  const [isLoadingRows, setIsLoadingRows] = useState(false);
  const [searchByAffiliateID, setSearchByAffiliateID] = useState(null);
  const [newArray, setNewArray] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");

  const dates = getTodaysDay()

  const [dateFrom, setDateFrom] = useState(dates.firstOfMonth);
  const [dateTo, setDateTo] = useState(dates.today);

  const searchOffers = async (query, searchParam) => {
    const url = `${apiService}/AutocompleteOptionsAffiliates?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    setAffiliates(resOffers);
  };

  useEffect(() => {
    if (searchText && !affiliateID) {
      if (searchByAffiliateID) {
        searchOffers(searchText, "affiseID");
      } else {
        searchOffers(searchText, "title");
      }
      setIsSearching(true);
      setIsListOpen(true);
    } else {
      setAffiliates([]);
      setIsSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    searchByAffiliateID,
    isTextFieldClicked,
    isSearching
  ]);

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setSearchByAffiliateID(!isNaN(e.target.value));
    // setAffiliates([]);
    setIsTextFieldClicked(false);
  };

  const handleLiClick = async (value) => {
    if (value) {
      setSearchText(value.title)
      setIsTextFieldClicked(false);
      setAffiliateID(value.affiseID)
      setIsListOpen(false);
      setEnableButton(true)
      setIsSearching(false)
    }
  };

  const handleSubmit = async () => {
    if (!isNaN(affiliateID)) {
      setEnableButton(false)
      setOffers([]);
      setIsLoadingRows(true);

      const formattedDateFrom = dateFrom
        ? dayjs(dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = dateTo ? dayjs(dateTo).format("YYYY-MM-DD") : "";

      const seguimientoUrl = `${apiService}/campaignTrackingByAffiliate?affiseID=${affiliateID}&dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const dates = getTodaysDay()

      setDateTo(dates.today)
      setDateFrom(dates.firstOfMonth)

      const seguimientoData = await fetchData(seguimientoUrl);

      setIsLoadingRows(false);
      setOffers(seguimientoData);
      setAffiliates([]);
      setSearchText("");
    }
  }
  const columns = [
    { field: "offerID", headerName: "Offer ID", width: 80 },
    { field: "affiliateID", headerName: "Affiliate ID", width: 80 },
    { field: "affiliateName", headerName: "Affiliate Name", width: 180 },
    { field: "manager", headerName: "Affiliate Manager", width: 140 },
    {
      field: "hierarchy",
      headerName: "Dia",
      width: 60,
      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        const rowNode = params.rowNode;

        if (params.row.id == totalRow.id) {
          return "TOTAL";
        }

        if (rowNode.depth === 0) {
          return "";
        }

        if (hierarchy.length === 2) {
          const day = hierarchy[hierarchy.length - 1];
          return `${day.toString()}`;
        } else {
          return hierarchy[0];
        }
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalClicks;
        } else {
          return params.row.clicks;
        }
      },
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalPending;
        } else {
          return params.row.pending;
        }
      },
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return params.row.totalDecline;
        } else {
          return params.row.declined;
        }
      },
    },
    {
      field: "cr",
      headerName: "Cr",
      width: 70,

      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return roundDecimal(params.row.totalCr) + "%";
        } else {
          return roundDecimal(params.row.cr) + "%";
        }
      },
    },
    {
      field: "capAffiliate",
      headerName: "Cap Affiliate",
      width: 85,
      valueGetter: (params) => params.row.capAffiliate ?? null
    },
    { field: "capTotal", headerName: "Cap Offer", width: 85 },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;
        let revenue =
          rowNode.depth === 0 ? params.row.totalRevenue : params.row.revenue;

        revenue = parseInt(revenue).toFixed(0);

        return `$${revenue}`;
      },
    },
    {
      field: "payouts",
      headerName: "Payout",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${params.row.totalPayout}`;
        } else {
          return `$${params.row.payouts}`;
        }
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 80,
      valueGetter: (params) => {
        const rowNode = params.rowNode;
        let profit =
          rowNode.depth === 0 ? params.row.totalProfit : params.row.profit;

        profit = parseInt(profit).toFixed(0);

        return `$${profit}`;
      },
    },
    {
      field: "payoutAffiliate",
      headerName: "Payout Affiliate",
      width: 120,
      valueGetter: (params) => {
        const rowNode = params.rowNode;

        if (rowNode.depth === 0) {
          return `$${roundDecimal(params.row.totalPayoutAffiliate)}`;
        } else {
          return `$${roundDecimal(params.row.payoutAffiliate)}`;
        }
      },
    }
  ];

  const getTreeDataPath = (row) => row.hierarchy;

  const groupingColDef = {
    headerName: "Offer",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const getRowClassName = (params) => {
    const row = params.row;

    if (row.totalName && row?.totalName === "TOTAL") {
      return "total-row";
    }
    if (row?.hierarchy?.length === 1) {
      return "highlighted-row";
    }

    return "";
  };

  const calculateFieldSum = (fieldName) => {
    if (newArray.length > 0 && filterOn) {
      return newArray
        .filter((offer) => offer.hierarchy.length === 2)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    } else {
      return offers
        .filter((offer) => offer.hierarchy.length === 1)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    }
  };

  const roundDecimal = (numero) => {
    const numeroRedondeado = Math.round(numero * 10) / 10;
    return numeroRedondeado;
  };

  const totalPendingSum = calculateFieldSum("totalPending");
  const totalClicksSum = calculateFieldSum("totalClicks");
  const totalDeclinedSum = calculateFieldSum("totalDecline");
  const totalRevenueSum = `${roundDecimal(calculateFieldSum("totalRevenue"))}`;
  const totalPayoutSum = `${roundDecimal(calculateFieldSum("totalPayout"))}`;
  const totalProfitSum = `${roundDecimal(calculateFieldSum("totalProfit"))}`;
  const totalPayoutAffiliateSum = `${roundDecimal(
    calculateFieldSum("totalPayoutAffiliate")
  )}`;

  const totalCrSum = roundDecimal((totalPendingSum / totalClicksSum) * 100);

  const totalRow = {
    id: offers.length + 1,
    hierarchy: [offers.length + 1],
    totalPending: totalPendingSum,
    totalClicks: totalClicksSum,
    totalDecline: totalDeclinedSum,
    totalRevenue: totalRevenueSum,
    totalPayout: totalPayoutSum,
    totalPayoutAffiliate: totalPayoutAffiliateSum,
    totalProfit: totalProfitSum,
    totalCr: isNaN(totalCrSum) ? 0 : totalCrSum,
    totalName: "TOTAL",
  };

  const updatedOffers = [...offers, totalRow];

  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "#f8f7ff" }}>
      <Box sx={{ padding: "15px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "15px" }}>
              <TextField
                type="text"
                label="Affiliate ID / Affiliate Title"
                value={searchText}
                disabled={isLoadingRows}
                onChange={handleSearchTextChange}
                onClick={() => setIsTextFieldClicked(true)}
                sx={{
                  width: "350px",
                  backgroundColor: "white",
                  position: "relative",
                }}
              />

              {isSearching &&
                affiliates.length === 0 &&
                searchText !== "" && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      position: "absolute",
                      width: "350px",
                      zIndex: "1000",
                    }}
                  >
                    <ListItem className="autocomplete-li" disabled={true}>
                      <ListItemText primary="Offer no encontrada" />
                    </ListItem>
                  </List>
                )}

              {isSearching && isListOpen && affiliates.length > 0 && (
                <List
                  component="ul"
                  className="autocomplete-ul"
                  sx={{
                    width: "350px",
                    zIndex: "1000",
                    position: "absolute",
                  }}
                >
                  {isSearching &&
                    isListOpen &&
                    affiliates.map((result) => (
                      <ListItem
                        key={result.affiseID}
                        onClick={() => handleLiClick(result)}
                        className="autocomplete-li"
                        disabled={isLoadingRows}
                        sx={{
                          color: "black",
                          fontWeight: "700",
                          margin: "5px 0px",
                          "&:hover": {
                            backgroundColor: "var(--blueLowTwo)",
                          },
                        }}
                      >
                        <ListItemText primary={result.title} />
                      </ListItem>
                    ))}
                </List>
              )}
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{
                  start: `${dateFrom}`,
                  end: `${dateTo}`,
                }}
                sx={{ backgroundColor: "white", marginRight: "10px" }}
                onChange={(newValue) => {
                  if (newValue[0]) setDateFrom(newValue[0]);
                  if (newValue[1]) setDateTo(newValue[1]);
                }}
                disableFuture
              />
            </LocalizationProvider>
            <ThemeProvider theme={theme}>
              <Button
                variant="outlined"
                onClick={handleSubmit}
                disabled={!enebleButton}
              >
                Generar
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <DataGridPro
          slots={{
            toolbar: () => <CustomToolBar apiRef={apiRef} />,
            loadingOverlay: () => <LinearProgress />,
          }}
          loading={isLoadingRows ? true : false}
          treeData
          rows={updatedOffers}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rowHeight={30}
          getRowClassName={getRowClassName}
          sx={{ backgroundColor: "white" }}
          apiRef={apiRef}
        />
      </Box>
    </div>
  );
}
