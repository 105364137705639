import React, { useEffect } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { useState } from "react";
import { editBoardField, fetchData } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { solicitudCampanaStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/ColumnStatus.jsx";
import EditableField from "../../../../components/global/EditableField.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import SingleDuration from "../../../../components/global/SingleDuration.jsx";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple.jsx";
import EditableSelectCountry from "../../../../components/global/EditableSelectCountry.jsx";
import { sortAlphabetically } from "../../../../json/CampaignCreator.js";
import { countries } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SolicitudCampana = () => {
  const [groups] = useState(["Nombre de campaña"]);
  const [status, setStatus] = useState("Nombre de campaña");
  const apiUrl = `${backendUrl}/api/solicitud-campana/items`;
  const apiService = `${backendUrl}/api/offer-service`;
  const [payAffiliate , setPayAffiliate] = useState("");
  const [items, setItems] = useState([]);
  const [selectionRow, setSelectionRow] = useState([]);
  const [trafficSources, setTrafficSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });


  useEffect(() => {
    const fetchApi = async () => {
      const resSources = await fetchData(`${apiService}/getSources`);
      setTrafficSources(sortAlphabetically(resSources, "title"));
      const resCategories = await fetchData(`${apiService}/getCategories`);
      setCategories(sortAlphabetically(resCategories, "title"));
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsAndSetGroups = (items) => {
    setItems(items);
  };

  const controlEdit = async (params) => {
    let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
    switch (params.field) {
      case "payAffiliate":
        await editBoardField(
          "/solicitud-campana",
          ids,
          params.field,
          payAffiliate
        );
        break;
      default:
        break;
    }
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  const getGroupRows = (groupName) =>{
    return groupName === "Nombre de campaña" ? items : [];
  }

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "owner.username",
      headerName: "Persona que lo pide",
      width: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.owner?.username,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={solicitudCampanaStatus}
        />
      ),
    },
    {
      field: "date",
      headerName: "Fecha de solicitud",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={selectionRow}
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Tipo de Compra",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPL", "CPI", "CPC", "CPM", "CPS", "CPA"]}
          getOptionLabelFunc={(client) => client}
          initialValue={params.row.conversionType}
          label="Tipo de Compra"
        />
      ),
    },
    {
      field: "landingPreview",
      headerName: "Landing Preview",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"landingPreview"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.landingPreview}
        />
      ),
    },
    {
      field: "affiliateName",
      headerName: "Afiliado que solicita",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"affiliateName"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.affiliateName}
          styleText={true}
        />
      ),
    },
    {
      field: "payAffiliate",
      headerName: "PAY Afiliado USD",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderEditCell: (params) => (
        <EditableField {...params} setState={setPayAffiliate} />
      ),
      editable: "true",
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={countries}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"vertical"}
          handleEditResponseChange={handleEditResponseChange}
          options={categories.map(item => (item.title))}
          getOptionLabelFunc={(vertical) => vertical}
          initialValue={params.row.vertical}
          label="Vertical"
        />
      ),
    },

    {
      field: "trafficSources",
      headerName: "Traffic Source",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={trafficSources.map(item => (item.title))}
          getOptionLabelFunc={(traffic) => traffic}
          initialValue={params.row.trafficSources}
          label="Traffic Source"
        />
      ),
    },
    {
      field: "additionalComments",
      headerName: "Comentarios Adicionales",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"additionalComments"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.additionalComments}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
        <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        controlEdit={controlEdit}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default SolicitudCampana;
