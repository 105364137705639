import {
  Box,
  createTheme,
  Menu,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";

import { editDate } from "../../utils/ApiCall";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
require("dayjs/locale/es");

const SingleDuration = ({ row, value, selectionRow }) => {
  let pathname = window.location.pathname.replace("/", "");

  const [dateFrom, setDateFrom] = useState(row?.date || row?.reminder);
  const renderStartDate = dateFrom
    ? dayjs(dateFrom).locale("es").format("MMM. D")
    : "Agregar fecha";

  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;

  const theme = createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: "50px",
            backgroundColor: "var(--blackLow)",
          },
          bar: {
            backgroundColor: "var(--done)",
          },
        },
      },
    },
  });

  const [{ open }, setState] = useState({
    open: false,
    selectValue: value,
  });

  const cellRef = useRef(null);

  useEffect(() => {
    setTimeout(window.unlock, 100);
  }, []);

  const cellStyle = {
    position: "relative",
    cursor: pathname === "it-request" ? "default" : "pointer",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: "auto",
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "122px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleMenuOpen = () => {
    const cellRect = cellRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const isMiddle =
      cellRect.top > viewportHeight / 2 &&
      cellRect.bottom < (viewportHeight * 2) / 3;
    const isBottom = cellRect.bottom > (viewportHeight * 2) / 3;
    if (isMiddle || isBottom) {
      menuProps.anchorOrigin.vertical = "top";
      menuProps.transformOrigin.vertical = "bottom";
    } else {
      menuProps.anchorOrigin.vertical = "bottom";
      menuProps.transformOrigin.vertical = "top";
    }
  };

  const handleDateChange = (dateRange) => {
    const date = dateRange?.$d;
    setDateFrom(date);

    if ("start?.$d") {
      if (selectionRow.length !== 0) {
        editDate(pathname, date, "", selectionRow);
      } else {
        editDate(pathname, date, "", [row.id]);
      }
    }
  };

  return (
    <div ref={cellRef} style={cellStyle}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          onClick={pathname === "it-request" ? null : handleClick}
        >
          <Typography
            variant="caption"
            component="div"
            color="inherit"
            sx={{ width: "120px", display: "flex", justifyContent: "center" }}
          >
            {renderStartDate !== "" ? renderStartDate : "Agregar fecha"}
          </Typography>
        </Box>
      </ThemeProvider>
      <Menu {...menuProps} onMouseEnter={handleMenuOpen}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            onChange={handleDateChange}
            defaultValue={dayjs(row.date || row.reminder || Date.now())}
          />
        </LocalizationProvider>
      </Menu>
    </div>
  );
};

export default SingleDuration;
