import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { editTabs, steps } from "../../json/CampaignCreator.js";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import FormTabs from "./tabs/FormTabs";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
      sx={{ cursor: "pointer" }}
    >
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const MainTabs = ({
  isEdit,
  dataForm,
  setDataForm,
  apiUrl,
  header,
  dataCaps,
  setDataCaps,
  dataLanding,
  setDataLanding,
  logo,
  setLogo,
  oldCreatives,
  setCreatives,
  newCreatives,
  setNewCreatives,
  creatives,
  offerId,
  bat,
  setBat,
  direct,
  setDirect,
  checkboxBat,
  setCheckboxBat,
  checkboxDirect,
  setCheckboxDirect,
  activeStep,
  handleStep,
  descriptionCreatives,
  setDescriptionCreatives,
  descriptionTargeting,
  setDescriptionTargeting,
  selectedCategories,
  setSelectedCategories,
  selectedTags,
  setSelectedTags,
  title,
  description,
  setDescription,
  personalPayout,
  setPersonalPayout,
  personalTargeting,
  setPersonalTargeting,
  transformedPersonalTargeting,
  setTransformedPersonalTargeting,
}) => {
  const [completed] = React.useState({});
  const renderStepComponent = () => {
    switch (activeStep) {
      default:
        return (
          <FormTabs
            isEdit={isEdit}
            activeStep={activeStep}
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            dataCaps={dataCaps}
            setDataCaps={setDataCaps}
            dataLanding={dataLanding}
            setDataLanding={setDataLanding}
            logo={logo}
            setLogo={setLogo}
            oldCreatives={oldCreatives}
            setCreatives={setCreatives}
            newCreatives={newCreatives}
            setNewCreatives={setNewCreatives}
            creatives={creatives}
            offerId={offerId}
            bat={bat}
            setBat={setBat}
            direct={direct}
            setDirect={setDirect}
            checkboxBat={checkboxBat}
            setCheckboxBat={setCheckboxBat}
            checkboxDirect={checkboxDirect}
            setCheckboxDirect={setCheckboxDirect}
            descriptionCreatives={descriptionCreatives}
            setDescriptionCreatives={setDescriptionCreatives}
            descriptionTargeting={descriptionTargeting}
            setDescriptionTargeting={setDescriptionTargeting}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            title={title}
            description={description}
            setDescription={setDescription}
            personalPayout={personalPayout}
            setPersonalPayout={setPersonalPayout}
            personalTargeting={personalTargeting}
            setPersonalTargeting={setPersonalTargeting}
            transformedPersonalTargeting={transformedPersonalTargeting}
            setTransformedPersonalTargeting={setTransformedPersonalTargeting}
          />
        );
    }
  };

  return (
    <Box sx={{ width: "fit-content", minWidth: "70%", margin: "20px 0" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        sx={{ cursor: "pointer" }}
      >
        {isEdit
          ? editTabs.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel
                  onClick={handleStep(index)}
                  className="stepper"
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))
          : steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel
                  onClick={handleStep(index)}
                  className="stepper"
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
      </Stepper>

      {renderStepComponent()}
    </Box>
  );
};

export default MainTabs;
