import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const CustomFileUploader = ({ isMobile }) => {
  return (
    <div
      className="dropArea"
      style={{
        width: isMobile ? "100%" : "500px",
      }}
    >
      <FileUploadIcon
        sx={{
          marginRight: "10px",
          color: "var(--blueFour)",
        }}
      />
      Elegir archivos o arrastrar aqui
    </div>
  );
};

export default CustomFileUploader;
