import * as React from "react";
import { useMemo } from "react";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Edit } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Modal, ThemeProvider, Tooltip, createTheme } from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import styles from "../../../styles/EditCampaign.module.css";
import globalStyles from "../../../styles/Global.module.css";
import { UserContext } from "../../../UserContext";
import { useContext } from "react";
import { editOfferAffise, fetchData, postFile } from "../../../utils/ApiCall";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import MainTabs from "../../CampaignCreator/MainTabs";
import { boardStyles, modalEditStyles } from "../../../json/BoardStyles";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ display: value === index ? "block" : "none" }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const EditCampaign2 = () => {
  const apiUrl = `${backendUrl}/api/`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));
  let header;
  header = {
    Accept: "/",
    Authorization: `Bearer ${key}`,
  };

  const [activeOffers, setActiveOffers] = useState([]);
  const [stoppedOffers, setStoppedOffers] = useState([]);
  const [suspendedOffers, setSuspendedOffers] = useState([]);
  const [tab, setTab] = useState(0);
  const [domainsChosen, setDomainsChosen] = useState("");
  const [description, setDescription] = useState("");
  const [offerId, setOfferId] = useState("");
  const [personalPayout, setPersonalPayout] = useState([]);
  const [personalTargeting, setPersonalTargeting] = useState([]);
  const [newCreatives, setNewCreatives] = useState([]);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [landing, setLanding] = useState([]);
  const [dataCaps, setDataCaps] = useState([]);
  const [logo, setLogo] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isEdit] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [transformedPersonalTargeting, setTransformedPersonalTargeting] =
    useState([]);

  const tabConfig = [
    {
      label: "Active",
      rows: activeOffers,
    },
    {
      label: "Paused",
      rows: suspendedOffers,
    },
    {
      label: "Disabled",
      rows: stoppedOffers,
    },
  ];
  useEffect(() => {
    if (!key) return;
    const fetchApi = async () => {
      const resOffers = await fetchData(
        `${apiUrl}offer-service/getActiveOffers`
      );
      setActiveOffers(resOffers);
      const resStopped = await fetchData(
        `${apiUrl}offer-service/getStoppedOffers`
      );
      setStoppedOffers(resStopped);
      const resSuspended = await fetchData(
        `${apiUrl}offer-service/getSuspendedOffers`
      );
      setSuspendedOffers(resSuspended);
    };

    const fetchDataAndUpdate = async () => {
      await fetchApi().catch(console.error);

      setTimeout(window.unlock, 100);
    };
    fetchDataAndUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  const [dataForm, setDataForm] = useState({
    offerTitle: "",
    advertisers: {
      id: 93,
      name: "INHOUSE TEST",
      affiseID: "60e4b8aa17b9f9e30611e655",
    },
    trackingUrl: "",
    paymentType: "fixed",
    currencies: "USD",
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    status: "",
    trafficSourcesAffise: [],
    description: "",
    tags: [],
    categories: [],
    previewUrl: "",
    countriesTargeting: [],
    revenue: "",
    payouts: "",
    goalValue: "",
    goalTitle: "",
    countriesPayout: [],
    domains: "track.go4aluna.co",
    typeOfClient: "",
    encargadaCsm: "",
    monedaAnunciante: "",
    revenueMonedaAnunciante: "",
    payoutAceptado: "",
    capEstimado: "",
    pixelStatus: "",
    conversionTypeKALA: "",
    privacy: "protected",
    ticketPromedio: "",
    direct: "",
  });

  const handleEdit = async (rowData) => {
    setDataForm({
      offerTitle: rowData.row.title,
      advertisers: rowData.row.advertisers,
      trackingUrl: rowData.row.trackingUrl,
      paymentType: rowData.row.paymentTypePayout,
      currencies: "USD",
      dateRange: [
        {
          startDate: new Date(rowData.row.dateFrom),
          endDate: new Date(rowData.row.dateTo),
          key: "selection",
        },
      ],
      status: rowData?.row?.status,
      trafficSourcesAffise: rowData.row.trafficSources,
      description: rowData.row.description,
      tags: rowData.row.tags,
      categories: rowData.row.categories,
      previewUrl: rowData.row.previewUrl,
      countriesTargeting: rowData.row.countriesTarget,
      revenue: rowData.row.payoutsPayout,
      payouts: rowData.row.revenuePayout,
      goalValue: rowData.row.goalValuePayout,
      goalTitle: "",
      countriesPayout: rowData.row.countriesPayout,
      privacy: rowData.row.privacy,
      personalTargeting: rowData.row.personalTargeting,
    });
    setModalOpen(true);
    setCurrentRowData(rowData);
    setPersonalPayout(rowData?.row?.personalPayout);
    setPersonalTargeting(rowData?.row?.personalTargeting ?? []);
    setDomainsChosen(rowData.row.domains);
    setOfferId(rowData.row.id);
    setCreatives(rowData.row.creatives);
    setLanding(rowData.row.landings);
    setLogo(rowData.row.logo);
    setDescription(rowData.row.description);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Deseas editar la campaña?",
      showCancelButton: true,
      icon: "question",
      confirmButtonText: "Editar",
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        const offerData = {
          personalPayout: personalPayout,
          personGeneralTabalTargeting: transformedPersonalTargeting,
          offerTitle: dataForm.offerTitle,
          advertiser: dataForm.advertisers,
          description: description,
          // targeting: targeting,
          sources: dataForm.trafficSourcesAffise,
          status: dataForm.status,
          tags: dataForm.tags,
          dateStart: dataForm.dateRange[0].startDate,
          dateTo: dataForm.dateRange[0].endDate,
          categories: dataForm.categories,
          trackingDomain: domainsChosen,
          previewUrl: dataForm.previewUrl,
          url: dataForm.trackingUrl,
          targetingCountry: dataForm.countriesTargeting,
          paymentCountry: dataForm.countriesPayout,
          paymentType: dataForm.paymentType,
          paymentPayouts: dataForm.payouts,
          paymentRevenue: dataForm.revenue,
          currency: "",
          paymentGoal: dataForm.goalValue,
          paymentGoalTitle: "",
          typeClient: "",
          encargadaCSM: "",
          monedaAnunciante: "",
          revenueMonedaAnunciante: "",
          BAT: "",
          other: "",
          id: offerId,
          payoutAceptado: "",
          estimadoCapMensual: "",
          privacy: dataForm.privacy,
          landing: landing,
        };
        await editOfferAffise(offerData)
          .catch((error) => {
            Swal.fire(
              `Error: ${error.response.status}. Se canceló el proceso, verificar información`,
              "",
              "error"
            );
          })
          .then((response) => {
            postFile([logo], offerId, "postLogoAffise", "logo", 0).then(
              (res) => {
                postFile(
                  newCreatives,
                  offerId,
                  "postFileAffise",
                  "creatives",
                  creatives.length
                );
              }
            );
          })
          .catch((error) => {
            Swal.fire(
              `Error: ${error.response.status}, se canceló el proceso`,
              "",
              "error"
            );
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Se ha editado la campaña con éxito", "", "success").then(
          setModalOpen(false)
        );
      } else {
        Swal.fire("Se ha cancelado la edición de campaña", "", "info");
      }
    });
  };

  const cancelButton = () => {
    setDataForm({
      offerTitle: "",
      advertisers: {
        id: 93,
        name: "INHOUSE TEST",
        affiseID: "60e4b8aa17b9f9e30611e655",
      },
      trackingUrl: "",
      paymentType: "fixed",
      currencies: "USD",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
      status: "",
      trafficSourcesAffise: [],
      tags: [],
      categories: [],
      previewUrl: "",
      countriesTargeting: [],
      revenue: "",
      payouts: "",
      goalValue: "",
      goalTitle: "",
      countriesPayout: [],
      domains: "track.go4aluna.co",
      typeOfClient: "",
      encargadaCsm: "",
      monedaAnunciante: "",
      revenueMonedaAnunciante: "",
      payoutAceptado: "",
      capEstimado: "",
      pixelStatus: "",
      conversionTypeKALA: "",
      privacy: "protected",
      ticketPromedio: "",
      other: "",
    });

    setModalOpen(!modalOpen);
    setCurrentRowData(null);
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
    setCurrentRowData(null);
  };

  const handleTab = (event, newValue) => {
    event.preventDefault();
    setValueTab(newValue);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setTimeout(window.unlock, 10);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Edit",
        field: "edit",
        width: 50,
        renderCell: (rowData) => (
          <Edit
            onClick={() => handleEdit(rowData)}
            style={{ cursor: "pointer", color: "black" }}
          />
        ),
        headerAlign: "center",
      },
      {
        field: "id",
        headerName: "Offer ID",
        width: 100,
        headerAlign: "center",
      },
      {
        field: "title",
        headerName: "Offer",
        width: 180,
        headerAlign: "center",
      },
      {
        field: "status",
        headerName: "Status",
        width: 70,
        headerAlign: "center",
      },
      {
        field: "description",
        headerName: "Description",
        width: 120,
        headerAlign: "center",
        renderCell: (params) => (
          <Tooltip
            title={params?.row?.description?.replace(/(<([^>]+)>)/gi, "")}
          >
            <div
              style={{
                width: "120px",
                height: "100%",
                display: "flex",
                padding: "0 10px",
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: params.row.description,
                }}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </div>
          </Tooltip>
        ),
      },

      {
        field: "advertisers",
        headerName: "Advertisers",
        width: 120,
        headerAlign: "center",
        renderCell: ({ row }) => <p>{row?.advertisers?.name}</p>,
      },
      {
        field: "trafficSources",
        headerName: "Traffic Sources",
        width: 120,
        headerAlign: "center",
        renderCell: ({ value }) => {
          const traffic = value.map((traffic) => traffic.title);

          return <p>{traffic.join(", ")}</p>;
        },
      },
      { field: "tags", headerName: "Tags", width: 120, headerAlign: "center" },

      {
        field: "trackingUrl",
        headerName: "Tracking URL",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "previewUrl",
        headerName: "Preview URL",
        width: 160,
        headerAlign: "center",
      },
      {
        field: "domains",
        headerName: "Domains",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "countriesTarget",
        headerName: "Countries Target",
        width: 130,
        headerAlign: "center",
      },
      {
        field: "countriesPayout",
        headerName: "Countries Payout",
        width: 130,
        headerAlign: "center",
      },
      {
        field: "paymentTypePayout",
        headerName: "Payment Type",
        width: 120,
        headerAlign: "center",
      },

      {
        field: "payoutsPayout",
        headerName: "Revenue",
        width: 80,
        headerAlign: "center",
      },
      {
        field: "revenuePayout",
        headerName: "Payouts",
        width: 80,
        headerAlign: "center",
      },
      {
        field: "currenciesPayout",
        headerName: "Currencies",
        width: 100,
        headerAlign: "center",
      },
      {
        field: "goalValuePayout",
        headerName: "Goal Value",
        width: 90,
        headerAlign: "center",
      },
      {
        field: "goalTitle",
        headerName: "Goal Title",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "dateFrom",
        headerName: "Date From",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "dateTo",
        headerName: "Date To",
        width: 120,
        headerAlign: "center",
      },
    ],
    []
  );

  return (
    <>
      <div className={styles.wrapper}>
        <Box className={globalStyles.tabContainer}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            sx={{ margin: "0 24px", borderBottom: 1, borderColor: "divider" }}
          >
            {tabConfig.map((config, index) => (
              <Tab label={config.label} {...a11yProps(index)} key={index} />
            ))}
          </Tabs>
        </Box>
        {activeOffers.length === 0 ? (
          <Box className={globalStyles.globalContainer} sx={boardStyles}>
            <TabPanel>
              <DataGridPro
                editMode="row"
                rowHeight={40}
                headerHeight={40}
                rows={activeOffers}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={(row) => activeOffers.indexOf(row)}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                loading
                style={{ height: "700px" }}
              />
            </TabPanel>
          </Box>
        ) : (
          <>
            <Box className={globalStyles.globalContainer} sx={boardStyles}>
              {tabConfig.map((config, index) => (
                <TabPanel
                  value={tab}
                  index={index}
                  key={index}
                  sx={{ padding: "0" }}
                >
                  <DataGridPro
                    editMode="row"
                    rows={config.rows}
                    columns={columns}
                    rowHeight={40}
                    headerHeight={40}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row) => config.rows.indexOf(row)}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          debounceMs: 500,
                          placeholder: "Buscar...",
                        },
                      },
                    }}
                    style={{ height: "700px", backgroundColor: "#fefcfd" }}
                  />
                </TabPanel>
              ))}
            </Box>

            <Modal open={modalOpen} onClose={handleClose}>
              <div>
                {currentRowData && (
                  <>
                    <Box
                      component="form"
                      onSubmit={handleSubmitEdit}
                      sx={modalEditStyles}
                      className={globalStyles.campaignCreatorContainer}
                    >
                      <div className={styles.stepperContainer}>
                        <MainTabs
                          isEdit={isEdit}
                          dataForm={dataForm}
                          setDataForm={setDataForm}
                          apiUrl={apiUrl}
                          header={header}
                          dataCaps={dataCaps}
                          setDataCaps={setDataCaps}
                          dataLanding={landing}
                          setDataLanding={setLanding}
                          logo={logo}
                          setLogo={setLogo}
                          oldCreatives={creatives}
                          setCreatives={setCreatives}
                          newCreatives={newCreatives}
                          setNewCreatives={setNewCreatives}
                          creatives={creatives}
                          offerId={offerId}
                          activeStep={activeStep}
                          handleStep={handleStep}
                          description={description}
                          setDescription={setDescription}
                          personalPayout={personalPayout}
                          setPersonalPayout={setPersonalPayout}
                          valueTab={valueTab}
                          handleTab={handleTab}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags}
                          personalTargeting={personalTargeting}
                          setPersonalTargeting={setPersonalTargeting}
                          transformedPersonalTargeting={
                            transformedPersonalTargeting
                          }
                          setTransformedPersonalTargeting={
                            setTransformedPersonalTargeting
                          }
                        />

                        <div className={styles.submitContainer}>
                          <Button
                            sx={{
                              color: "black",
                              marginRight: "14px",
                              backgroundColor: "white",
                            }}
                            variant="outlined"
                            onClick={cancelButton}
                          >
                            Cancelar
                          </Button>
                          <ThemeProvider theme={theme}>
                            <Button type="submit" variant="contained">
                              Editar Campaña
                            </Button>
                          </ThemeProvider>
                        </div>
                      </div>
                    </Box>
                  </>
                )}
              </div>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default EditCampaign2;
