import { Box, Button, Menu, TextField, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { editBoardField } from "../../utils/ApiCall";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Swal from "sweetalert2";
const EditableText = ({
  row,
  field,
  handleEditResponseChange,
  initialValue,
  styleText,
}) => {
  const [newComment, setNewComment] = useState(initialValue || "");
  let currentUrl = window.location.pathname;

  const [anchorEl, setAnchorEl] = useState(null);
  const [{ open }, setState] = useState({
    open: false,
  });
  const [windowWidth] = useState(window.innerWidth);

  const [saveButton] = useState("Guardar");
  const [disableSave] = useState(false);
  const isMobile = windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const cellStyle = {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    cursor: "pointer",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "24.3%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "50px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = async () => {
    const detail = await editBoardField(
      currentUrl,
      [row.id],
      field,
      newComment
    );
    if (detail[0]?.status === 400) {
      Swal.fire(
        `${detail[0]?.data?.error?.message ?? "Bad Request"}`,
        "",
        "error"
      );
      handleClose();
    } else {
      handleEditResponseChange(newComment, row.id, field);
      handleClose();
    }
  };
  return (
    <Box>
      <Tooltip title={initialValue ?? null}>
        <div onClick={handleClick} ref={cellRef} style={cellStyle}>
          {initialValue === null ||
          initialValue === "" ||
          initialValue === undefined ? (
            <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
          ) : styleText ? (
            <span>{initialValue}</span>
          ) : (
            initialValue
          )}
        </div>
      </Tooltip>
      <Menu {...menuProps}>
        <TextField
          sx={{ width: "100%" }}
          multiline
          minRows={3}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {saveButton}
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableText;
