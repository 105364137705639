import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import { createTagRoundler } from "../../../../../../utils/ApiCall.js";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import style from "./ModalCreate.module.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
};

const TAG_TYPES = ["script"];

const ModalCreate = ({ handleClose, modalOpen, id }) => {
  const [type, setType] = useState(TAG_TYPES[0]);
  const newTagFields = {
    type: type,
    name: "",
    basket: { content: "" },
    category: { content: "" },
    home: { content: "" },
    product: { content: "" },
    thankyou: { content: "" },
  };
  const [currentNewTags, setCurrentNewTags] = useState([
    {
      type: type,
      name: "",
      basket: { content: "" },
      category: { content: "" },
      home: { content: "" },
      product: { content: "" },
      thankyou: { content: "" },
    },
  ]);

  const addNewTag = () => {
    const newTag = { ...newTagFields };
    setCurrentNewTags((prevTags) => [...prevTags, newTag]);
  };

  const deleteTag = (index) => {
    setCurrentNewTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleNewTagChange = (field, value, index) => {
    setCurrentNewTags((prevTags) =>
      prevTags.map((tag, i) =>
        i === index
          ? {
              ...tag,
              [field]:
                typeof tag[field] === "object" ? { content: value } : value,
            }
          : tag
      )
    );
  };

  const handleSubmitCreate = async (e, data) => {
    e.preventDefault();

    try {
      const payload = {
        content: [...data],
      };

      await createTagRoundler(id, payload);

      handleClose();
    } catch (error) {
      console.error("Error creating tag:", error);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={modalOpen}>
        <Box
          sx={modalStyle}
          onSubmit={(e) => handleSubmitCreate(e, currentNewTags)}
          component="form"
        >
          <Box className={style.closeContainer}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Box>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <Box>
              {/* Renderizar los 6 TextField para los nuevos tags */}
              {currentNewTags.map((tag, index) => (
                <div key={index} className={style.modalContainer}>
                  <div className={style.deleteTag}>
                    <Tooltip title="Eliminar Tag">
                      <IconButton onClick={() => deleteTag(index)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </div>

                  {/* Renderizar los TextField para los nuevos tags actuales */}
                  {Object.keys(tag).map((fieldName) => (
                    <React.Fragment key={fieldName}>
                      {fieldName === "type" ? (
                        <FormControl sx={{ margin: "10px 0px", width: "50%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Type"
                            onChange={handleChangeType}
                          >
                            <MenuItem value={"script"}>Script</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          label={`${
                            fieldName.charAt(0).toUpperCase() +
                            fieldName.slice(1)
                          }  Script tag `}
                          value={
                            typeof tag[fieldName] === "object"
                              ? tag[fieldName].content
                              : tag[fieldName]
                          }
                          onChange={(e) =>
                            handleNewTagChange(fieldName, e.target.value, index)
                          }
                          className={style.textFieldContainer}
                          sx={{ margin: "10px 0px" }}
                          required={true}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))}
              <div className={style.buttonsContainer}>
                <Button onClick={addNewTag}>Nuevo Tag</Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "var(--blueFour)",
                    color: "#fcfaff",
                    textTransform: "none",
                    padding: "4px 8px",
                    minWidth: "80px",
                    height: "35px",
                    fontSize: "16px",
                    "&: hover": {
                      backgroundColor: "var(--blue)",
                    },
                  }}
                >
                  Guardar
                </Button>
              </div>
            </Box>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalCreate;
