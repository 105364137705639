import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Chip, Grid } from "@mui/material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { useEffect } from "react";
import { format } from "date-fns";

export default function SmallCard({
  title,
  data,
  isLoading,
  progress,
  timeIcon,
  moreExpensive,
}) {
  const [formattedDate, setFormattedDate] = useState("");
  const [isLoss, setIsLoss] = useState(null);
  const actualMonth = data?.total?.total;
  const lastMonth = data?.total?.lastMonth;
  const difference = actualMonth - lastMonth;
  const value = difference.toFixed(2);
  const renderValue = Math.abs(value);

  const percentageDifference = (
    ((actualMonth - lastMonth) / lastMonth) *
    100
  ).toFixed(2);

  useEffect(() => {
    if (value < 0) {
      setIsLoss(true);
    }
  }, [value, moreExpensive]);

  useEffect(() => {
    if (data?.lastUpdated) {
      const date = new Date(data?.lastUpdated);
      const formattedDate = format(date, "yyyy-MM-dd 'at' hh:mm a");
      setFormattedDate(formattedDate);
    }
  }, [data]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <CardContent sx={{ height: "100%", width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            width="100%"
            justifyContent={isLoading ? "center" : "flex-start"}
          >
            {isLoading ? (
              <Box>{progress}</Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 12 }}
                    color="text.secondary"
                    display="flex"
                  >
                    {timeIcon} {formattedDate}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: 16 }} color="text.secondary">
                  {title}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{ fontSize: "clamp(20px, 2vw, 36px)" }}
                    color="text.primary"
                    fontWeight="600"
                  >
                    ${actualMonth?.toLocaleString()}
                  </Typography>

                  <Box>
                    <Grid item>
                      <Chip
                        variant="combined"
                        style={{
                          backgroundColor: moreExpensive
                            ? moreExpensive
                            : !isLoss
                            ? "var(--greenGrass)"
                            : "var(--issues)",
                          borderRadius: "5px",
                        }}
                        icon={
                          <>
                            {!isLoss && (
                              <TrendingUpOutlinedIcon
                                style={{ fontSize: "1.2rem", color: "white" }}
                              />
                            )}

                            {isLoss && (
                              <TrendingDownIcon
                                style={{ fontSize: "1.25rem", color: "white" }}
                              />
                            )}
                          </>
                        }
                        label={
                          lastMonth === 0 ? "0%" : `${percentageDifference}%`
                        }
                        sx={{
                          ml: 1.25,
                          pl: 1,
                          color: "white",
                          fontSize: "14px",
                        }}
                        size="small"
                      />
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    {isLoss ? "You made " : "You made an extra "}
                    <Typography
                      component="span"
                      variant="h6"
                      sx={{
                        color: moreExpensive
                          ? moreExpensive
                          : !isLoss
                          ? "var(--greenGrass)"
                          : "var(--issues)",
                      }}
                    >
                      {isLoss && "-"}${renderValue?.toLocaleString()}
                    </Typography>{" "}
                    this month
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
