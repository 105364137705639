
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";



const CheckSession = async () => {
const navigate = useNavigate();

const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000 // d * h * m * s * ms
const currentTimestamp = Date.now();
const session = localStorage?.getItem("session")

if (session && currentTimestamp - session > sevenDaysInMilliseconds) {

    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("session");
    localStorage.removeItem("role");
    Swal.fire("Su sesión expiró, por favor ingresar nuevamente", "", "info").then(
    navigate(`/`, { replace: true }))
}

}

export default CheckSession