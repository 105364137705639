import React, { useEffect } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { useState } from "react";
import Threads from "../../../Threads/Threads.jsx";
import { prospectingComercialStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/ColumnStatus.jsx";
import EditableText from "../../../../components/global/EditableText.jsx";
import SingleDuration from "../../../../components/global/SingleDuration.jsx";
import EditableSelectCountry from "../../../../components/global/EditableSelectCountry.jsx";
import { agencia } from "../../../../json/CampaignCreator.js";
import { countries } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import SingleUserSelect from "../../../../components/global/SingleUserSelect.jsx";
import EditableSelect from "../../../../components/global/EditableSelect.jsx";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Prospecting = () => {
  const [groups] = useState(["Prospecting"]);
  const [status, setStatus] = useState("Prospecting");
  const apiUrl = `${backendUrl}/api/prospecting/items`;
  const apiUsers = `${backendUrl}/api/users`;
  const [users, setUsers] = useState([]);

  const [items, setItems] = useState([]);
  const [selectionRow, setSelectionRow] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: [],
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();

      setUsers(data);
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsAndSetGroups = (items) => {
    setItems(items);
  };

  // const controlEdit = async (params) => {
  //   let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
  //   switch (params.field) {
  //     case "payAffiliate":
  //       await editBoardField(
  //         "/solicitud-campana",
  //         ids,
  //         params.field,
  //         payAffiliate
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    setItems(updatedRowsNoTerminados);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Prospecting" ? items : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "commercial",
      headerName: "Comercial",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleUserSelect
          {...params}
          users={users.filter((user) =>
            ["Victor Perez", "Alexis Peña"].includes(user.username)
          )}
          field={"commercial"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "agency",
      headerName: "Agencia",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"agency"}
          handleEditResponseChange={handleEditResponseChange}
          options={agencia}
          spanText={params.row.agency}
          initialValue={params.row.agency}
          label="Agencia"
        />
      ),
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={countries}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "advertiser",
      headerName: "Advertiser",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"advertiser"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.advertiser}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={prospectingComercialStatus}
        />
      ),
    },
    {
      field: "reminder",
      headerName: "Recordatorio",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration {...params} selectionRow={selectionRow} />
      ),
    },
    {
      field: "contact",
      headerName: "Contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contact"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contact}
        />
      ),
    },
    {
      field: "contactCharged",
      headerName: "Cargo contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contactCharged"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contactCharged}
        />
      ),
    },
    {
      field: "comment",
      headerName: "Comentario",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"comment"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.comment}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        // controlEdit={controlEdit}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default Prospecting;
