import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import {
  divStyle,
  privacyLevel,
  sortAlphabetically,
  status,
} from "../../../json/CampaignCreator";
import { fetchData } from "../../../utils/ApiCall";

const GeneralTab = ({
  isEdit,
  dataForm,
  setDataForm,
  apiUrl,
  descriptionCreatives,
  setDescriptionCreatives,
  descriptionTargeting,
  setDescriptionTargeting,
  setSelectedCategories,
  setSelectedTags,
  description,
  setDescription,
}) => {
  const [trafficSources, setTrafficSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const quillRef = useRef(null);

  useEffect(() => {
    const fetchApi = async () => {
      const resSources = await fetchData(`${apiUrl}offer-service/getSources`);
      setTrafficSources(sortAlphabetically(resSources, "title"));

      const resCategories = await fetchData(
        `${apiUrl}offer-service/getCategories`
      );
      setCategories(sortAlphabetically(resCategories, "title"));
      setTags(
        sortAlphabetically(
          resCategories.map((tag) => tag.title),
          "title"
        )
      );

      // Establecer los valores seleccionados en los autocompletados
      setSelectedCategories(
        categories.filter((category) =>
          dataForm.categories.includes(category.id)
        )
      );
      setSelectedTags(tags.filter((tag) => dataForm.tags.includes(tag.title)));
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataForm((prevState) => ({
      ...prevState,
      descriptionTargeting,
      descriptionCreatives,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionTargeting, descriptionCreatives]);

  const handleAutocompleteChange = (name, newValue) => {
    setDataForm((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue : [],
    }));
  };

  const handleMultipleAutocompleteChange = (name, newValue) => {
    if (name === "categories") {
      const selectedIDs = newValue.map((item) => item);
      const selectedTitles = newValue.map((tag) => tag.title);

      setDataForm((prevDataForm) => ({
        ...prevDataForm,
        categories: selectedIDs,
        tags: selectedTitles,
      }));
      setSelectedTags(selectedTitles);
      setSelectedCategories(newValue);
    } else if (name === "tags") {
      const selectedIDs = categories.filter((categorie) => {
        if (newValue.includes(categorie.title)) {
          return categorie;
        }
      });

      const selectedTitles = newValue.map((item) => item);

      setDataForm((prevDataForm) => ({
        ...prevDataForm,
        categories: selectedIDs,
        tags: selectedTitles,
      }));

      setSelectedCategories(selectedIDs);
      setSelectedTags(newValue);
    } else {
      const selectedAffiseIDs = newValue.map((item) => item);

      setDataForm((prevDataForm) => ({
        ...prevDataForm,
        [name]: selectedAffiseIDs,
      }));
    }
  };

  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },
  }).current;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "800px",
      }}
    >
      <div style={divStyle}>
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={status}
          getOptionLabel={(option) => option.name}
          sx={{ width: "385px", marginRight: "30px" }}
          onChange={(event, newValue) =>
            handleAutocompleteChange("status", newValue.value)
          }
          value={status.find((stat) => stat.value === dataForm.status) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              variant="standard"
              value={dataForm.status}
            />
          )}
        />
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={privacyLevel}
          getOptionLabel={(option) => option.name}
          sx={{ width: "385px" }}
          onChange={(event, newValue) =>
            handleAutocompleteChange("privacy", newValue.value)
          }
          value={
            privacyLevel.find((level) => level.value === dataForm.privacy) ||
            dataForm.privacy
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Privacy Level"
              variant="standard"
              value={dataForm.privacy}
            />
          )}
        />
      </div>

      <div style={divStyle}>
        <Autocomplete
          size="small"
          multiple
          disablePortal
          id="combo-box-demo"
          options={trafficSources}
          getOptionLabel={(trafficSources) => trafficSources?.title}
          sx={{ width: "800px" }}
          onChange={(event, newValue) =>
            handleMultipleAutocompleteChange("trafficSourcesAffise", newValue)
          }
          value={
            dataForm.trafficSourcesAffise !== null
              ? dataForm.trafficSourcesAffise.map((selectedTrafficSource) =>
                  trafficSources.find(
                    (trafficSource) =>
                      trafficSource.id === selectedTrafficSource.id
                  )
                )
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Traffic Sources"
              variant="standard"
              value={dataForm.trafficSources}
            />
          )}
        />
      </div>

      {isEdit ? (
        <ReactQuill
          theme="snow"
          value={description}
          onChange={setDescription}
          placeholder="Description"
          modules={modules}
          ref={quillRef}
          style={{
            width: "800px",
            height: "fit-content",
            marginBottom: "60px",
          }}
        />
      ) : (
        <>
          <div>
            <ReactQuill
              theme="snow"
              value={descriptionTargeting}
              onChange={setDescriptionTargeting}
              style={{ width: "800px", marginBottom: "30px" }}
              placeholder="Description - Targeting"
            />
          </div>

          <div>
            <ReactQuill
              theme="snow"
              value={descriptionCreatives}
              onChange={setDescriptionCreatives}
              style={{ width: "800px", marginBottom: "30px" }}
              placeholder="Description - Creatives"
            />
          </div>
        </>
      )}

      <div style={divStyle}>
        <Autocomplete
          size="small"
          multiple
          disablePortal
          id="combo-box-demo"
          options={tags}
          getOptionLabel={(tags) => tags}
          sx={{ width: "385px", marginRight: "30px", marginTop: "3px" }}
          onChange={(event, newValue) =>
            handleMultipleAutocompleteChange("tags", newValue)
          }
          value={
            dataForm.tags !== null
              ? dataForm.tags.map((tag) => tags.find((t) => tag === t))
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tags"
              variant="standard"
              value={dataForm.tags.map((tags) => tags)}
            />
          )}
        />

        <Autocomplete
          size="small"
          multiple
          disablePortal
          id="combo-box-demo"
          options={categories}
          getOptionLabel={(categories) => categories?.title}
          sx={{ width: "385px", marginTop: "3px" }}
          onChange={(event, newValue) =>
            handleMultipleAutocompleteChange("categories", newValue)
          }
          value={
            dataForm.categories !== null
              ? dataForm.categories.map((category) =>
                  categories.find((cat) => cat.id === category.id)
                )
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Categories"
              variant="standard"
              value={dataForm.categories}
            />
          )}
        />
      </div>
    </Box>
  );
};

export default GeneralTab;
