import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { format } from "date-fns";

const GridList = ({ newAffiliates, isLoading, progress, timeIcon }) => {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (newAffiliates?.lastUpdated) {
      const date = new Date(newAffiliates?.lastUpdated);
      const formattedDate = format(date, "yyyy-MM-dd 'at' hh:mm a");
      setFormattedDate(formattedDate);
    }
  }, [newAffiliates]);

  const reversedAffiliates = newAffiliates?.newAffiliates?.slice()?.reverse();

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        borderRadius: "10px",
        minHeight: "120px",
        overflowY: "scroll",
        backgroundColor: "white",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: isLoading ? "center" : "flex-start",
        alignItems: isLoading ? "center" : "inherit",
      }}
    >
      {isLoading ? (
        <Box>{progress}</Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: 12 }}
              color="text.secondary"
              display="flex"
            >
              {timeIcon} {formattedDate}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: 16 }} color="text.secondary">
            New affiliates
          </Typography>
          {reversedAffiliates.map((affiliate, index) => (
            <Grid item xs={12} key={index}>
              <Box
                style={{ padding: "16px 0px", margin: "10px 0px" }}
                sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              >
                <Typography
                  sx={{ fontSize: 16 }}
                  color="text.primary"
                  fontWeight="600"
                >
                  {affiliate.title}
                </Typography>
                <Typography variant="body2">
                  Created at:{" "}
                  {format(new Date(affiliate.affise_createdAt), "dd/MM/yyyy")}
                </Typography>
              </Box>
            </Grid>
          ))}
        </>
      )}
    </Card>
  );
};

export default GridList;
