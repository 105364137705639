import { Box, Typography } from "@mui/material";
import React from "react";

const Header = ({ title }) => {
  return (
    <Box>
      <Typography color="#10002b" variant="h2" fontWeight="medium" mb="12px">
        {title}
      </Typography>
    </Box>
  );
};

export default Header;
