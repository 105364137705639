export const fileTypes = [
  "JPG",
  "PNG",
  "GIF",
  "PDF",
  "XLSX",
  "TXT",
  "ZIP",
  "CSV",
  "RAR",
  "XLS",
  "PPTX",
  "PPT",
  "HTML",
];
