import React from "react";
import { Button } from "@mui/material";

import ReplyIcon from "@mui/icons-material/Reply";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";

import styles from "../../styles/Threads.module.css";

const ActionsButtons = ({
  thread,
  handleFormSubmitLike,
  handleResponse,
  response,
  index,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        borderTop: "1px solid #dee2ff",
        borderBottom:
          (thread?.comments?.length === 0 && response) ||
          thread?.comments?.length > 0
            ? "1px solid #dee2ff"
            : "0px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            thread?.likes?.length > 0 ? "var(--blueTwo)" : "transparent",
          borderBottomLeftRadius: "8px",
        }}
      >
        <Button
          style={{
            textTransform: "none",
            fontSize: "16px",
            padding: "0 12px",
            width: "100%",
            height: "100%",
            fontWeight: "400",
            color: thread?.likes?.length > 0 ? "white" : "var(--blackLow)",
          }}
          onClick={() => handleFormSubmitLike(thread.id, null)}
        >
          {thread?.likes?.length > 0 ? (
            <ThumbDownAltOutlinedIcon style={{ marginRight: "12px" }} />
          ) : (
            <ThumbUpAltOutlinedIcon
              style={{
                marginRight: "12px",
                color: "var(--blackLow)",
              }}
            />
          )}

          {thread?.likes?.length > 0 ? "No me gusta" : "Me gusta"}
        </Button>
      </div>

      <span className={styles.divisionLine}></span>

      <div className={styles.divisionContainer}>
        <Button
          className={styles.responderButton}
          onClick={(e) => handleResponse(e, index)}
          style={{
            textTransform: "none",
            fontSize: "16px",
            color: "var(--blackLow)",
          }}
        >
          <ReplyIcon
            style={{
              marginRight: "12px",
              color: "var(--blackLow)",
            }}
          />
          Responder
        </Button>
      </div>
    </div>
  );
};

export default ActionsButtons;
