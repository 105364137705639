const inlineStyles = {
  viewMoreButtom: {
    color: "var(--blueTwo)",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    textTransform: "none",
    marginBottom: "20px",
  },
  closeButtom: {
    zIndex: "1500",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    textTransform: "none",
    color: "var(--blueTwo)",
    marginLeft: "8px",
    backgroundColor: "#fcfaff",
  },
  fileGradientDiv: (boolean) => {
    return {
      minHeight: "200px",
      minWidth: "600px",
      overflow: "hidden",
      position: "relative",
      backgroundImage: boolean
        ? "none"
        : "linear-gradient(to bottom, transparent , #fcfaff 70%))",
      marginBottom: "20px",
      marginLeft: "16px",
    };
  },
  resizable: {
    position: "absolute",
    top: "50%",
    right: "0",
    bottom: "0%",
    transform: "translate(-0%, -50%)",
    height: "100vh",
    overflow: "hidden",
    overflowY: "scroll",
    backgroundColor: "#f8f7ff",
    borderLeft: "1px solid white",
    boxShadow: "24",
    alignItems: "center",
  },
};

export default inlineStyles;
