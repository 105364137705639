import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import { postFile, postOfferAffise } from "../../utils/ApiCall";
import MainTabs from "./MainTabs";
import Swal from "sweetalert2";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CampaignCreator = () => {
  const apiUrl = `${backendUrl}/api/`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));

  let header;
  header = {
    Accept: "/",
    Authorization: `Bearer ${key}`,
  };

  const [dataForm, setDataForm] = useState({
    board: "MASTER PERFORMANCE",
    offerTitle: "",
    advertisers: {
      name: "INHOUSE TEST",
      affiseID: "60e4b8aa17b9f9e30611e655",
      id: 4782116,
    },
    trackingUrl: "",
    paymentType: "fixed",
    currencies: "USD",
    dateRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    status: "",
    trafficSourcesAffise: [],
    descriptionTargeting: "",
    descriptionCreatives: "",
    tags: [],
    categories: [],
    previewUrl: "",
    countriesTargeting: [],
    revenue: "",
    payouts: "",
    goalValue: "",
    goalTitle: "",
    countriesPayout: [],
    domains: "track.go4aluna.co",
    typeOfClient: "",
    encargadaCsm: "",
    monedaAnunciante: "",
    revenueMAnunciante: "",
    payoutAceptado: "",
    capEstimado: "",
    pixelStatus: "",
    conversionTypeKALA: "",
    privacy: "protected",
    ticketPromedio: "",
    direct: "",
    responsible: "",
    owner: "",
    // format: "",
    platform: "",
    typeOfContract: "",
    segmentation: "",
    billingMonth: "",
    test: "",
    offerStatus: "ACTIVE",
    payoutUSD: "",
    revenueUSD: "",
    trafficSources: [],
  });

  const [dataCaps, setDataCaps] = useState([]);
  const [dataLanding, setDataLanding] = useState([]);
  const [descriptionCreatives, setDescriptionCreatives] = useState("");
  const [descriptionTargeting, setDescriptionTargeting] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [logo, setLogo] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [bat, setBat] = useState("");
  const [checkboxBat, setCheckboxBat] = useState(false);
  const [direct, setDirect] = useState("");
  const [checkboxDirect, setCheckboxDirect] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // setDisableButton(true);
    if (
      dataForm.offerTitle === "" ||
      dataForm.advertisers === "" ||
      dataForm.trackingUrl === "" ||
      dataForm.paymentType === "" ||
      dataForm.currencies === "" ||
      dataForm.dateRange[0].startDate === "" ||
      dataForm.dateRange[0].endDate === ""
    ) {
      alert("Tienes campos obligatorios sin completar");
    } else {
      Swal.fire({
        title: "¿Deseas publicar la campaña",
        showCancelButton: true,
        icon: "question",
        confirmButtonColor: "var(--blue)",
        cancelButtonColor: "red",
        cancelButtonText: `Cancelar`,
        confirmButtonText: "Publicar",

        showLoaderOnConfirm: true,
        // allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          await postOfferAffise(dataForm, dataCaps, dataLanding, bat, direct)
            .catch((error) => {
              Swal.fire(
                `Error: ${error.response.status}. Se canceló el proceso, verificar información`,
                "",
                "error"
              );
            })
            .then((response) => {
              postFile(
                logo,
                response.data.offerID,
                "postLogoAffise",
                "logo",
                0
              ).then((res) => {
                postFile(
                  creatives,
                  response.data.offerID,
                  "postFileAffise",
                  "creatives",
                  0
                );

                setDataForm({
                  board: "MASTER PERFORMANCE",
                  offerTitle: "",
                  advertisers: {
                    name: "INHOUSE TEST",
                    affiseID: "60e4b8aa17b9f9e30611e655",
                    id: 4782116,
                  },
                  trackingUrl: "",
                  paymentType: "fixed",
                  currencies: "USD",
                  dateRange: [
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: "selection",
                    },
                  ],
                  status: "",
                  trafficSourcesAffise: [],
                  descriptionTargeting: "",
                  descriptionCreatives: "",
                  tags: [],
                  categories: [],
                  previewUrl: "",
                  countriesTargeting: [],
                  revenue: "",
                  payouts: "",
                  goalValue: "",
                  goalTitle: "",
                  countriesPayout: [],
                  domains: "track.go4aluna.co",
                  typeOfClient: "",
                  encargadaCsm: "",
                  monedaAnunciante: "",
                  revenueMAnunciante: "",
                  payoutAceptado: "",
                  capEstimado: "",
                  pixelStatus: "",
                  conversionTypeKALA: "",
                  privacy: "protected",
                  ticketPromedio: "",
                  other: "",
                  responsible: "",
                  owner: "",
                  // format: "",
                  platform: "",
                  typeOfContract: "",
                  segmentation: "",
                  billingMonth: "",
                  test: "",
                  offerStatus: "ACTIVE",
                  payoutUSD: "",
                  revenueUSD: "",
                  trafficSources: [],
                });

                setDataCaps([]);
                setDataLanding([]);
                setDescriptionCreatives("");
                setDescriptionTargeting("");
                setBat("");
                setCheckboxBat(false);
                setDirect("");
                setCheckboxDirect(false);
                setLogo([]);
                setCreatives([]);
                setActiveStep(0);
              });
            })
            .catch((error) => {
              Swal.fire(
                `Error: ${error.response.status}, se canceló el proceso`,
                "",
                "error"
              );
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Se ha subido la campaña con éxito", "", "success");
        } else {
          Swal.fire("Se ha cancelado la subida de campaña", "", "info");
        }
      });
    }
  };

  const cancelButton = () => {
    setDataForm({
      board: "MASTER PERFORMANCE",
      offerTitle: "",
      advertisers: {
        name: "INHOUSE TEST",
        affiseID: "60e4b8aa17b9f9e30611e655",
        id: 4782116,
      },
      trackingUrl: "",
      paymentType: "fixed",
      currencies: "USD",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
      status: "",
      trafficSourcesAffise: [],
      descriptionTargeting: "",
      descriptionCreatives: "",
      tags: [],
      categories: [],
      previewUrl: "",
      countriesTargeting: [],
      revenue: "",
      payouts: "",
      goalValue: "",
      goalTitle: "",
      countriesPayout: [],
      domains: "track.go4aluna.co",
      typeOfClient: "",
      encargadaCsm: "",
      monedaAnunciante: "",
      revenueMAnunciante: "",
      payoutAceptado: "",
      capEstimado: "",
      pixelStatus: "",
      conversionTypeKALA: "",
      privacy: "protected",
      ticketPromedio: "",
      other: "",
      responsible: "",
      owner: "",
      // format: "",
      platform: "",
      typeOfContract: "",
      segmentation: "",
      billingMonth: "",
      test: "",
      offerStatus: "ACTIVE",
      payoutUSD: "",
      revenueUSD: "",
      trafficSources: [],
    });

    setActiveStep(0);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    },
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fcfaff",
        position: "relative",
      }}
    >
      <MainTabs
        dataForm={dataForm}
        setDataForm={setDataForm}
        apiUrl={apiUrl}
        header={header}
        dataCaps={dataCaps}
        setDataCaps={setDataCaps}
        dataLanding={dataLanding}
        setDataLanding={setDataLanding}
        logo={logo}
        setLogo={setLogo}
        creatives={creatives}
        setCreatives={setCreatives}
        bat={bat}
        setBat={setBat}
        direct={direct}
        setDirect={setDirect}
        checkboxBat={checkboxBat}
        setCheckboxBat={setCheckboxBat}
        checkboxDirect={checkboxDirect}
        setCheckboxDirect={setCheckboxDirect}
        activeStep={activeStep}
        handleStep={handleStep}
        descriptionCreatives={descriptionCreatives}
        setDescriptionCreatives={setDescriptionCreatives}
        descriptionTargeting={descriptionTargeting}
        setDescriptionTargeting={setDescriptionTargeting}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div
        style={{
          position: "fixed",
          bottom: "0.5%",
          backgroundColor: "rgb(252, 250, 255)",
          width: "67%",
          padding: "8px 0",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{ color: "black", marginRight: "14px" }}
          variant="outlined"
          onClick={cancelButton}
        >
          Cancelar
        </Button>
        <ThemeProvider theme={theme}>
          <Button type="submit" variant="contained">
            Publicar Campaña
          </Button>
        </ThemeProvider>
      </div>
    </Box>
  );
};

export default CampaignCreator;
