import { Box, Button, useMediaQuery } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBackIos } from "@mui/icons-material";
import React, { useState } from "react";
import styles from "../../styles/Threads.module.css";
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import CustomFileUploader from "../../components/global/CustomFileUploader";
import { fileTypes } from "../../json/FileTypes";

const NewThread = ({
  modules,
  quillRef,
  handleFormSubmitThread,
  handleOnClickThread,
  handleUploadFile,
  selectedFile,
  newThreadIsClicked,
  valueWritten,
  setValueWritten,
  setSelectedFile,
  setFile,
  file,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await handleFormSubmitThread();
    setIsSubmitting(false);
  };

  const handleDelete = async (file) => {
    let files = selectedFile.filter(
      (e) => e.lastModified !== file.lastModified
    );
    setFile(files);
    setSelectedFile(files);
  };

  return (
    <div className={styles.createNewThreadContainer}>
      <Box
        className={styles.container}
        component="form"
        onSubmit={(e) => handleSubmit(e)}
      >
        <>
          <div className={styles.threadClick} onClick={handleOnClickThread}>
            {newThreadIsClicked ? (
              <ArrowBackIos className={styles.arrowBack} />
            ) : (
              <ArrowForwardIosIcon className={styles.arrowForward} />
            )}

            <h3 className={styles.createNewThread}>Crear nueva conversación</h3>
          </div>

          {newThreadIsClicked && (
            <div style={{ padding: "13px 0px" }}>
              {/* <TextField
                id="outlined-basic"
                label="Titulo"
                variant="outlined"
                className={styles.title}
                type="text"
                onChange={handleInputTitle}
                value={titleNewThread}
                style={{ width: "100%", marginBottom: "20px" }}
              /> */}
              <ReactQuill
                theme="snow"
                value={valueWritten}
                onChange={setValueWritten}
                placeholder="Contenido"
                modules={modules}
                ref={quillRef}
              />

              <div
                className={styles.displayButtons}
                style={{ flexDirection: isMobile ? "column" : "row" }}
              >
                <FileUploader
                  handleChange={handleUploadFile}
                  name="file"
                  types={fileTypes}
                  multiple={true}
                  label="Elegir archivos o arrastrar aqui"
                  hoverTitle="Arrastrar aqui"
                  children={<CustomFileUploader isMobile={isMobile} />}
                />

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  sx={{
                    backgroundColor: "var(--blueFour)",
                    color: "#fcfaff",
                    textTransform: "none",
                    padding: "4px 8px",
                    minWidth: "80px",
                    height: "35px",
                    fontSize: "16px",
                    "&: hover": {
                      backgroundColor: "var(--blue)",
                    },
                  }}
                >
                  {isSubmitting ? "Publicando" : "Publicar"}
                </Button>
              </div>
              {selectedFile &&
                selectedFile.map((file) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p key={file.name}>{file.name}</p>
                    <Button onClick={() => handleDelete(file)}>
                      <DeleteIcon style={{ width: "23px" }} />
                    </Button>
                  </div>
                ))}
            </div>
          )}
        </>
      </Box>
    </div>
  );
};

export default NewThread;
