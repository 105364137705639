import * as React from "react";
import {
  DataGridPro,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { fetchData } from "../../../../utils/ApiCall";
import { useEffect } from "react";
import CustomToolBar from "../../../../components/global/CustomToolBar";
import { useMemo } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import dayjs from "dayjs";

export const isNavigationKey = (key) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <span
            onClick={handleClick}
            tabIndex={-1}
            style={{ cursor: "pointer" }}
          >
            {props?.row?.hierarchy[0]} TOTAL
          </span>
        ) : rowNode?.depth === 0 ? (
          <span></span>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

const groupingColDef = {
  headerName: "Dia",
  width: 100,
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

const getRowClassName = (params) => {
  const row = params.row;

  if (row.offerTitle === "TOTAL") {
    return "total-row";
  }
  if (row?.hierarchy?.length === 1) {
    return "highlighted-row";
  }

  return "";
};

const generateUniqueId = () => {
  const min = 10;
  const max = 1000;
  return Math.floor(Math.random() * (max - min) + min);
};

const getTreeDataPath = (row) => {
  return row.hierarchy;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const names = [
  { value: "PAUSED" },
  { value: "PAUSED - CAP REACHED" },
  { value: "ACTIVE" },
  { value: "RUNNING" },
  { value: "INACTIVE" },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const getTodaysDay = () => {
  const date = new Date().toISOString();
  const first = "01";
  const firstOfMonth = date.slice(0, 8).concat(first);
  const today = date.slice(0, 10);

  return { firstOfMonth, today };
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function TreeDataCustomGroupingColumn() {
  const apiService = `${backendUrl}/api/offer-service`;
  const [offers, setOffers] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingRows, setIsLoadingRows] = useState(false);
  const [searchByOfferID, setSearchByOfferID] = useState(null);
  const [searchByStatus, setSearchByStatus] = useState(false);
  const [newArray, setNewArray] = useState([]);
  const [filterOn, setFilterOn] = useState(false);
  const [offerStatus, setOfferStatus] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const [enebleButton, setEnableButton] = useState(false);
  const [offerID, setOfferID] = useState("");

  const dates = getTodaysDay();

  const [dateFrom, setDateFrom] = useState(dates.firstOfMonth);
  const [dateTo, setDateTo] = useState(dates.today);

  const apiRef = useGridApiRef();

  //Funcion para buscar offers en el autocomplete
  const searchOffers = async (query, searchParam) => {
    const url = `${apiService}/AutocompleteOptions?${searchParam}=${query}`;
    const resOffers = await fetchData(url);
    setSearchResult(resOffers);
  };

  useEffect(() => {
    if (searchText && !offerID) {
      if (searchByOfferID) {
        searchOffers(searchText, "offerID");
      } else {
        searchOffers(searchText, "offerTitle");
      }
      setIsSearching(true);
    } else if ((offerStatus || !isTextFieldClicked) && !offerID) {
      // aca
      searchOffers(offerStatus?.value, "offerStatus");
      setIsSearching(true);
      setIsListOpen(true);
    } else {
      setSearchResult([]);
      setIsSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    searchByOfferID,
    searchByStatus,
    offerStatus,
    isTextFieldClicked,
  ]);
  //Funcion para seguir el value en el autocomplete
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setSearchByOfferID(!isNaN(e.target.value));
    setOfferStatus("");
    setIsTextFieldClicked(false);
  };

  //Funcion para cargar la offer seleccionada
  const handleLiClick = async (value) => {
    if (value) {
      setOfferID(value.offerID);
      setSearchText(value.offerTitle);
      setIsTextFieldClicked(false);
      setIsListOpen(false);
      setEnableButton(true);
    }
  };

  const handleSubmit = async () => {
    if (!isNaN(offerID)) {
      setEnableButton(false);
      setOffers([]);
      setIsLoadingRows(true);

      const formattedDateFrom = dateFrom
        ? dayjs(dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = dateTo ? dayjs(dateTo).format("YYYY-MM-DD") : "";

      const seguimientoUrl = `${apiService}/getSeguimientoCampanaDiario?offerID=${offerID}&dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const seguimientoData = await fetchData(seguimientoUrl);

      const modifiedOffers = seguimientoData.map((offer) => {
        if (offer.offerID) {
          return {
            ...offer,
            clicks: offer.totalClicks,
            pending: offer.totalPending,
            declined: offer.totalDecline,
            cr: offer.totalCr,
            revenue: offer.totalRevenue,
            payouts: offer.totalPayout,
            profit: offer.totalProfit,
            cumplimientoCampana: `${
              (offer.totalPending * 100) / offer.totalCap
            }%`,
          };
        } else {
          return offer;
        }
      });

      setIsLoadingRows(false);
      setOffers(modifiedOffers);
      setSearchResult([]);
      setSearchText("");
      setOfferStatus("");
      setIsSearching(true);
      setIsListOpen(false);

      const dates = getTodaysDay();

      setDateTo(dates.today);
      setDateFrom(dates.firstOfMonth);

      const affiliatesNames = modifiedOffers
        .map((off) => off?.hierarchy[1] || "")
        .filter((name) => name !== "");

      const affiliates = affiliatesNames.filter(
        (value, index, originalArray) => {
          return originalArray.indexOf(value) === index;
        }
      );
      setAffiliates(affiliates);

      setIsLoadingRows(false);
      setOffers(seguimientoData);
      setAffiliates([]);
      setOfferID("");
    }
  };

  //Funcion para saber que se esta filtrando
  const handleFilterModel = (filterModel) => {
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }

    let offers;
    switch (filterModel?.items[0]?.field) {
      case "manager":
        offers = updatedOffers.filter((man) =>
          man?.manager
            ?.toLowerCase()
            .includes(filterModel?.items[0]?.value?.toLowerCase())
        );
        setNewArray(offers);
        break;
      case "affiliate":
        offers = updatedOffers.filter((aff) =>
          aff?.hierarchy[1]
            ?.toLowerCase()
            .includes(filterModel?.items[0]?.value?.toLowerCase())
        );
        setNewArray(offers);
        break;
      default:
        break;
    }
  };

  //Funcion para calcular la suma de un field
  const calculateFieldSum = (fieldName) => {
    if (newArray.length > 0 && filterOn) {
      return newArray
        .filter((offer) => offer.hierarchy.length === 2)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    } else {
      return offers
        .filter((offer) => offer.hierarchy.length === 1)
        .reduce((sum, offer) => sum + (offer[fieldName] || 0), 0);
    }
  };

  //Funcion para redondear un decimal
  const roundDecimal = (numero) => {
    const numeroRedondeado = Math.round(numero * 10) / 10;
    return numeroRedondeado;
  };

  const totalPendingSum = calculateFieldSum("totalPending");
  const totalClicksSum = calculateFieldSum("totalClicks");
  const totalDeclinedSum = calculateFieldSum("totalDecline");
  const totalRevenueSum = `$${roundDecimal(calculateFieldSum("totalRevenue"))}`;
  const totalPayoutSum = `$${roundDecimal(calculateFieldSum("totalPayout"))}`;
  const totalProfitSum = `$${roundDecimal(calculateFieldSum("totalProfit"))}`;
  const totalCrSum = roundDecimal((totalPendingSum / totalClicksSum) * 100);

  const uniqueId = useMemo(() => generateUniqueId(), []);
  //Row con el total
  const totalRow = {
    offerTitle: "TOTAL",
    id: uniqueId,
    hierarchy: [uniqueId],
    manager: filterOn ? newArray[0]?.manager : "",
    affiliate: filterOn ? newArray[0]?.hierarchy[1] : "",
    pending: totalPendingSum,
    clicks: totalClicksSum,
    declined: totalDeclinedSum,
    revenue: totalRevenueSum,
    payouts: totalPayoutSum,
    profit: totalProfitSum,
    cr: isNaN(totalCrSum) ? 0 : totalCrSum,
  };

  const updatedOffers = [...offers, totalRow];

  const formatCumplimiento = (cumplimiento) => {
    if (isNaN(cumplimiento) || !isFinite(cumplimiento)) {
      return "0%";
    }
    return `${roundDecimal(cumplimiento)}%`;
  };

  const totalCap = updatedOffers[0]?.totalCap;

  const cumplimientoCampana = formatCumplimiento(
    (totalPendingSum * 100) / totalCap
  );

  const columns = [
    { field: "offerID", headerName: "Offer ID", width: 80 },
    { field: "offerTitle", headerName: "Offer", width: 120 },
    { field: "status", headerName: "Estado", width: 100 },
    {
      field: "affiliate",
      headerName: "Afiliado",
      width: 120,
      type: "singleSelect",
      valueOptions: affiliates,

      valueGetter: (params) => {
        const hierarchy = params.row.hierarchy;
        const rowNode = params.rowNode;
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalRow?.affiliate;
        }
        if (rowNode.depth === 0) {
          return "";
        }

        return hierarchy[hierarchy.length - 1];
      },
    },
    {
      field: "manager",
      headerName: "Manager Afiliado",
      width: 120,
      renderCell: (params) => {
        const manager = params?.row?.manager;
        return <p>{manager}</p>;
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalClicksSum;
        }
        if (row.hierarchy.length === 1 && filterOn) {
          const filterClicks = newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const clicksSum = filterClicks.reduce(
            (sum, offer) => sum + (offer.clicks || 0),
            0
          );
          return clicksSum;
        }

        if (row.hierarchy.length === 2) {
          return row.clicks;
        }
        return row.totalClicks;
      },
    },
    {
      field: "pending",
      headerName: "Pending",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalPendingSum;
        }
        if (row.hierarchy.length === 1 && filterOn) {
          const filterPending = newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const pendingSum = filterPending.reduce(
            (sum, offer) => sum + (offer.pending || 0),
            0
          );
          return pendingSum;
        }

        if (row.hierarchy.length === 2) {
          return row.pending;
        }
        return row.totalPending;
      },
    },
    {
      field: "declined",
      headerName: "Declined",
      width: 80,
      valueGetter: ({ row }) => {
        if (row.hierarchy.length === 1 && row.offerTitle === "TOTAL") {
          return totalDeclinedSum;
        }
        if (row.hierarchy.length === 1 && filterOn) {
          const filterDeclined = newArray.filter((offer) =>
            offer.hierarchy.includes(row.hierarchy[0])
          );
          const declinedSum = filterDeclined.reduce(
            (sum, offer) => sum + (offer.declined || 0),
            0
          );
          return declinedSum;
        }

        if (row.hierarchy.length === 2) {
          return row.declined;
        }
        return row.totalDecline;
      },
    },
    {
      field: "cr",
      headerName: "CR",
      width: 80,
      renderCell: (params) => {
        const cr = roundDecimal(params.row?.cr);
        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return <p>{cr ? `${roundDecimal(cr)}%` : "0%"}</p>;
        }
        if (params.row.hierarchy.length === 1 && filterOn) {
          const filteredCr = newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );

          const clicks = filteredCr[0]?.clicks;
          const pending = filteredCr[0]?.pending;
          const totalCr = (pending / clicks) * 100;

          return <p>{totalCr ? `${roundDecimal(totalCr)}%` : "0%"}</p>;
        }
        if (params.row.hierarchy.length === 2) {
          return <p>{roundDecimal(params.row.cr * 100)}%</p>;
        }

        const totalCr = params.row.totalCr;

        return (
          <p>
            {totalCr && params.row.hierarchy.length === 1
              ? `${roundDecimal(totalCr)}%`
              : totalCr === 0 && params.row.hierarchy.length === 1
              ? "0%"
              : ""}
          </p>
        );
      },
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      valueGetter: (params) => {
        const revenue = `$${roundDecimal(params?.row?.revenue)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalRevenueSum;
        }

        if (params.row.hierarchy.length === 1 && filterOn) {
          const filterRevenue = newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const revenueSum = filterRevenue.reduce(
            (sum, offer) => sum + (offer.revenue || 0),
            0
          );
          return `$${revenueSum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return revenue;
        }

        return `$${params.row.totalRevenue}`;
      },
    },
    {
      field: "payouts",
      headerName: "Payout",
      width: 80,
      valueGetter: (params) => {
        const payout = `$${roundDecimal(params?.row?.payouts)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalPayoutSum;
        }

        if (params.row.hierarchy.length === 1 && filterOn) {
          const filterPayout = newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const payoutssum = filterPayout.reduce(
            (sum, offer) => sum + (offer.payouts || 0),
            0
          );
          return `$${payoutssum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return payout;
        }

        return `$${params.row.totalPayout}`;
      },
    },
    {
      field: "profit",
      headerName: "Profit",
      width: 80,
      valueGetter: (params) => {
        const profit = `$${roundDecimal(params?.row?.profit)}`;

        if (
          params.row.hierarchy.length === 1 &&
          params.row.offerTitle === "TOTAL"
        ) {
          return totalProfitSum;
        }

        if (params.row.hierarchy.length === 1 && filterOn) {
          const filterProfit = newArray.filter((offer) =>
            offer.hierarchy.includes(params.row.hierarchy[0])
          );
          const profitSum = filterProfit.reduce(
            (sum, offer) => sum + (offer.profit || 0),
            0
          );
          return `$${profitSum}`;
        }

        if (params.row.hierarchy.length === 2) {
          return profit;
        }

        return `$${params.row.totalProfit}`;
      },
    },
    {
      field: "payoutAffiliate",
      headerName: "Payout Affiliate",
      width: 120,
      renderCell: (params) => {
        const payoutAffiliate = roundDecimal(params?.row?.payoutAffiliate);

        return <p>{params.row.payoutAffiliate ? `$${payoutAffiliate}` : ""}</p>;
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setOfferStatus(newValue);
    setSearchByStatus(true);
    setSearchText("");
  };

  return (
    <div style={{ height: "100%", width: "100%", backgroundColor: "#f8f7ff" }}>
      <Box sx={{ padding: "15px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <div style={{ marginRight: "15px" }}>
                <TextField
                  type="text"
                  label="Offer ID / Offer Title"
                  value={searchText}
                  disabled={isLoadingRows}
                  onChange={handleSearchTextChange}
                  onClick={() => setIsTextFieldClicked(true)}
                  sx={{
                    width: "350px",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                />

                {isSearching &&
                  searchResult.length === 0 &&
                  searchText !== "" && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        position: "absolute",
                        width: "350px",
                        zIndex: "1000",
                      }}
                    >
                      <ListItem className="autocomplete-li" disabled={true}>
                        <ListItemText primary="Offer no encontrada" />
                      </ListItem>
                    </List>
                  )}

                {isSearching && isListOpen && searchResult.length > 0 && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      width: "350px",
                      zIndex: "1000",
                      position: "absolute",
                    }}
                  >
                    {isSearching &&
                      isListOpen &&
                      searchResult
                        .filter((item) => item.offerStatus)
                        .map((result) => (
                          <ListItem
                            key={result.offerID}
                            onClick={() => handleLiClick(result)}
                            className="autocomplete-li"
                            disabled={isLoadingRows}
                            sx={{
                              backgroundColor:
                                result.offerStatus === "ACTIVE"
                                  ? "var(--greenYellow)"
                                  : result.offerStatus === "PAUSED"
                                  ? "var(--paused)"
                                  : result.offerStatus ===
                                    "PAUSED - CAP REACHED"
                                  ? "var(--greenDark)"
                                  : result.offerStatus === "RUNNING"
                                  ? "var(--violet)"
                                  : result.offerStatus === "INACTIVE"
                                  ? "rgb(226, 68, 92)"
                                  : "var(--gray)",
                              color: "white",
                              fontWeight: "700",
                              margin: "5px 0px",
                              "&:hover": {
                                backgroundColor: "var(--blackLow)",
                              },
                            }}
                          >
                            <ListItemText primary={result.offerTitle} />
                          </ListItem>
                        ))}
                  </List>
                )}
              </div>

              <Autocomplete
                id="checkboxes-tags-demo"
                options={names}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.value ?? ""}
                defaultValue=""
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                    />
                    {option.value ?? ""}
                  </li>
                )}
                value={offerStatus ?? null}
                onChange={handleChange}
                style={{ width: 350 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Offer Status"
                    sx={{ backgroundColor: "white" }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "12px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  localeText={{
                    start: `${dateFrom}`,
                    end: `${dateTo}`,
                  }}
                  sx={{ backgroundColor: "white", marginRight: "10px" }}
                  onChange={(newValue) => {
                    if (newValue[0]) setDateFrom(newValue[0]);
                    if (newValue[1]) setDateTo(newValue[1]);
                  }}
                  disableFuture
                />
              </LocalizationProvider>
              <ThemeProvider theme={theme}>
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={!enebleButton}
                >
                  Generar
                </Button>
              </ThemeProvider>
            </div>
          </div>

          <TableContainer
            sx={{ width: "350px", height: "107px", backgroundColor: "white" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "600" }}>CAP</TableCell>
                  <TableCell>100%</TableCell>
                  <TableCell>{updatedOffers[0]?.totalCap ?? 0}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{cumplimientoCampana}</TableCell>
                  <TableCell>{roundDecimal(totalPendingSum) ?? 0}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <DataGridPro
          slots={{
            toolbar: () => <CustomToolBar apiRef={apiRef} />,
            loadingOverlay: () => <LinearProgress />,
          }}
          loading={isLoadingRows ? true : false}
          treeData
          rows={updatedOffers}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          rowHeight={30}
          defaultGroupingExpansionDepth={-1}
          getRowClassName={getRowClassName}
          sx={{ backgroundColor: "white" }}
          getRowId={(row) =>
            row.hierarchy.length === 1 ? row.id : updatedOffers.indexOf(row)
          }
          onFilterModelChange={handleFilterModel}
          apiRef={apiRef}
        />
      </Box>
    </div>
  );
}
