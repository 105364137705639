import { React , useEffect } from "react";
import Header from "../../../../components/global/Header.jsx";
import container from "../../../../styles/Global.module.css";
import { useState } from "react";
import { alertStyles, criticityStyles } from "../../../../json/BoardStyles.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AlunaAlerts = () => {
  const [groups] = useState(["Alerts"]);
  const [status, setStatus] = useState("Alerts");
  const apiUrl = `${backendUrl}/api/activeAlertsAluna`;
  const [items, setItems] = useState([]);

  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "threads"],
    right: []
  });

  const [affiliatesManager, setAffiliatesManager] = useState([]);
  const usernamesSet = new Set();

  useEffect(() => {
    items.forEach((item) => {
      usernamesSet.add(item.affiliateManager);
    });
    const usernamesUnicos = [...usernamesSet];
    setAffiliatesManager(usernamesUnicos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const generateUniqueIds = (data) => {
    return data.map((item, index) => {
      return { ...item, id: `alert_${index}` };
    });
  };

  const filterItemsAndSetGroups = (items) => {
    const alertsWithIds = generateUniqueIds(items);

    setItems(alertsWithIds);
  };

  const getGroupRows = (groupName) => {
    return groupName === "Alerts" ? items : [];
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiliate.affiseID",
      headerName: "Affiliate ID",
      width: 100,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.affiliate?.affiseID,
    },
    {
      field: "affiliate.title",
      headerName: "Affiliate",
      width: 150,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.affiliate?.title,
    },    
    {
      field: "affiliateManager",
      headerName: "Affiliate Manager",
      width: 150,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: affiliatesManager,
      valueGetter: (params) => params.row.affiliateManager,
    },
    {
      field: "criticity",
      headerName: "Criticidad",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Alta", "Media", "Baja"],

      renderCell: (params) => {
        const criticityStyle = criticityStyles[params.row.criticity] || {};

        return (
          <div
            style={{
              width: "100%",
            }}
          >
            {params.row.criticity && (
              <p
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...criticityStyle,
                }}
              >
                {params.row.criticity}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: "affiseLinkStats",
      headerName: "URL stats",
      width: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "var(--blueFour)" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "affiseLink",
      headerName: "URL offer",
      width: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "var(--blueFour)" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "ips",
      headerName: "IPs",
      width: 140,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Tipo de Alerta",
      width: 200,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const alertStyle = alertStyles[params.row.type] || {};

        return (
          <div
            style={{
              width: "100%",
            }}
          >
            {params.row.type && (
              <p
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...alertStyle,
                }}
              >
                {params.row.type}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: "convertionMinute",
      headerName: "Minuto conv.",
      width: 140,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "result",
      headerName: "Result",
      width: 140,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={false}
      />
    </div>
  );
};

export default AlunaAlerts;
