import * as React from "react";
import { useMemo } from "react";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Edit } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import styles from "../../../styles/EditCampaign.module.css";
import globalStyles from "../../../styles/Global.module.css";
import { UserContext } from "../../../UserContext";
import { useContext } from "react";
import { editOfferAffise, fetchData, postFile } from "../../../utils/ApiCall";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Creatives from "../../CampaignCreator/tabs/Creatives";
import LandingPages from "../../CampaignCreator/tabs/LandingPages";
import Logo from "../../CampaignCreator/tabs/Logo";
import ReactQuill from "react-quill";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ display: value === index ? "block" : "none" }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditCampaign = () => {
  const apiUrl = `${backendUrl}/api/offer-service/`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));

  //API INFO
  const [activeOffers, setActiveOffers] = useState([]);
  const [stoppedOffers, setStoppedOffers] = useState([]);
  const [suspendedOffers, setSuspendedOffers] = useState([]);
  const [tab, setTab] = useState(0);

  //TAGS

  const [tagsChosen, setTagsChosen] = useState([]);

  //CATEGORIES
  const [categories, setCategories] = useState([]);
  const [categoriesChosen, setCategoriesChosen] = useState([]);

  //SOURCES
  const [sources, setSources] = useState([]);
  const [sourcesChosen, setSourcesChosen] = useState([]);

  //STATUS
  const [status, setStatus] = useState("");

  //ADVERTISERS
  const [advertisers, setAdvertisers] = useState([]);
  const [advertiserChosen, setAdvertiserChosen] = useState("");

  //DOMAINS

  const [domainsChosen, setDomainsChosen] = useState("");

  //COUNTRIES
  const [countries, setCountries] = useState([]);
  const [countriesTargetingChosen, setCountriesTargetingChosen] = useState([]);

  const [countriesPaymentChosen, setCountriesPaymentChosen] = useState([]);

  //PAYMENT TYPE
  const [paymentTypeChosen, setPaymentTypeChosen] = useState("");

  //CURRENCIES

  const [currenciesChosen, setCurrenciesChosen] = useState("USD");
  //DATE
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  //TITLE
  const [title, setTitle] = useState("");

  //DESCRIPTION
  const [description, setDescription] = useState("");

  const [targeting, setTargeting] = useState("");
  //URL
  const [trackingUrl, setTrackingUrl] = useState("");

  const [previewUrl, setPreviewUrl] = useState("");

  //REVENUE
  const [revenue, setRevenue] = useState("");

  //PAYOUTS
  const [payouts, setPayouts] = useState("");

  //GOAL VALUE GOAL TITLE
  const [goalValue, setGoalValue] = useState("");

  const [goalTitle, setGoalTitle] = useState("");

  //OFFER ID
  const [offerId, setOfferId] = useState("");

  //CAP

  //AFFILIATES
  const [affiliates, setAffiliates] = useState([]);
  //KALA
  const [typeOfClient, setTypeOfClient] = useState("");

  const [encargadaCsm, setEncargadaCsm] = useState("");

  const [monedaAnunciante, setMonedaAnunciante] = useState("");

  const [bat] = useState("");

  const [payoutAceptado, setPayoutAceptado] = useState("");
  const [estimadoCapMensual, setEstimadoCapMensual] = useState("");

  const [direct] = useState("");

  //PERSONAL PAYOUT
  const [personalPayout, setPersonalPayout] = useState([]);

  //LOGO
  const [logo, setLogo] = useState([]);

  //CREATIVES
  const [creatives, setCreatives] = useState([]);
  const [newCreatives, setNewCreatives] = useState([]);
  //MODAL
  const [currentRowData, setCurrentRowData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  //LANDING
  const [landing, setLanding] = useState([]);

  // NUEVOS
  const [privacy, setPrivacy] = useState("");
  const [revenueMonedaAnunciante, setRevenueMonedaAnunciante] = useState("");

  useEffect(() => {
    if (!key) return;
    const fetchApi = async () => {
      const resOffers = await fetchData(`${apiUrl}getActiveOffers`);
      setActiveOffers(resOffers);
      const resStopped = await fetchData(`${apiUrl}getStoppedOffers`);
      setStoppedOffers(resStopped);
      const resSuspended = await fetchData(`${apiUrl}getSuspendedOffers`);
      setSuspendedOffers(resSuspended);

      const resCategories = await fetchData(`${apiUrl}getCategories`);
      setCategories(resCategories);
      const resSources = await fetchData(`${apiUrl}getSources`);
      setSources(resSources);
      const resAdvertisers = await fetchData(
        `${backendUrl}/api/advertiser/getAllAdvertisers`
      );
      setAdvertisers(resAdvertisers);

      const resCountries = await fetchData(`${apiUrl}getCountries`);
      setCountries(resCountries);
      //   const resCurrencies = await fetchData(`${apiUrl}getCurrencies`);
      //   setCurrencies(resCurrencies);

      const resAffiliates = await fetchData(
        `${backendUrl}/api/affiliate/getAllAffiliates`
      );
      setAffiliates(resAffiliates);
    };
    const fetchDataAndUpdate = async () => {
      // call the function
      await fetchApi()
        // make sure to catch any error
        .catch(console.error);

      setTimeout(window.unlock, 100);
    };
    fetchDataAndUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  const handleEdit = async (rowData) => {
    setModalOpen(true);
    setCurrentRowData(rowData);
    setPersonalPayout(rowData?.row?.personalPayout);
    setTitle(rowData.row.title);
    setTagsChosen(rowData.row.tags);
    setCategoriesChosen(rowData.row.categories);
    setSourcesChosen(rowData.row.trafficSources);

    setStatus(rowData.row.status);

    setAdvertiserChosen(rowData.row.advertisers);

    setDomainsChosen(rowData.row.domains);
    setCountriesTargetingChosen(
      rowData?.row?.countriesTarget ? rowData?.row?.countriesTarget : []
    );

    setCountriesPaymentChosen(
      rowData?.row?.countriesPayout ? rowData?.row?.countriesPayout : []
    );

    setPaymentTypeChosen(rowData.row.paymentTypePayout);

    setCurrenciesChosen("USD");
    setDateFrom(rowData.row.dateFrom);
    setDateTo(rowData.row.dateTo);
    setDescription(rowData.row.description);
    setTargeting(rowData.row.targeting);
    setTrackingUrl(rowData.row.trackingUrl);

    setPreviewUrl(rowData.row.previewUrl);

    setRevenue(rowData.row.payoutsPayout);

    setPayouts(rowData.row.revenuePayout);

    setGoalValue(rowData.row.goalValuePayout);

    setGoalTitle(rowData.row.goalTitle);

    setOfferId(rowData.row.id);

    setPayoutAceptado(rowData.row.payoutAceptado);
    setEstimadoCapMensual(rowData.row.estimadoCapMensual);

    setPrivacy(rowData.row.privacy);
    setTypeOfClient(rowData.row.typeOfClient);
    setEncargadaCsm(rowData.row.encargadaCsm);
    setMonedaAnunciante(rowData.row.monedaAnunciante);
    setRevenueMonedaAnunciante(rowData.row.revenueMAnunciante);

    setCreatives(rowData.row.creatives);
    setLanding(rowData.row.landings);
    setLogo(rowData.row.logo);

    // const monday = await getItem(rowData.row.id, rowData.row.paymentTypePayout);
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
    setCurrentRowData(null);
  };

  const handleSetTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleSetTagsChosen = (e) => {
    setTagsChosen(e.target.value);
    // setCategoriesChosen(e.target.value);
  };

  const handleChange = (e) => {
    setSourcesChosen(e.target.value);
  };

  const handleSetStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleSetPrivacy = (e) => {
    setPrivacy(e.target.value);
  };

  const handleSetAdvertisersChosen = (e) => {
    setAdvertiserChosen(e.target.value);
  };

  const handleSetCountriesTargetingChosen = (e) => {
    setCountriesTargetingChosen(e.target.value);
  };

  const handleSetCountriesPaymentChosen = (e) => {
    setCountriesPaymentChosen(e.target.value);
  };

  const handleAddPayout = () => {
    setPersonalPayout([
      ...personalPayout,
      {
        countries: [],
        type: "",
        total: "",
        revenue: "",
        currency: "usd",
        value: "",
        title: "",
        partners: [],
      },
    ]);
  };

  const handleSetFieldInPersonalPayout = (e, index, fieldName) => {
    const updatedPersonalPayout = personalPayout.map((payout, i) => {
      if (i === index) {
        return {
          ...payout,
          [fieldName]: e.target.value,
        };
      }
      return payout;
    });
    setPersonalPayout(updatedPersonalPayout);
  };

  const handleSetPaymentType = (e) => {
    setPaymentTypeChosen(e.target.value);
  };

  const handleSetCurrencies = (e) => {
    setCurrenciesChosen(e.target.value);
  };

  const handleSetTrackingUrl = (e) => {
    setTrackingUrl(e.target.value);
  };

  const handleSetPreviewUrl = (e) => {
    setPreviewUrl(e.target.value);
  };

  const handleSetRevenue = (e) => {
    setRevenue(e.target.value);
  };

  const handleSetPayouts = (e) => {
    setPayouts(e.target.value);
  };

  const handleSetGoalValue = (e) => {
    setGoalValue(e.target.value);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Deseas editar la campaña?",
      showCancelButton: true,
      icon: "question",
      confirmButtonText: "Editar",
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: `Cancelar`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        const offerData = {
          personalPayout: personalPayout,
          offerTitle: title,
          advertiser: advertiserChosen,
          description: description,
          targeting: targeting,
          sources: sourcesChosen,
          status: status,
          tags: tagsChosen,
          dateStart: dateFrom,
          dateTo: dateTo,
          categories: tagsChosen,
          trackingDomain: domainsChosen,
          previewUrl: previewUrl,
          url: trackingUrl,
          targetingCountry: countriesTargetingChosen,
          paymentCountry: countriesPaymentChosen,
          paymentType: paymentTypeChosen,
          paymentPayouts: payouts,
          paymentRevenue: revenue,
          currency: currenciesChosen,
          paymentGoal: goalValue,
          paymentGoalTitle: goalTitle,
          typeClient: typeOfClient,
          encargadaCSM: encargadaCsm,
          monedaAnunciante: monedaAnunciante,
          revenueMonedaAnunciante: revenueMonedaAnunciante,
          BAT: bat,
          other: direct,
          id: offerId,
          payoutAceptado: payoutAceptado,
          estimadoCapMensual: estimadoCapMensual,
          privacy: privacy,
          landing: landing,
        };

        await editOfferAffise(offerData)
          .catch((error) => {
            Swal.fire(
              `Error: ${error.response.status}. Se canceló el proceso, verificar información`,
              "",
              "error"
            );
          })
          .then((response) => {
            postFile([logo], offerId, "postLogoAffise", "logo", 0).then(
              (res) => {
                postFile(
                  newCreatives,
                  offerId,
                  "postFileAffise",
                  "creatives",
                  creatives.length
                );
              }
            );
          })
          .catch((error) => {
            Swal.fire(
              `Error: ${error.response.status}, se canceló el proceso`,
              "",
              "error"
            );
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Se ha editado la campaña con éxito", "", "success").then(
          setModalOpen(false)
        );
      } else {
        Swal.fire("Se ha cancelado la edición de campaña", "", "info");
      }
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    height: "700px",
    overflow: "scroll",
    overflowX: "hidden",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    alignItems: "center",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Edit",
        field: "edit",
        width: 50,
        renderCell: (rowData) => (
          <Edit
            onClick={() => handleEdit(rowData)}
            style={{ cursor: "pointer", color: "black" }}
          />
        ),
        headerAlign: "center",
      },
      // {
      //   headerName: "Logo",
      //   field: "offerLogo",
      //   width: 50,
      //   headerAlign: "center",

      //   renderCell: (rowData) => (
      //     <PhotoIcon
      //       onClick={() => handleLogo(rowData)}
      //       style={{ cursor: "pointer", color: "black" }}
      //     />
      //   ),
      // },
      // {
      //   headerName: "Creatives",
      //   width: 80,
      //   field: "offerCreatives",
      //   headerAlign: "center",

      //   renderCell: (rowData) => (
      //     <DriveFolderUploadIcon
      //       onClick={() => handleCreatives(rowData)}
      //       style={{ cursor: "pointer", color: "black" }}
      //     />
      //   ),
      // },
      {
        field: "id",
        headerName: "Offer ID",
        width: 100,
        headerAlign: "center",
      },
      {
        field: "title",
        headerName: "Offer",
        width: 180,
        headerAlign: "center",
      },
      {
        field: "status",
        headerName: "Status",
        width: 70,
        headerAlign: "center",
      },
      {
        field: "description",
        headerName: "Description",
        width: 120,
        headerAlign: "center",
        renderCell: (params) => (
          <Tooltip
            title={params?.row?.description?.replace(/(<([^>]+)>)/gi, "")}
          >
            <div
              style={{
                width: "120px",
                height: "100%",
                display: "flex",
                padding: "0 10px",
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: params.row.description,
                }}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </div>
          </Tooltip>
        ),
      },

      {
        field: "advertisers",
        headerName: "Advertisers",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "trafficSources",
        headerName: "Traffic Sources",
        width: 120,
        headerAlign: "center",
      },
      { field: "tags", headerName: "Tags", width: 120, headerAlign: "center" },

      {
        field: "trackingUrl",
        headerName: "Tracking URL",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "previewUrl",
        headerName: "Preview URL",
        width: 160,
        headerAlign: "center",
      },
      {
        field: "domains",
        headerName: "Domains",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "countriesTarget",
        headerName: "Countries Target",
        width: 130,
        headerAlign: "center",
      },
      {
        field: "countriesPayout",
        headerName: "Countries Payout",
        width: 130,
        headerAlign: "center",
      },
      {
        field: "paymentTypePayout",
        headerName: "Payment Type",
        width: 120,
        headerAlign: "center",
      },

      {
        field: "payoutsPayout",
        headerName: "Revenue",
        width: 80,
        headerAlign: "center",
      },
      {
        field: "revenuePayout",
        headerName: "Payouts",
        width: 80,
        headerAlign: "center",
      },
      {
        field: "currenciesPayout",
        headerName: "Currencies",
        width: 100,
        headerAlign: "center",
      },
      {
        field: "goalValuePayout",
        headerName: "Goal Value",
        width: 90,
        headerAlign: "center",
      },
      {
        field: "goalTitle",
        headerName: "Goal Title",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "dateFrom",
        headerName: "Date From",
        width: 120,
        headerAlign: "center",
      },
      {
        field: "dateTo",
        headerName: "Date To",
        width: 120,
        headerAlign: "center",
      },
    ],
    []
  );

  const [valueTab, setValueTab] = React.useState(0);

  const handleTab = (event, newValue) => {
    event.preventDefault();
    setValueTab(newValue);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    setTimeout(window.unlock, 10);
  };

  const tabConfig = [
    {
      label: "Active",
      rows: activeOffers,
    },
    {
      label: "Paused",
      rows: suspendedOffers,
    },
    {
      label: "Disabled",
      rows: stoppedOffers,
    },
  ];
  const quillRef = useRef(null);

  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },
  }).current;

  return (
    <>
      <div className={styles.wrapper}>
        <Box className={globalStyles.tabContainer}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            sx={{ margin: "0 24px", borderBottom: 1, borderColor: "divider" }}
          >
            {tabConfig.map((config, index) => (
              <Tab label={config.label} {...a11yProps(index)} key={index} />
            ))}
          </Tabs>
        </Box>
        {activeOffers.length === 0 ? (
          <Box className={globalStyles.globalContainer}>
            <TabPanel>
              <DataGridPro
                editMode="row"
                rowHeight={40}
                headerHeight={40}
                rows={activeOffers}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={(row) => activeOffers.indexOf(row)}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                loading
                style={{ height: "700px" }}
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "1px solid #dee2ff",

                    zIndex: "1",
                  },
                  "& .no-padding": {
                    padding: 0,
                  },
                  "& .centered-header": {
                    textAlign: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    justifyContent: "center",
                  },

                  "& .MuiDataGrid-cell:not(:last-child)": {
                    borderBottom: "1px solid #dee2ff",
                    borderRight: "1px solid #dee2ff",
                  },
                  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
                    {
                      borderBottom: "1px solid #dee2ff",
                    },
                  "& .name-column--cell": {
                    color: "green",
                  },
                  "& .status-column--cell": {
                    padding: 0,
                    color: "black",
                  },
                  "& .MuiDataGrid-row": {
                    border: "none",
                  },
                  "& .Mui-selected": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#fefcfd",
                    color: "black",
                    borderBottom: "1px solid #dee2ff",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "500",
                    fontSize: "16px",
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },

                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "1px solid #dee2ff",
                    backgroundColor: "#240046",
                    color: "white",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    borderLeft: "1px solid #dee2ff",
                  },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                  "& .css-i4bv87-MuiSvgIcon-root": {
                    color: "black",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "#F6F8FF",
                    border: "none",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    color: "white",
                  },
                  "& .MuiInputBase-colorPrimary": {
                    color: "white",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: "white",
                  },
                  "& .MuiDataGrid-cell--editable": {
                    cursor: "pointer",
                  },

                  "& .icons-access": {
                    color: "black",
                  },
                  "& .css-17jaavt": {
                    margin: "0",
                  },
                  "& .MuiList-padding": {
                    width: "400px",
                  },
                }}
              />
            </TabPanel>
          </Box>
        ) : (
          <>
            <Box className={globalStyles.globalContainer}>
              {tabConfig.map((config, index) => (
                <TabPanel
                  value={tab}
                  index={index}
                  key={index}
                  sx={{ padding: "0" }}
                >
                  <DataGridPro
                    editMode="row"
                    rows={config.rows}
                    columns={columns}
                    rowHeight={40}
                    headerHeight={40}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row) => config.rows.indexOf(row)}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          debounceMs: 500,
                          placeholder: "Buscar...",
                        },
                      },
                    }}
                    style={{ height: "700px", backgroundColor: "#fefcfd" }}
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "1px solid #dee2ff",
                        zIndex: "1",
                      },
                      "& .no-padding": {
                        padding: 0,
                      },
                      "& .centered-header": {
                        textAlign: "center",
                      },
                      "& .MuiDataGrid-cell": {
                        justifyContent: "center",
                      },

                      "& .MuiDataGrid-cell:not(:last-child)": {
                        borderBottom: "1px solid #dee2ff",
                        borderRight: "1px solid #dee2ff",
                      },
                      "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
                        {
                          borderBottom: "1px solid #dee2ff",
                        },
                      "& .name-column--cell": {
                        color: "green",
                      },
                      "& .status-column--cell": {
                        padding: 0,
                        color: "black",
                      },
                      "& .MuiDataGrid-row": {
                        border: "none",
                      },
                      "& .Mui-selected": {
                        border: "none",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#fefcfd",
                        color: "black",
                        borderBottom: "1px solid #dee2ff",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "500",
                        fontSize: "16px",
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                      },

                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "1px solid #dee2ff",
                        backgroundColor: "#240046",
                        color: "white",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        borderLeft: "1px solid #dee2ff",
                      },
                      "& .MuiDataGrid-columnHeader:focus": {
                        outline: "none",
                      },
                      "& .css-i4bv87-MuiSvgIcon-root": {
                        color: "black",
                      },
                      "& .MuiDataGrid-row.Mui-selected": {
                        backgroundColor: "#F6F8FF",
                        border: "none",
                      },
                      "& .MuiTablePagination-selectLabel": {
                        color: "white",
                      },

                      "& .MuiTablePagination-displayedRows": {
                        color: "white",
                      },
                      "& .MuiDataGrid-cell--editable": {
                        cursor: "pointer",
                      },

                      "& .icons-access": {
                        color: "black",
                      },
                      "& .css-17jaavt": {
                        margin: "0",
                      },
                      "& .MuiList-padding": {
                        width: "400px",
                      },
                    }}
                  />
                </TabPanel>
              ))}
            </Box>

            <Modal open={modalOpen} onClose={handleClose}>
              <div>
                {
                  currentRowData && (
                    <Box
                      className={globalStyles.campaignCreatorContainer}
                      component="form"
                      onSubmit={handleSubmitEdit}
                      sx={style}
                    >
                      <div
                        className={styles.stepperContainer}
                        style={{
                          minWidth: "858px",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          position: "relative",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tabs
                              value={valueTab}
                              onChange={handleTab}
                              aria-label="basic tabs example"
                              sx={{ justifyContent: "center" }}
                            >
                              <Tab
                                label="General"
                                {...a11yProps(0)}
                                sx={{ width: "120px" }}
                              />
                              <Tab
                                label="Logo"
                                {...a11yProps(1)}
                                sx={{ width: "120px" }}
                              />
                              <Tab
                                label="Tracking"
                                {...a11yProps(2)}
                                sx={{ width: "120px" }}
                              />
                              <Tab
                                label="Targeting Groups"
                                {...a11yProps(3)}
                                sx={{ width: "160px" }}
                              />
                              <Tab
                                label="Affiliates"
                                {...a11yProps(4)}
                                sx={{ width: "120px", display: "none" }}
                              />
                              <Tab
                                label="Payouts"
                                {...a11yProps(5)}
                                sx={{ width: "120px" }}
                              />
                              <Tab
                                label="Creatives"
                                {...a11yProps(6)}
                                sx={{ width: "120px" }}
                              />
                              <Tab
                                label="Landing pages"
                                {...a11yProps(7)}
                                sx={{ width: "130px" }}
                              />
                              {/* <Tab
                                label="Kala"
                                {...a11yProps(8)}
                                sx={{ width: "120px" }}
                              /> */}
                            </Tabs>
                          </Box>

                          <TabPanel value={valueTab} index={0}>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <TextField
                                    label="Offer Title"
                                    value={title}
                                    className={styles.textfields}
                                    onChange={handleSetTitle}
                                    sx={{ margin: "10px", width: "250px" }}
                                  />
                                  <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Advertisers"
                                    value={advertiserChosen}
                                    onChange={handleSetAdvertisersChosen}
                                    className={styles.textfields}
                                    sx={{ margin: "10px", width: "250px" }}
                                  >
                                    {advertisers.map((el) => (
                                      <MenuItem value={el.name}>
                                        {el.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <TextField
                                    id="outlined-basic"
                                    select
                                    label="Status"
                                    value={status}
                                    className={styles.textfields}
                                    onChange={handleSetStatus}
                                    sx={{ margin: "10px", width: "250px" }}
                                  >
                                    <MenuItem value="stopped">
                                      Disabled
                                    </MenuItem>
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="suspended">
                                      Paused
                                    </MenuItem>
                                  </TextField>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "50px",
                                    padding: "0 25px",
                                  }}
                                >
                                  <ReactQuill
                                    theme="snow"
                                    value={description}
                                    onChange={setDescription}
                                    placeholder="Description"
                                    modules={modules}
                                    ref={quillRef}
                                    style={{ width: "100%", height: 300 }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormControl
                                    sx={{ margin: "10px", width: "250px" }}
                                  >
                                    <InputLabel id="demo-multiple-name-label">
                                      Traffic Sources
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      multiple
                                      value={sourcesChosen}
                                      onChange={handleChange}
                                      input={
                                        <OutlinedInput label="Traffic Sources" />
                                      }
                                      MenuProps={MenuProps}
                                      label="Traffic Sources"
                                    >
                                      {sources?.map((el) => (
                                        <MenuItem
                                          key={el.title}
                                          value={el.title}
                                        >
                                          {el.title}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  {/* <FormControl
                                    sx={{ margin: "10px", width: "250px" }}
                                  >
                                    <InputLabel id="demo-multiple-name-label">
                                      Tags
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      multiple
                                      value={tagsChosen}
                                      onChange={handleSetTagsChosen}
                                      input={<OutlinedInput label="Tags" />}
                                      MenuProps={MenuProps}
                                      label="Tags"
                                    >
                                      {categories.map((el) => (
                                        <MenuItem key={el.title} value={el}>
                                          {console.log(el, "EL")}
                                          {el.title}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl> */}

                                  <TextField
                                    id="outlined-basic"
                                    select
                                    label="Privacy"
                                    value={privacy}
                                    // className={styles.textfields}
                                    onChange={handleSetPrivacy}
                                    sx={{ margin: "10px", width: "250px" }}
                                  >
                                    <MenuItem value="public">Public</MenuItem>
                                    <MenuItem value="protected">
                                      Premoderation
                                    </MenuItem>
                                    <MenuItem value="private">Private</MenuItem>
                                  </TextField>

                                  {/* <FormControl
             sx={{ margin: "10px", width: "250px" }}
           >
             <InputLabel id="demo-multiple-name-label">
               Categories
             </InputLabel>
             <Select
               labelId="demo-multiple-name-label"
               id="demo-multiple-name"
               multiple
               value={categoriesChosen}
               onChange={handleSetTagsChosen}
               input={<OutlinedInput label="Tags" />}
               MenuProps={MenuProps}
               label="Tags"
             >
               {categories.map((el) => (
                 <MenuItem
                   key={el.title}
                   value={el.id}
                 >
                   {el.title}
                 </MenuItem>
               ))}
             </Select>
           </FormControl> */}
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value={valueTab} index={1}>
                            <Logo logo={logo} setLogo={setLogo} />
                          </TabPanel>
                          <TabPanel value={valueTab} index={2}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                label="Tracking Url"
                                value={trackingUrl}
                                onChange={handleSetTrackingUrl}
                                sx={{ margin: "10px", width: "250px" }}
                              />
                              <TextField
                                label="Preview Url"
                                value={previewUrl}
                                onChange={handleSetPreviewUrl}
                                sx={{ margin: "10px", width: "250px" }}
                              />
                            </div>
                          </TabPanel>
                          <TabPanel value={valueTab} index={3}>
                            <FormControl
                              sx={{
                                margin: "10px",
                                width: "386px",
                              }}
                            >
                              <InputLabel id="demo-multiple-name-label">
                                Countries Targeting
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={countriesTargetingChosen}
                                onChange={handleSetCountriesTargetingChosen}
                                input={<OutlinedInput label="Countries" />}
                                MenuProps={MenuProps}
                                label="Countries Targeting"
                              >
                                {countries.map((el) => (
                                  <MenuItem key={el.code} value={el.code}>
                                    {el.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TabPanel>
                          <TabPanel value={valueTab} index={4}>
                            AFILIADOS
                          </TabPanel>
                          <TabPanel value={valueTab} index={5}>
                            General
                            <div
                              style={{
                                paddingBottom: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {" "}
                              {/* ACA HAY QUE PONER LA LiNEA DIVISORA */}
                              <FormControl sx={{ m: 1, width: 386 }}>
                                <InputLabel id="demo-multiple-name-label">
                                  Countries Payout
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  value={countriesPaymentChosen}
                                  onChange={handleSetCountriesPaymentChosen}
                                  input={<OutlinedInput label="Countries" />}
                                  MenuProps={MenuProps}
                                  label="Countries"
                                >
                                  {countries.map((el) => (
                                    <MenuItem key={el.code} value={el.code}>
                                      {el.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div style={{ display: "flex" }}>
                                <TextField
                                  id="outlined-select-currency"
                                  select
                                  label="Payment Type"
                                  value={paymentTypeChosen}
                                  onChange={handleSetPaymentType}
                                  sx={{ margin: "10px", width: "250px" }}
                                >
                                  <MenuItem value="fixed">CPA (fixed)</MenuItem>
                                  <MenuItem value="percent">
                                    CPS (percent)
                                  </MenuItem>
                                  <MenuItem value="mixed">
                                    RPS/CPA (mixed)
                                  </MenuItem>
                                </TextField>

                                <TextField
                                  label="Revenue"
                                  value={revenue}
                                  onChange={handleSetRevenue}
                                  type="number"
                                  sx={{ margin: "10px", width: "250px" }}
                                />

                                <TextField
                                  label="Payouts"
                                  value={payouts}
                                  onChange={handleSetPayouts}
                                  sx={{ margin: "10px", width: "250px" }}
                                  type="number"
                                />
                              </div>
                              <div style={{ display: "flex" }}>
                                <TextField
                                  id="outlined-select-currency"
                                  select
                                  label="Currencies"
                                  value={currenciesChosen}
                                  onChange={handleSetCurrencies}
                                  sx={{ margin: "10px", width: "250px" }}
                                >
                                  <MenuItem value="USD">USD</MenuItem>
                                </TextField>
                                <TextField
                                  label="Goal Value"
                                  value={goalValue}
                                  sx={{ margin: "10px", width: "250px" }}
                                  onChange={handleSetGoalValue}
                                  type="number"
                                />
                              </div>
                            </div>
                            <Divider sx={{ marginBottom: "24px" }} />
                            <div>
                              Personal payouts
                              {personalPayout?.map((payout, index) => (
                                <div
                                  key={index}
                                  style={{
                                    backgroundColor: "var(--blueLow)",
                                    margin: "20px 0",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <FormControl
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                    >
                                      <InputLabel id={`country-label-${index}`}>
                                        Country
                                      </InputLabel>
                                      <Select
                                        labelId={`country-label-${index}`}
                                        id={`country-select-${index}`}
                                        multiple
                                        value={personalPayout[index].countries}
                                        onChange={(e) =>
                                          handleSetFieldInPersonalPayout(
                                            e,
                                            index,
                                            "countries"
                                          )
                                        }
                                        input={
                                          <OutlinedInput label="Countries" />
                                        }
                                        MenuProps={MenuProps}
                                        label="Countries"
                                      >
                                        {countries.map((el) => (
                                          <MenuItem
                                            key={el.code}
                                            value={el.code}
                                          >
                                            {el.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <FormControl
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                    >
                                      <Select
                                        id={`afiliates-select-${index}`}
                                        multiple
                                        value={personalPayout[index].partners}
                                        onChange={(e) =>
                                          handleSetFieldInPersonalPayout(
                                            e,
                                            index,
                                            "partners"
                                          )
                                        }
                                        input={
                                          <OutlinedInput label="Afiliates" />
                                        }
                                        MenuProps={MenuProps}
                                        label="Afiliates"
                                      >
                                        {affiliates.map((el) => (
                                          <MenuItem
                                            key={el.affiseID}
                                            value={el.affiseID}
                                          >
                                            {el.title}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <TextField
                                      id={`payment-type-field-${index}`}
                                      select
                                      label="Payment Type"
                                      value={personalPayout[index].type}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "type"
                                        )
                                      }
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                    >
                                      <MenuItem value="fixed">
                                        CPA (fixed)
                                      </MenuItem>
                                      <MenuItem value="percent">
                                        CPS (percent)
                                      </MenuItem>
                                      <MenuItem value="mixed">
                                        RPS/CPA (mixed)
                                      </MenuItem>
                                    </TextField>
                                  </div>

                                  <div>
                                    <TextField
                                      label="Revenue"
                                      value={personalPayout[index].total}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "total"
                                        )
                                      }
                                      type="number"
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                    />
                                    <TextField
                                      label="Payouts"
                                      value={personalPayout[index].revenue}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "revenue"
                                        )
                                      }
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                      type="number"
                                    />

                                    <TextField
                                      id="outlined-select-currency"
                                      select
                                      label="Currency"
                                      value={personalPayout[index].currency}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "currency"
                                        )
                                      }
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                    >
                                      <MenuItem value="usd">USD</MenuItem>
                                    </TextField>
                                  </div>

                                  <div>
                                    <TextField
                                      label="Goal Value"
                                      value={personalPayout[index].goal}
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "goal"
                                        )
                                      }
                                      type="number"
                                    />
                                    <TextField
                                      label="Goal Title"
                                      value={personalPayout[index].title}
                                      sx={{
                                        margin: "10px",
                                        width: "250px",
                                      }}
                                      onChange={(e) =>
                                        handleSetFieldInPersonalPayout(
                                          e,
                                          index,
                                          "title"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                              <Button onClick={handleAddPayout}>Agregar</Button>
                            </div>
                          </TabPanel>
                          <TabPanel value={valueTab} index={6}>
                            <Creatives
                              oldCreatives={creatives}
                              setCreatives={setCreatives}
                              id={offerId}
                              newCreatives={newCreatives}
                              setNewCreatives={setNewCreatives}
                            />
                          </TabPanel>
                          <TabPanel value={valueTab} index={7}>
                            <LandingPages
                              dataLanding={landing}
                              setDataLanding={setLanding}
                            />
                          </TabPanel>
                          <TabPanel value={valueTab} index={8}>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                {/* <FormControl
                                  sx={{ margin: "10px", width: "386px" }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Type of client
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeOfClient}
                                    label="Type of client"
                                    onChange={handleTypeOfClient}
                                  >
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                    <MenuItem value="B+">B+</MenuItem>
                                  </Select>
                                </FormControl> */}

                                {/* <FormControl
                                  sx={{ margin: "10px", width: "386px" }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Encargada CSM
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={encargadaCsm}
                                    label="Encargada CSM"
                                    onChange={handleEncargadaCsm}
                                  >
                                    <MenuItem value="Maria Camila Arango">
                                      Maria Camila Arango
                                    </MenuItem>
                                    <MenuItem value="Felicitas Casado">
                                      Felicitas Casado
                                    </MenuItem>
                                    <MenuItem value="Agustina Iemmi">
                                      Agustina Iemmi
                                    </MenuItem>
                                  </Select>
                                </FormControl> */}
                              </div>

                              <div style={{ display: "flex" }}>
                                {/* <TextField
                                  id="outlined-select-currency"
                                  select
                                  label="Moneda Anunciante"
                                  value={monedaAnunciante}
                                  onChange={handleSetMonedaAnunciante}
                                  sx={{ margin: "10px", width: "386px" }}
                                >
                                  {monedas.map((el) => (
                                    <MenuItem value={el.currency}>
                                      {el.currency}
                                    </MenuItem>
                                  ))}
                                </TextField> */}

                                {/* <TextField
                                  label="Revenue Moneda Anunciante"
                                  value={revenueMonedaAnunciante}
                                  sx={{ margin: "10px", width: "386px" }}
                                  onChange={handleSetRevenueMonedaAnunciante}
                                  type="number"
                                /> */}
                              </div>

                              <div style={{ display: "flex" }}>
                                {/* <TextField
                                  label="Payout Aceptado"
                                  value={payoutAceptado}
                                  onChange={handleSetPayoutAceptado}
                                  type="number"
                                  fullWidth
                                  variant="outlined"
                                  sx={{ margin: "10px", width: "386px" }}
                                /> */}

                                {/* <TextField
                                  label="Cap estimado"
                                  value={estimadoCapMensual}
                                  onChange={handleSetCapEstimado}
                                  type="number"
                                  fullWidth
                                  variant="outlined"
                                  sx={{ margin: "10px", width: "386px" }}
                                /> */}
                              </div>

                              {/* <div>
                                <TextField
                                  label="Targeting"
                                  value={targeting}
                                  onChange={handleSetTargeting}
                                  multiline
                                  maxRows={10}
                                  fullWidth
                                  variant="outlined"
                                  sx={{ margin: "10px" }}
                                />
                              </div> */}
                              <div>
                                {/* <Box>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox sx={{ marginLeft: "10px" }} />
                                      }
                                      label="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                                      value="BAT/PREVIEW REQUIRED BEFORE RUNNING"
                                      checked={checkboxBat}
                                      onChange={handleSetBat}
                                    />
                                  </FormGroup>
                                </Box> */}

                                {/* <Box>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox sx={{ marginLeft: "10px" }} />
                                      }
                                      label="Direct"
                                      value="Direct"
                                      checked={checkboxDirect}
                                      onChange={handleSetDirect}
                                    />
                                  </FormGroup>
                                </Box> */}
                              </div>
                            </div>
                          </TabPanel>
                        </Box>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "10px",
                          }}
                        >
                          <Button type="submit">Guardar</Button>
                        </div>
                      </div>
                    </Box>
                  )
                  // <Box sx={style} component="form" onSubmit={handleSubmitEdit}>

                  //   <div style={{ display: "flex", alignItems: "center" }}>
                  //

                  //   </div>

                  //   {/* <div className={styles.flex}>
                  //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //       <Stack className={styles.dobleTextfields}>
                  //         <DatePicker
                  //           // minDate={today}
                  //           openTo="year"
                  //           views={["year", "month", "day"]}
                  //           label="Date From"
                  //           value={newDateFrom}
                  //           onChange={(newValue) => handleSetDateFrom(newValue)}
                  //           renderInput={(params) => (
                  //             <TextField
                  //               {...params}
                  //               error={errorDateFrom}
                  //               helperText={errorDateFrom ? errorMessage : ""}
                  //             />
                  //           )}
                  //         />
                  //       </Stack>
                  //     </LocalizationProvider>

                  //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //       <Stack className={styles.dobleTextfields}>
                  //         <DatePicker
                  //           minDate={today}
                  //           openTo="year"
                  //           views={["year", "month", "day"]}
                  //           label="Date To"
                  //           value={newDateTo}
                  //           onChange={(newValue) => handleSetDateTo(newValue)}
                  //           renderInput={(params) => (
                  //             <TextField
                  //               {...params}
                  //               error={errorDateTo}
                  //               helperText={errorDateTo ? errorMessage : ""}
                  //             />
                  //           )}
                  //         />
                  //       </Stack>
                  //     </LocalizationProvider>
                  //   </div> */}

                  //   <div style={{ display: "flex" }}>

                  //     <div className={styles.flex}>
                  //
                  //     </div>

                  //
                  //   </div>
                  //   <Button type="submit">{editButton}</Button>
                  //   <Button onClick={handleClose}>Cancelar</Button>
                  // </Box>
                }
              </div>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default EditCampaign;
