import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  TextField,
  Autocomplete,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { UserContext } from "../../../../UserContext";
import { fetchData, getProgressStatusQueue } from "../../../../utils/ApiCall";
import container from "../../../../styles/Global.module.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CreacionDeConversiones = () => {
  const apiUrl = `${backendUrl}/api/offer-service/`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));

  const [formData, setFormData] = useState({
    date: null,
    offer: null,
    affiliate: null,
    status: "",
    quantity: "",
    revenue: null,
    payout: null,
  });
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTextAffiliates, setSearchTextAffiliates] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingAffiliates, setIsSearchingAffiliates] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [isListOpenAffiliates, setIsListOpenAffiliates] = useState(false);
  // const [isLoadingRows, setIsLoadingRows] = useState(false);
  // const [isLoadingRowsAffiliates, setIsLoadingRowsAffiliates] = useState(false);
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);
  const [isTextFieldClickedAffiliates, setIsTextFieldClickedAffiliates] =
    useState(false);
  const [searchByOfferID, setSearchByOfferID] = useState(false);
  const [searchByAffiliateID, setSearchByAffiliateID] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");
  const [offerID, setOfferID] = useState("");
  const [editPayout, setEditPayout] = useState(false);
  const [editRevenue, setEditRevenue] = useState(false);

  const statuses = ["PENDING", "CONFIRMED", "DECLINED"];

  useEffect(() => {
    if (searchText && !offerID) {
      handleSearchOffers();
    } else {
      setSearchResult([]);
      setIsSearching(false);
      setIsListOpen(false);
    }
  }, [searchText, searchByOfferID, isTextFieldClicked, isSearching]);

  useEffect(() => {
    if (searchTextAffiliates && !affiliateID) {
      handleSearchAffiliates();
    } else {
      setAffiliates([]);
      setIsSearchingAffiliates(false);
      setIsListOpen(false);
    }
  }, [
    searchTextAffiliates,
    searchByAffiliateID,
    isTextFieldClickedAffiliates,
    isSearchingAffiliates,
  ]);

  useEffect(() => {
    const getProgress = async () => {
      try {
        const progressQueue = await getProgressStatusQueue(
          `getStatusAddRevPay?id=${jobId}`
        );
        if (progressQueue.state == "completed" && jobId) {
          setLoading(false);
          setRequestCompleted(true);
          setJobId(null);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    if (loading && jobId) {
      const interval = setInterval(getProgress, 500);

      return () => clearInterval(interval);
    }
  }, [jobId]);

  useEffect(() => {
    if (requestCompleted) {
      resetForm();
      Swal.fire(
        "Success",
        "Data submitted successfully. Changes may take a few seconds to be reflected in Affise.",
        "success"
      );
      setRequestCompleted(false);
    }
  }, [requestCompleted]);

  const handleSearchOffers = async () => {
    if (searchByOfferID) {
      searchOffers(searchText, "offerID");
    } else {
      searchOffers(searchText, "offerTitle");
    }
    setIsSearching(true);
    setIsListOpen(true);
  };

  const handleSearchAffiliates = async () => {
    if (searchByAffiliateID) {
      searchAffiliates(searchTextAffiliates, "affiseID");
    } else {
      searchAffiliates(searchTextAffiliates, "title");
    }
    setIsSearchingAffiliates(true);
    setIsListOpenAffiliates(true);
  };

  const searchOffers = async (query, searchParam) => {
    const url = `${backendUrl}/api/offer-service/autocompleteOffers?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    setSearchResult(resOffers);
  };

  const searchAffiliates = async (query, searchParam) => {
    const url = `${apiUrl}autocompleteAffiliates?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    setAffiliates(resOffers);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      date: newDate,
    }));
  };

  const handleLiClick = (result) => {
    setFormData((prevData) => ({
      ...prevData,
      offer: result,
    }));
    setSearchText(result.offerTitle);
    setIsTextFieldClicked(false);
    setOfferID(result.offerID);
    setIsListOpen(false);
  };

  const handleLiClickAffiliates = (result) => {
    setFormData((prevData) => ({
      ...prevData,
      affiliate: result,
    }));
    setSearchTextAffiliates(result.title);
    setIsTextFieldClickedAffiliates(false);
    setAffiliateID(result.affiseID);
    setIsListOpenAffiliates(false);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setSearchByOfferID(!isNaN(e.target.value));
    setIsTextFieldClicked(false);
    setOfferID("");
  };

  const handleSearchTextChangeAffiliates = (e) => {
    setSearchTextAffiliates(e.target.value);
    setSearchByAffiliateID(!isNaN(e.target.value));
    setIsTextFieldClickedAffiliates(false);
    setAffiliateID("");
  };

  const resetForm = () => {
    setFormData({
      date: null,
      offer: null,
      affiliate: null,
      status: "",
      quantity: "",
      revenue: "",
      payout: "",
    });
    setSearchText("");
    setSearchTextAffiliates("");
    setEditPayout(false);
    setEditRevenue(false);
  };

  const handleEditPayout = () => {
    if (editPayout) {
      setFormData({
        ...formData,
        payout: "",
      });
    }
    setEditPayout(!editPayout);
  };
  const handleEditRevenue = () => {
    if (editRevenue) {
      setFormData({
        ...formData,
        revenue: "",
      });
    }
    setEditRevenue(!editRevenue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      date: formData.date ? dayjs(formData.date).format("YYYY-MM-DD") : null,
      offer: formData.offer ? formData.offer.offerID : null,
      affiliate: formData.affiliate ? formData.affiliate.affiseID : null,
      status: formData.status.toLowerCase(),
      quantity: formData.quantity,
      revenue: formData.revenue,
      payout: formData.payout,
    };

    try {
      const response = await fetch(
        `${backendUrl}/api/offer-service/createConversions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseJSON = await response.json();

        if (!responseJSON.jobId) {
          Swal.fire(
            "Success",
            "Data submitted successfully. Changes may take a few seconds to be reflected in Affise.",
            "success"
          );
          setLoading(false);
        } else {
          setJobId(responseJSON.jobId);
        }
        resetForm();
      } else {
        Swal.fire("Error", "Failed to submit data", "error");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Error", "Failed to submit data", "error");
    }
  };

  const offerRef = useRef(null);
  const affiliateRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        offerRef.current &&
        !offerRef.current.contains(event.target) &&
        isListOpen
      ) {
        setIsListOpen(false);
      }
      if (
        affiliateRef.current &&
        !affiliateRef.current.contains(event.target) &&
        isListOpenAffiliates
      ) {
        setIsListOpenAffiliates(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isListOpen, isListOpenAffiliates]);

  return (
    <Box className={container.globalContainer}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: "600px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha"
              value={formData.date}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} required />}
              sx={{ width: "100%", backgroundColor: "white" }}
            />
          </LocalizationProvider>

          <div style={{ display: "flex" }}>
            <div>
              <TextField
                type="text"
                label="Offer ID / Offer Title"
                value={searchText}
                // disabled={isLoadingRows}
                onChange={handleSearchTextChange}
                onClick={() => setIsTextFieldClicked(true)}
                sx={{
                  width: "350px",
                  backgroundColor: "white",
                  position: "relative",
                }}
              />

              {isSearching &&
                searchResult.length === 0 &&
                searchText !== "" && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      position: "absolute",
                      width: "350px",
                      zIndex: "1000",
                    }}
                  >
                    <ListItem className="autocomplete-li" disabled={true}>
                      <ListItemText primary="Offer no encontrada" />
                    </ListItem>
                  </List>
                )}

              {isSearching && isListOpen && searchResult.length > 0 && (
                <List
                  component="ul"
                  className="autocomplete-ul"
                  sx={{
                    width: "350px",
                    zIndex: "1000",
                    position: "absolute",
                  }}
                  ref={offerRef}
                >
                  {isSearching &&
                    isListOpen &&
                    searchResult
                      .filter(
                        (result) =>
                          result?.offerTitle
                            ?.toLowerCase()
                            ?.includes(searchText?.toLowerCase()) ||
                          result?.offerID?.toString() === searchText
                      )
                      .map((result) => (
                        <ListItem
                          key={result.offerID}
                          onClick={() => handleLiClick(result)}
                          className="autocomplete-li"
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            margin: "5px 0px",
                            "&:hover": {
                              backgroundColor: "var(--blueLowTwo)",
                            },
                          }}
                        >
                          <ListItemText primary={result.offerTitle} />
                        </ListItem>
                      ))}
                </List>
              )}
            </div>
          </div>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <div style={{ marginRight: "15px" }}>
            <TextField
              type="text"
              label="Affiliate ID / Affiliate Title"
              value={searchTextAffiliates}
              // disabled={isLoadingRowsAffiliates}
              onChange={handleSearchTextChangeAffiliates}
              onClick={() => setIsTextFieldClickedAffiliates(true)}
              sx={{
                width: "350px",
                backgroundColor: "white",
                position: "relative",
              }}
            />

            {isSearchingAffiliates &&
              affiliates.length === 0 &&
              searchTextAffiliates !== "" && (
                <List
                  component="ul"
                  className="autocomplete-ul"
                  sx={{
                    position: "absolute",
                    width: "350px",
                    zIndex: "1000",
                  }}
                >
                  <ListItem className="autocomplete-li" disabled={true}>
                    <ListItemText primary="Offer no encontrada" />
                  </ListItem>
                </List>
              )}

            {isSearchingAffiliates &&
              isListOpenAffiliates &&
              affiliates.length > 0 && (
                <List
                  component="ul"
                  className="autocomplete-ul"
                  sx={{
                    width: "350px",
                    zIndex: "1000",
                    position: "absolute",
                  }}
                  ref={affiliateRef}
                >
                  {isSearchingAffiliates &&
                    isListOpenAffiliates &&
                    affiliates
                      .filter(
                        (result) =>
                          result?.title
                            ?.toLowerCase()
                            ?.includes(searchTextAffiliates?.toLowerCase()) ||
                          result?.affiseID?.toString() === searchTextAffiliates
                      )
                      .map((result) => (
                        <ListItem
                          key={result.affiseID}
                          onClick={() => handleLiClickAffiliates(result)}
                          className="autocomplete-li"
                          // disabled={isLoadingRowsAffiliates}
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            margin: "5px 0px",
                            "&:hover": {
                              backgroundColor: "var(--blueLowTwo)",
                            },
                          }}
                        >
                          <ListItemText primary={result.title} />
                        </ListItem>
                      ))}
                </List>
              )}
          </div>
          <Autocomplete
            options={statuses}
            onChange={(event, newValue) =>
              handleAutocompleteChange("status", newValue)
            }
            value={formData.status}
            renderInput={(params) => (
              <TextField {...params} label="Status" required />
            )}
            sx={{ width: "100%", backgroundColor: "white" }}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label="Cantidad"
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
            sx={{ width: "100%", backgroundColor: "white" }}
          />
          <div style={{ display: "flex", width: "100%" }}>
            <TextField
              label="Revenue"
              type="number"
              name="revenue"
              placeholder="Revenue"
              value={formData.revenue}
              disabled={!editRevenue}
              onChange={handleChange}
              required
              sx={{ width: "100%", backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Agregar payout">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={handleEditRevenue}
                            checked={editRevenue}
                          />
                        }
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <TextField
              label="Payout"
              type="number"
              name="payout"
              placeholder="Payout"
              value={formData.payout}
              disabled={!editPayout}
              onChange={handleChange}
              required
              sx={{ width: "100%", backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Agregar payout">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={handleEditPayout}
                            checked={editPayout}
                          />
                        }
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreacionDeConversiones;
