import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { capTimeframe, capType } from "../../../json/CampaignCreator";
import { fetchData } from "../../../utils/ApiCall";
import styles from "../../../styles/CampaignCreator.module.css";
import { v4 as uuidv4 } from "uuid";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const Caps = ({ apiUrl, header, dataCaps, setDataCaps }) => {
  const [affiliates, setAffiliates] = useState([]);

  const [period, setPeriod] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState([{ title: "All" }]);
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedCapEdit, setSelectedCapEdit] = useState(null);

  const [caps, setCaps] = useState({
    period: "",
    type: "",
    value: "",
    id: "",
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resAffiliates = await fetchData(
        `${apiUrl}affiliate/getAllAffiliates`
      );
      const updatedAffiliates = resAffiliates.map((affiliate) => {
        return {
          ...affiliate,
          disabled: affiliate.title !== "All",
        };
      });
      setAffiliates(updatedAffiliates);
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCloseModal = () => {
    setModal(!modal);

    setSelected([{ title: "All" }]);
    setCaps({ period: "", type: "", value: "", id: "" });
  };

  const openCloseModalAdd = () => {
    setModal(!modal);
    setPeriod("");
    setType("");
    setValue("");
  };

  const openCloseModalEdit = (cap) => {
    setModalEdit(!modalEdit);
    setSelectedCapEdit(cap);
    setCaps({
      period: cap?.period || "",
      type: cap?.type || "",
      value: cap?.value || "",
      id: cap?.id || "",
      affiliates: cap?.affiliates || [],
      index: dataCaps?.findIndex((el) => el?.id === cap?.id),
    });
  };

  const openCloseModalDelete = () => {
    setModalDelete(!modalDelete);
  };

  const selectCap = (cap, condition) => {
    setCaps(cap);
    condition === "Edit" ? openCloseModalEdit(cap) : openCloseModalDelete();
  };

  const handleAutocompleteChange = (name, newValue) => {
    setCaps((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue : "",
      id: uuidv4(),
    }));
    setPeriod(newValue);
    setType(newValue);
  };

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setCaps((prevState) => ({ ...prevState, [name]: value, id: uuidv4() }));
    setValue(value);
  };

  const onOptionsChange = (e, valueArr) => {
    const allSelection = valueArr.find((item) => item.title === "All");

    const updatedOptions = affiliates.map((opt) => {
      if (allSelection) {
        if (opt.title === "All") {
          return { ...opt, disabled: false };
        } else {
          return { ...opt, disabled: true };
        }
      } else {
        return { ...opt, disabled: false };
      }
    });

    setAffiliates(updatedOptions);
    setSelected(valueArr);
  };

  const exterminateCap = () => {
    const removeData = dataCaps.filter((el) => el.id !== caps.id);
    setDataCaps(removeData);
    openCloseModalDelete();
    setSelected([]);
    setCaps({ period: "", type: "", value: "", id: "" });
  };

  const postCap = () => {
    if (caps.index !== undefined) {
      const updatedCaps = [...dataCaps];
      const updatedCap = {
        period: caps.period,
        type: caps.type,
        value: caps.value,
        id: caps.id,
        affiliates: selected.map((el) => el.title),
      };
      updatedCaps[caps.index] = updatedCap;
      setDataCaps(updatedCaps);
    } else {
      const newCap = {
        period: caps.period,
        type: caps.type,
        value: caps.value,
        id: caps.id,
        affiliates: selected.map((el) => el.title),
      };
      setDataCaps((prevDataCaps) => [...prevDataCaps, newCap]);
    }

    openCloseModal();
    setSelected([]);
    setValue("");
    setPeriod("");
    setType("");

    const resetOptions = affiliates.map((opt) => {
      return { ...opt, disabled: false };
    });
    setAffiliates(resetOptions);
  };

  const addCap = (
    <div className={styles.wrapperCap}>
      <h3 style={{ fontWeight: "600" }}>Nuevo Cap</h3>
      <br />
      <Autocomplete
        disableClearable
        disablePortal
        id="combo-box-demo"
        options={capTimeframe}
        getOptionLabel={(option) => option.name}
        sx={{ width: "100%" }}
        onChange={(event, newValue) =>
          handleAutocompleteChange("period", newValue.value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Timeframe"
            variant="standard"
            value={period}
            required
          />
        )}
      />
      <Autocomplete
        disableClearable
        disablePortal
        id="combo-box-demo"
        options={capType}
        getOptionLabel={(option) => option.name}
        sx={{ width: "100%", marginTop: "26px" }}
        onChange={(event, newValue) =>
          handleAutocompleteChange("type", newValue.value)
        }
        renderInput={(params) => (
          <TextField {...params} label="Type" variant="standard" value={type} />
        )}
      />
      <TextField
        style={{ width: "100%", marginTop: "26px" }}
        label="Value"
        name="value"
        onChange={handleChangeDataForm}
        type="number"
        value={value}
        variant="standard"
      />
      <Autocomplete
        size="small"
        disablePortal
        filterSelectedOptions
        multiple
        getOptionLabel={(option) => `${option.title}`}
        getOptionDisabled={(option) => option.disabled}
        id="days-autocomplete"
        value={selected}
        options={affiliates}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        style={{ width: "100%", marginTop: "26px" }}
        renderInput={(params) => (
          <TextField
            required
            id="affiliate"
            label="Affiliate"
            name="affiliate"
            type="search"
            variant="standard"
            {...params}
          />
        )}
        onChange={(event, value) => onOptionsChange(event, value)}
      />
      <br /> <br />
      <div align="right">
        <ThemeProvider theme={theme}>
          <Button
            color="primary"
            onClick={() => postCap()}
            variant="contained"
            sx={{ marginRight: "10px" }}
          >
            Agregar
          </Button>
        </ThemeProvider>

        <Button color="primary" onClick={openCloseModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );

  const deleteCap = (
    <div
      style={{
        position: "absolute",
        width: 600,
        backgroundColor: "white",
        padding: "16px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <h2>¿Estás seguro que deseas eliminar este CAP?</h2>

      <div align="center" style={{ marginTop: "30px" }}>
        <Button
          color="primary"
          onClick={openCloseModalDelete}
          variant="outlined"
          sx={{ marginRight: "10px" }}
        >
          Cancelar
        </Button>
        <Button
          color="error"
          onClick={() => exterminateCap()}
          variant="contained"
        >
          Eliminar
        </Button>
      </div>
    </div>
  );

  const editCap = (
    <div className={styles.wrapperCap}>
      <h3 style={{ fontWeight: "600" }}>Editar Cap</h3>
      <br />
      <Autocomplete
        disableClearable
        disablePortal
        id="combo-box-demo"
        options={capTimeframe}
        getOptionLabel={(option) => option.name}
        sx={{ width: "100%" }}
        onChange={(event, newValue) =>
          handleAutocompleteChange("period", newValue.value)
        }
        value={
          caps.period
            ? capTimeframe.find(
                (timeframe) => timeframe.value === caps.period
              ) || caps.period
            : ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Timeframe"
            variant="standard"
            value={selectedCapEdit ? selectedCapEdit.period : ""}
          />
        )}
      />
      <Autocomplete
        disableClearable
        disablePortal
        id="combo-box-demo"
        options={capType}
        getOptionLabel={(option) => option.name}
        sx={{ width: "100%", marginTop: "26px" }}
        onChange={(event, newValue) =>
          handleAutocompleteChange("type", newValue.value)
        }
        value={
          caps.type
            ? capType.find((type) => type.value === caps.type) || caps.type
            : ""
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Type"
            variant="standard"
            value={selectedCapEdit ? selectedCapEdit.type : ""}
          />
        )}
      />
      <TextField
        style={{ width: "100%", marginTop: "26px" }}
        label="Value"
        name="value"
        onChange={handleChangeDataForm}
        type="number"
        value={caps.value}
        variant="standard"
      />
      <Autocomplete
        size="small"
        disablePortal
        filterSelectedOptions
        multiple
        getOptionLabel={(option) => `${option.title}`}
        getOptionDisabled={(option) => option.disabled}
        id="days-autocomplete"
        options={affiliates}
        value={dataCaps.affiliates}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        style={{ width: "100%", marginTop: "26px" }}
        renderInput={(params) => (
          <TextField
            required
            id="affiliate"
            label="Affiliate"
            name="affiliate"
            type="search"
            variant="standard"
            {...params}
          />
        )}
        onChange={(event, value) => onOptionsChange(event, value)}
      />
      <br /> <br />
      <div align="right">
        <Button color="primary" onClick={() => postCap()}>
          Agregar
        </Button>
        <Button color="primary" onClick={openCloseModalEdit}>
          Cancelar
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "800px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Button
            onClick={openCloseModalAdd}
            sx={{ marginBottom: "10px", width: "180px" }}
            variant="contained"
          >
            Nuevo Cap
          </Button>
        </ThemeProvider>

        <br />
        <TableContainer style={{ width: "100%" }}>
          <Table>
            <TableHead sx={{ height: "20px" }}>
              <TableRow>
                <TableCell>Timeframe</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Affiliate</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataCaps.map((el) => (
                <TableRow key={el.id}>
                  <TableCell>{el.period}</TableCell>
                  <TableCell>{el.type}</TableCell>
                  <TableCell>{el.value}</TableCell>
                  <TableCell>{el.affiliates.join(", ")}</TableCell>
                  <TableCell>
                    {/* <Tooltip title="Editar cap">
                      <IconButton>
                        <Edit
                          onClick={() => selectCap(el, "Edit")}
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Eliminar cap">
                      <IconButton onClick={() => selectCap(el, "Delete")}>
                        <Delete style={{ cursor: "pointer" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={modal} onClose={() => openCloseModal()}>
          {addCap}
        </Modal>

        <Modal open={modalEdit} onClose={() => openCloseModalEdit()}>
          {editCap}
        </Modal>

        <Modal open={modalDelete} onClose={() => openCloseModalDelete()}>
          {deleteCap}
        </Modal>
      </Box>
    </>
  );
};

export default Caps;
