import React from "react";
import Swal from "sweetalert2";

const Cap = ({ value, row, api, setCap, setTimeframe }) => {
  const handleChange = (e) => {
    if (!row.timeframe && !row.cap_total_offer_timeframe) {
      Swal.fire("Primero se debe cargar un timeframe", "", "error");
      return;
    } else {
      const updatedValue = e.target.value;

      e.preventDefault();
      api.setEditCellValue({
        id: row.id,
        field: "cap",
        value: updatedValue,
      });
    }
  };

  let timeframe = row.timeframe ? row.timeframe : row.cap_total_offer_timeframe;

  setCap(value);
  setTimeframe(timeframe);

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      style={{
        width: "100%",
        padding: "10px",
        outline: "none",
        border: "none",
      }}
    />
  );
};

export default Cap;
