import { Autocomplete, Box, Button, Menu, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { editBoardField } from "../../utils/ApiCall";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const EditableSelectMultiple = ({
  row,
  field,
  handleEditResponseChange,
  options,
  getOptionLabelFunc,
  initialValue,
  label,
}) => {
  let currentUrl = window.location.pathname;
  const initialSelectedValues = initialValue
    ? initialValue.split(",").map((item) => item.trim())
    : [];

  const [selectedValue, setSelectedValue] = useState(
    initialSelectedValues || []
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [{ open }, setState] = useState({
    open: false,
  });
  const [windowWidth] = useState(window.innerWidth);

  const isMobile = windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const cellStyle = {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "160px",
    cursor: "pointer",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "24%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "10px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = () => {
    const valueData = selectedValue.map((item) => item).join(", ");
    editBoardField(currentUrl, [row.id], field, valueData);
    handleEditResponseChange(valueData, row.id, field);
    handleClose();
  };

  return (
    <Box>
      <div onClick={handleClick} ref={cellRef} style={cellStyle}>
        {initialValue ? (
          <span className="cell-data">{initialValue}</span>
        ) : (
          <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
        )}
      </div>
      <Menu {...menuProps}>
        {" "}
        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          options={options}
          getOptionLabel={getOptionLabelFunc}
          sx={{
            width: "100%",
          }}
          onChange={handleAutocompleteChange}
          value={selectedValue}
          multiple
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" />
          )}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button sx={{ marginTop: "20px" }} onClick={handleSubmit}>
            Guardar
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableSelectMultiple;
