import React, { useContext } from "react";
import Header from "../../../../components/global/Header";
import ViewsPerformance from "../../../others/ViewsPerformance";
import container from "../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useState } from "react";
import {
  editOfferAffise,
  editBoardField,
  postCapAffise,
  getStatus,
} from "../../../../utils/ApiCall";
import Threads from "../../../Threads/Threads";
import { UserContext } from "../../../../UserContext";
import { countries } from "../../../../json/Countries";
import { Currencies } from "../../../../json/Currencies";
import { Divider, Tooltip } from "@mui/material";
import Cap from "../../../../components/aluna/Cap.jsx";
import ReporteInvalidaciones from "../../../../components/global/ReporteInvalidaciones";
import Duration from "../../../../components/global/Duration.jsx";
import {
  csmStatusPerformanceOptions,
  afiliacionStatusPerformanceOptions,
  offerStatusPerformanceOptions,
  pixelStatus,
  batPreviewOptions,
} from "../../../../json/StatusOptions";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/ColumnStatus";
import EditableSelect from "../../../../components/global/EditableSelect.jsx";
import {
  encargadaCsm,
  otherOptions,
} from "../../../../json/CampaignCreator.js";
import EditableText from "../../../../components/global/EditableText.jsx";
import EditableField from "../../../../components/global/EditableField.jsx";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AlunaMasterPerformance = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [status, setStatus] = useState("Offer ID");
  const apiUrl = `${backendUrl}/api/master-performance/items?status=${status}`;
  const [offerID, setOfferID] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [notProfitable, setNotProfitable] = useState([]);
  const [payoutAceptado, setPayoutAceptado] = useState("");
  const [cap, setCap] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [capMensual, setCapMensual] = useState("");
  const [revenueMonedaAnunciante, setRevenueMonedaAnunciante] = useState("");
  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;

  const [groups, setGroups] = useState([
    "Offer ID",
    "Inactive",
    "Not profitable",
  ]);

  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [columnsFilter, setColumnsFilter] = useState({ id: false });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: ["__check__", "offerID", "threads", "offerTitle"],
    right: [],
  });

  const [position, setPosition] = useState([0]);
  const [selectedView, setSelectedView] = useState("tabla-principal");
  const [refreshState, setRefreshState] = useState(false);
  const { key, setKey } = useContext(UserContext);
  const [selectionRow, setSelectionRow] = useState([]);
  const [density, setDensity] = useState("standard");
  const [updateRows, setUpdateRows] = useState([]);

  setKey(localStorage?.getItem("jwt"));

  function OptionsFromColumns(array, attribute) {
    const valoresUnicos = new Set();
    for (const obj of array) {
      const valor = obj[attribute];
      if (valor !== undefined) {
        valoresUnicos.add(valor);
      }
    }
    return Array.from(valoresUnicos);
  }

  const filterByStatus = (rows, status) => {
    const filters = {
      "Offer ID": (item) =>
        item.offerStatus !== "INACTIVE" &&
        item.offerStatus !== "NOT PROFITABLE",
      INACTIVE: (item) => item.offerStatus === "INACTIVE",
      "NOT PROFITABLE": (item) => item.offerStatus === "NOT PROFITABLE",
    };

    return rows.filter(filters[status]);
  };

  const filterItemsAndSetGroups = async (items) => {
    setOfferID(filterByStatus(items, "Offer ID"));
    setInactive(filterByStatus(items, "INACTIVE"));
    setNotProfitable(filterByStatus(items, "NOT PROFITABLE"));
  };

  const mapFilterOptionsCode = (options) => {
    const filterOptions = options.map((option) => {
      return option.code;
    });
    return filterOptions;
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "master-performance");
    } else {
      status = newValue;
    }

    if (status === "NOT PROFITABLE") {
      const updatedRowsNotP = notProfitable.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setNotProfitable(updatedRowsNotP);
    } else if (status === "INACTIVE") {
      const updatedRowsInactives = inactive.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setInactive(updatedRowsInactives);
    } else {
      const updatedRowsOfferID = offerID.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setOfferID(updatedRowsOfferID);
    }
  };

  const mapFilterOptions = (options) => {
    const filterOptions = options.map((option) => {
      return option.label;
    });
    return filterOptions;
  };

  const columns = [
    {
      field: "id",
      headerName: " ID",
      width: 70,
      headerAlign: "center",
      align: "center",
      visible: "false",
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerStatus",
      headerName: "OFFER Status",
      width: 180,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(offerStatusPerformanceOptions),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"offerStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={offerStatusPerformanceOptions}
        />
      ),
    },
    {
      field: "direct",
      headerName: "Other",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"direct"}
          handleEditResponseChange={handleEditResponseChange}
          options={otherOptions.map((opt) => opt.title)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.direct}
          label="Other"
        />
      ),
    },
    {
      field: "csmStatus",
      headerName: "CSM Status",
      width: 170,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(csmStatusPerformanceOptions),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"csmStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={csmStatusPerformanceOptions}
        />
      ),
    },
    {
      field: "affiliacionStatus",
      headerName: "Affiliacion Status",
      width: 170,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(afiliacionStatusPerformanceOptions),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"affiliacionStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={afiliacionStatusPerformanceOptions}
        />
      ),
    },
    {
      field: "pixelStatus",
      headerName: "Verificación de Pixel",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(pixelStatus),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"pixelStatus"}
          columnPosition={122}
          optionsAmount={"low"}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={pixelStatus}
        />
      ),
    },
    {
      field: "advertiserName",
      headerName: "Advertiser Name",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: OptionsFromColumns(offerID, "advertiserName"),
      cellClassName: "cell-content",
      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "typeOfClient",
      headerName: "Tipo de cliente",
      width: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"typeOfClient"}
          handleEditResponseChange={handleEditResponseChange}
          options={["A", "B", "C"]}
          spanText={params.row.typeOfClient}
          initialValue={params.row.typeOfClient}
          label="Tipo de Cliente"
        />
      ),
    },
    {
      field: "encargadaCsm",
      headerName: "Encargada CSM",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"encargadaCsm"}
          handleEditResponseChange={handleEditResponseChange}
          options={encargadaCsm.map((encargada) => encargada.name)}
          spanText={params.row.encargadaCsm}
          initialValue={params.row.encargadaCsm}
          label="Encargada CSM"
        />
      ),
    },
    {
      field: "affiseLink",
      headerName: "Affise Link",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={params.value}>
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "var(--blueFour)" }}
            >
              {params.value}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Duration {...params} selectionRow={selectionRow} />
      ),
    },
    {
      field: "BAT_Preview",
      headerName: "BAT/Preview",
      width: 160,
      headerAlign: "center",
      align: "center",
      cellClassName: "no-padding",
      type: "singleSelect",
      valueOptions: mapFilterOptions(batPreviewOptions),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"BAT_Preview"}
          columnPosition={122}
          optionsAmount={"low"}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={batPreviewOptions}
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPL", "CPI", "CPM", "CPA"]}
          spanText={params.row.conversionType}
          initialValue={params.row.conversionType}
          label="Conversion Type"
        />
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptionsCode(countries),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          {countries.map((el) => {
            if (params?.value === el.label || params?.value === el.code) {
              return (
                <>
                  <img
                    src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                    style={{ marginRight: "8px" }}
                    alt="img"
                  />
                </>
              );
            } else return null;
          })}
          {countries.map((el) => {
            if (
              params?.value?.toLowerCase() === el.label.toLowerCase() ||
              params?.value?.toLowerCase() === el.code.toLowerCase()
            ) {
              return <p>{el.code}</p>;
            } else return null;
          })}
        </div>
      ),
    },
    {
      field: "cap",
      headerName: "Cap",
      width: 80,
      headerAlign: "center",
      align: "center",

      renderEditCell: (params) => (
        <Cap {...params} setCap={setCap} setTimeframe={setTimeframe} />
      ),
      editable: true,
    },
    {
      field: "timeframe",
      headerName: "Timeframe",
      width: 140,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"timeframe"}
          handleEditResponseChange={handleEditResponseChange}
          options={["Month", "Day", "All"]}
          spanText={
            params.row.timeframe === "day" || params.row.timeframe === "Day" ? (
              <p className="cell-data">Daily</p>
            ) : params.row.timeframe === "month" ||
              params.row.timeframe === "Month" ? (
              <p className="cell-data">Monthly</p>
            ) : params.row.timeframe === "all" ||
              params.row.timeframe === "All" ? (
              <p className="cell-data">Open</p>
            ) : (
              <p className="cell-data">{params.row.timeframe}</p>
            )
          }
          initialValue={params.row.timeframe}
          label="Timeframe"
        />
      ),
    },
    {
      field: "cumplimiento",
      headerName: "Cumplimiento CAP",
      width: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        if (
          params.row.timeframe !== "month" ||
          params.row.pendingQty == 0 ||
          params.row.pendingQty == null ||
          params.row.cap == 0
        ) {
          return null;
        }
        let cumplimiento = (params.row.pendingQty / params.row.cap) * 100;
        return `${cumplimiento.toFixed(2)}%`;
      },
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Sources Allowed",
      width: 220,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "revenueUSD",
      headerName: "Revenue USD",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "revenueMAnunciante",
      headerName: "Revenue Moneda Anunciante",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderEditCell: (params) => (
        <EditableField {...params} setState={setRevenueMonedaAnunciante} />
      ),
      editable: "true",
    },
    {
      field: "monedaAnunciante",
      headerName: "Moneda Anunciante",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"monedaAnunciante"}
          handleEditResponseChange={handleEditResponseChange}
          options={Currencies}
          spanText={<p className="cell-data">{params.value}</p>}
          initialValue={params.row.monedaAnunciante}
          label="Moneda Anunciante"
          offerID={params.row.offerID}
          backendUrl={backendUrl}
          clave={key}
        />
      ),
    },
    {
      field: "payoutUSD",
      headerName: "Payout USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "previewLink",
      headerName: "Preview Link",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "var(--blueFour)" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"targeting"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params?.row?.targeting?.replace(/(<([^>]+)>)/gi, "")}
        />
      ),
    },
    {
      field: "timeToValidation",
      headerName: "Tiempo de validación",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"timeToValidation"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params?.row?.timeToValidation}
        />
      ),
    },
    {
      field: "reporteInvalidacion",
      headerName: "Reporte De Invalidacion",
      width: 160,
      headerAlign: "center",
      renderCell: (params) => (
        <ReporteInvalidaciones
          {...params}
          backendUrl={backendUrl}
          initialValue={params.row.reporteInvalidacion}
          pathname={pathname}
          typeColumn={"postReporteInvalidacion"}
          field={"reporteInvalidacion"}
          deleteTypeColumn={"deleteReporteInvalidacion"}
          fileCount={params?.row?.reporteInvalidacion?.length || 0}
        />
      ),
    },
    {
      field: "estimadoCapMensual",
      headerName: "Estimado Cap Mensual",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderEditCell: (params) => (
        <EditableField {...params} setState={setCapMensual} />
      ),
      editable: true,
    },
    {
      field: "payoutAceptado",
      headerName: "Payout Aceptado",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderEditCell: (params) => (
        <EditableField {...params} setState={setPayoutAceptado} />
      ),
      editable: true,
    },
    {
      field: "maximoProfit",
      headerName: "Maximo Profit",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "maximoProfitPayAceptado",
      headerName: "Maximo Profit Pay Aceptado",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiseClicks",
      headerName: "Affise clicks",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingPayout",
      headerName: "Pending payout",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingRevenue",
      headerName: "Pending revenue",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingQty",
      headerName: "Pending QTY",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
  ];

  const controlEdit = async (params) => {
    let ids = selectionRow.length !== 0 ? selectionRow : [params.id];
    switch (params.field) {
      case "payoutAceptado":
        await editBoardField(
          "/master-performance",
          ids,
          params.field,
          payoutAceptado
        );
        setRefreshState(true);
        break;
      case "estimadoCapMensual":
        await editBoardField(
          "/master-performance",
          ids,
          params.field,
          capMensual
        );
        setRefreshState(true);
        break;
      case "cap":
        if(timeframe && cap){
          await postCapAffise(cap, timeframe, [], params.row.offerID);
          setRefreshState(true);
        }
        break;
      case "revenueMAnunciante":
        const dataOffer = {
          revenueMonedaAnunciante: revenueMonedaAnunciante,
          id: params.row.offerID,
        };
        await editOfferAffise(dataOffer);
        setRefreshState(true);
        break;
      default:
        break;
    }
  };

  const getGroupRows = (t) => {
    return t === "Offer ID"
      ? offerID
      : t === "Inactive"
      ? inactive
      : t === "Not profitable"
      ? notProfitable
      : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  return (
    <div
      className={container.globalContainer}
      style={{ padding: isMobile ? "5px 10px" : "10px 30px" }}
    >
      <Header title="" />
      <ViewsPerformance
        setViewFilter={setViewFilter}
        setColumnsFilter={setColumnsFilter}
        setPinnedColumns={setPinnedColumns}
        setTables={setGroups}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        setDensity={setDensity}
        density={density}
      />
      <Divider sx={{ marginBottom: "20px" }} />

      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        position={position}
        setPosition={setPosition}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        controlEdit={controlEdit}
        refreshState={refreshState}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        viewFilter={viewFilter}
        setViewFilter={setViewFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        pathname={pathname}
        updateBoard={updateBoard}
        updateRows={updateRows}
        selectionRow={selectionRow}
        setSelectionRow={setSelectionRow}
        selectedView={selectedView}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default AlunaMasterPerformance;
