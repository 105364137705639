import {
  Autocomplete,
  Box,
  Button,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { editBoardField } from "../../utils/ApiCall";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const EditableSelect = ({
  row,
  field,
  handleEditResponseChange,
  options,
  spanText,
  initialValue,
  label,
}) => {
  let currentUrl = window.location.pathname;
  const [selectedValue, setSelectedValue] = useState(initialValue || null);
  const [saveButton, setSaveButton] = useState("Guardar");
  const [disableSave, setDisableSave] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [{ open }, setState] = useState({
    open: false,
  });
  const [windowWidth] = useState(window.innerWidth);

  const isMobile = windowWidth <= 600;
  const cellRef = useRef(null);
  const handleClose = () => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const cellStyle = {
    position: "relative",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 350,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "115px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = async () => {
    setSaveButton("Guardando");
    setDisableSave(true);
    editBoardField(currentUrl, [row.id], field, selectedValue);
    handleEditResponseChange(selectedValue, row.id, field);
    setSaveButton("Guardar");
    setDisableSave(false);
    handleClose();
  };

  return (
    <Box>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        <div
          style={{
            width: "max-content",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {spanText ? (
            <Tooltip title={spanText}>
              <span className={"cell-data"}>{spanText}</span>
            </Tooltip>
          ) : (
            <AddCircleIcon sx={{ color: "var(--blackLow)" }} />
          )}
        </div>
      </p>
      <Menu {...menuProps}>
        {" "}
        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          options={options}
          getOptionLabel={(opt) => opt}
          sx={{
            width: "100%",
          }}
          onChange={handleAutocompleteChange}
          value={selectedValue}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" />
          )}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={disableSave}
          >
            {saveButton}
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default EditableSelect;
