export const offerStatusPerformanceOptions = [
  { value: "PIXEL INTEGRATION", label: "PIXEL INTEGRATION" },
  { value: "AON", label: "AON" },
  { value: "COMERCIAL WORKING ON IT", label: "COMERCIAL WORKING ON IT" },
  { value: "PAUSED", label: "PAUSED" },
  { value: "CSM WORKING ON IT", label: "CSM WORKING ON IT" },
  { value: "PAUSED - CAP REACHED", label: "PAUSED - CAP REACHED" },
  { value: "AFFISE SETUP", label: "AFFISE SETUP" },
  { value: "NOT PROFITABLE", label: "NOT PROFITABLE" },
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "INACTIVE", label: "INACTIVE" },
  { value: "RUNNING", label: "RUNNING" },
  { value: "THE BOX", label: "THE BOX" },
  { value: null, label: "Empty" },
];
export const offerStatusCPSOptions = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "BAT/PREVIEW", label: "BAT/PREVIEW" },
  { value: "PAUSED", label: "PAUSED" },
  { value: "PAUSED - CAP REACHED", label: "PAUSED - CAP REACHED" },
  { value: "PENDING AFFISE", label: "PENDING AFFISE" },
  { value: "NOT PROFITABLE", label: "NOT PROFITABLE" },
  { value: "RUNNING", label: "RUNNING" },
  { value: "INACTIVE", label: "INACTIVE" },
  { value: "AON", label: "AON" },
  { value: "PIXEL INTEGRATION", label: "PIXEL INTEGRATION" },
  { value: "CSM WORKING ON IT", label: "CSM WORKING ON IT" },
  { value: "THE BOX", label: "THE BOX" },
  { value: null, label: "Empty" },
];
export const csmStatusPerformanceOptions = [
  { value: "Pixel Required", label: "Pixel Required" },
  { value: "Nuevos Creativos", label: "Nuevos Creativos" },
  { value: "Done", label: "Done" },
  { value: "Ajuste BAT", label: "Ajuste BAT" },
  { value: "Pixel Integration", label: "Pixel Integration" },
  { value: "Nuevo Tracking", label: "Nuevo Tracking" },
  { value: "Reporte Invalidaciones", label: "Reporte Invalidaciones" },
  { value: "Pending Info Cliente", label: "Pending Info Cliente" },
  { value: "Nueva Campaña", label: "Nueva Campaña" },
  { value: "Global Postback", label: "Global Postback" },
  { value: "Bat Preview", label: "Bat Preview" },
  { value: "Nuevo CAP", label: "Nuevo CAP" },
  { value: "Hacer Seguimiento", label: "Hacer Seguimiento" },
  { value: "Pending Test", label: "Pending Test" },
  { value: "Nuevo Revenue", label: "Nuevo Revenue" },
  { value: "Verificando con cliente", label: "Verificando con cliente" },
  { value: "Conversiones Manuales", label: "Conversiones Manuales" },
  { value: "Tracking Issues", label: "Tracking Issues" },
  { value: "Ajuste Conversiones", label: "Ajuste Conversiones" },
  { value: null, label: "Empty" },
];
export const csmStatusCPSOptions = [
  { value: "Working On It", label: "Working On It" },
  { value: "Nuevos Creativos", label: "Nuevos Creativos" },
  { value: "Conversiones Manuales", label: "Conversiones Manuales" },
  { value: "Done", label: "Done" },
  { value: "Pending Test", label: "Pending Test" },
  { value: "Ajuste Conversiones", label: "Ajuste Conversiones" },
  { value: "Tracking Issues", label: "Tracking Issues" },
  { value: "Hacer Seguimiento", label: "Hacer Seguimiento" },
  { value: "Reached", label: "Reached" },
  { value: "Pixel Required", label: "Pixel Required" },
  { value: "Non Reached", label: "Non Reached" },
  { value: "Pixel Integration", label: "Pixel Integration" },
  { value: "Global Postback", label: "Global Postback" },
  { value: "Nueva Campaña", label: "Nueva Campaña" },
  { value: null, label: "Empty" },
];
export const afiliacionStatusPerformanceOptions = [
  { value: "Reporte de Calidad", label: "Reporte de Calidad" },
  { value: "Varias", label: "Varias" },
  { value: "Aprobacion de Subjects", label: "Aprobacion de Subjects" },
  { value: "Landing No Funciona", label: "Landing No Funciona" },
  { value: "Nuevos Formatos", label: "Nuevos Formatos" },
  { value: "Punto de Fuga", label: "Punto de Fuga" },
  { value: "Reactivar?", label: "Reactivar?" },
  { value: "Nuevos Creativos", label: "Nuevos Creativos" },
  { value: "Aumento de PAY", label: "Aumento de PAY" },
  { value: "Declined-Pending", label: "Declined-Pending" },
  { value: "Discrepancia", label: "Discrepancia" },
  { value: "Verificar Integracion", label: "Verificar Integracion" },
  { value: "Segmentacion Sugerida", label: "Segmentacion Sugerida" },
  { value: "Aumento de CAP", label: "Aumento de CAP" },
  { value: "Reporte Invalidaciones", label: "Reporte Invalidaciones" },
  { value: "Cambiar Pay de Afiliado", label: "Cambiar Pay de Afiliado" },
  { value: "Not Taken", label: "Not Taken" },
  { value: "Done", label: "Done" },
  { value: "Flow", label: "Flow" },
  { value: null, label: "Empty" },
];
export const afiliacionStatusCPSOptions = [
  { value: "Working On It", label: "Working On It" },
  { value: "Nuevos Creativos", label: "Nuevos Creativos" },
  { value: "Done", label: "Done" },
  { value: "Cupones/Descuentos", label: "Cupones/Descuentos" },
  { value: "Discrepancia", label: "Discrepancia" },
  { value: "Aumento de PO", label: "Aumento de PO" },
  { value: "Reached", label: "Reached" },
  { value: "Non Reached", label: "Non Reached" },
  { value: "Nuevo formato", label: "Nuevo formato" },
  { value: null, label: "Empty" },
];

export const cpcOfferStatus = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "RUNNING", label: "RUNNING" },
  { value: "TERMINADO", label: "TERMINADO" },
  { value: "PAUSED", label: "PAUSED" },
  { value: "BAT/PREVIEW", label: "BAT/PREVIEW" },
  { value: null, label: "Empty" },
];

export const tasksAlunaStatus = [
  { value: "Open", label: "Open" },
  { value: "Working on it", label: "Working on it" },
  { value: "Done", label: "Done" },
  { value: "Stuck", label: "Stuck" },
  { value: "", label: "Empty" },
];

export const tasksAlunaType = [
  { value: "Administrative", label: "Administrative" },
  { value: "Affise", label: "Affise" },
  { value: "Cotizacion", label: "Cotizacion" },
  { value: "Marketing", label: "Marketing" },
  { value: "IT", label: "IT" },
  { value: "Pre lanzamiento", label: "Pre lanzamiento" },
  { value: "Operations", label: "Operations" },
  { value: "Anunciantes", label: "Anunciantes" },
  { value: "Invalidaciones", label: "Invalidaciones" },
  { value: "Logistics", label: "Logistics" },
  { value: "Performance", label: "Performance" },
  { value: "Reportes", label: "Reportes" },
  { value: "The Box", label: "The Box" },
  { value: "Afiliados", label: "Afiliados" },
  { value: "Mediabuying", label: "Mediabuying" },
  { value: null, label: "Empty" },
];

export const validacionesStatus = [
  {
    value: "Cierre no recibido por parte del cliente",
    label: "Cierre no recibido por parte del cliente",
  },
  { value: "Invoiced", label: "Invoiced" },
  {
    value: "Cierre recibido por parte del cliente",
    label: "Cierre recibido por parte del cliente",
  },
  { value: "Paid", label: "Paid" },
  {
    value: "Info Pendiente por parte del cliente",
    label: "Info Pendiente por parte del cliente",
  },
  { value: "Pendiente Reporte ClickID", label: "Pendiente Reporte ClickID" },
  { value: "Pending Affise", label: "Pending Affise" },
  { value: "It won't be paid", label: "It won't be paid" },
  {
    value: "Campañas pendientes por recibir cierre",
    label: "Campañas pendientes por recibir cierre",
  },
  {
    value: "Facturación pendiente. No cumple con monto minimo para facturar",
    label: "Facturación pendiente. No cumple con monto minimo para facturar",
  },
  { value: "Ready to Invoice", label: "Ready to Invoice" },
  { value: "Facturación Pendiente", label: "Facturación Pendiente" },
  { value: null, label: "Empty" },
];

export const inhouseMediaBuyingStatus = [
  { value: "Recibida", label: "Recibida" },
  { value: "Subida", label: "Subida" },
  { value: "En revisión", label: "En revisión" },
  { value: "Pausada", label: "Pausada" },
  { value: "Finalizada", label: "Finalizada" },
  { value: "Solicitud a CSM", label: "Solicitud a CSM" },
  { value: null, label: "Empty" },
];

export const prospectingStatus = [
  { value: "En Proceso", label: "En Proceso" },
  { value: "Prospectado", label: "Prospectado" },
  { value: "Descartado", label: "Descartado" },
  { value: "1 contacto", label: "1 contacto" },
  { value: "Integrado", label: "Integrado" },
  { value: "2 Contacto", label: "2 Contacto" },
  { value: "Activo", label: "Activo" },
  { value: "Negociando", label: "Negociando" },
  { value: "Problemas de negociación", label: "Problemas de negociación" },
];

export const prospectingComercialStatus = [
  { value: "Primer contacto", label: "Primer contacto" },
  { value: "Segundo contacto", label: "Segundo contacto" },
  { value: "Cena", label: "Cena" },
  { value: "Reunion presencial", label: "Reunion presencial" },
  { value: "Reunion virtual", label: "Reunion virtual" },
];

export const comercialCRMStatus = [
  { value: "Propuesta Enviada", label: "Propuesta Enviada" },
  { value: "Feedback cliente", label: "Feedback cliente" },
  { value: "Negociacion", label: "Negociacion" },
  { value: "Orden Inicial", label: "Orden Inicial" },
  { value: "Perdido", label: "Perdido" },
  { value: "Creación de cliente", label: "Creación de cliente" },
  { value: "Cotización Solicitada", label: "Cotización Solicitada" },
];

export const infoTraficoAfiliadoStatus = [
  { value: "En Integración", label: "En Integración" },
  { value: null, label: "Empty" },
  { value: "Inactivo", label: "Inactivo" },
  { value: "Rtg", label: "Rtg" },
  { value: "Activo", label: "Activo" },
  { value: "Para activar", label: "Para activar" },
  { value: "Para reactivar", label: "Para reactivar" },
  { value: "No responde", label: "No responde" },
  { value: "Banneado", label: "Banneado" },
];

export const solicitudCampanaStatus = [
  { value: "Pendiente", label: "Pendiente" },
  { value: "En Proceso (2)", label: "En Proceso (2)" },
  { value: "No encontrada", label: "No encontrada" },
  { value: "Solicitada a cliente (1)", label: "Solicitada a cliente (1)" },
  { value: "Encontrada (3)", label: "Encontrada (3)" },
  { value: "Campaña pausada", label: "Campaña pausada" },
  { value: null, label: "Empty" },
];

export const pedidosItStatus = [
  { value: "Backlog", label: "Backlog" },
  { value: "To do", label: "To do" },
  { value: "Doing", label: "Doing" },
  { value: "Done", label: "Done" },
  { value: "Blocked", label: "Blocked" },
];

export const pedidosItTags = [
  { title: "Notificaciones" },
  { title: "Notas" },
  { title: "Actualizaciones" },
  { title: "Automatizaciones" },
  { title: "Columnas" },
  { title: "Mails" },
  { title: "Vistas" },
  { title: "Filtros" },
  { title: "Nuevos Desarrollos" },
  { title: "Accesibilidad" },
  { title: "Diseño" },
  { title: "Data Tableros" },
  { title: "Bug" },
  { title: "Affise" },
  { title: "Editar Campaña" },
  { title: "Panel de Caps" },
  { title: "Alertas" },
];

export const alertsStatus = [{ value: "Reviewed", label: "Reviewed" }];

export const offerStatusMasterBranding = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "RUNNING", label: "RUNNING" },
  { value: "PAUSED", label: "PAUSED" },
  { value: "COMPLETED", label: "COMPLETED" },
  { value: null, label: "Empty" },
];
export const conversiones = [
  { value: "CONFIRMED", label: "CONFIRMED" },
  { value: "PENDING", label: "PENDING" },
  { value: "DECLINED", label: "DECLINED" },
  { value: "", label: "null" },
];

export const COLORS = {
  "Primer contacto": "rgb(161, 227, 246)",
  "Segundo contacto": "var(--violet)",
  Cena: "rgb(253, 171, 61)",
  "Reunion presencial": "rgb(0, 200, 117)",
  "Reunion virtual": "var(--red)",
  COMPLETED: "var(--pinkGlow)",
  New: "rgb(161, 227, 246)",
  Reviewed: "rgb(253, 171, 61)",
  Backlog: "var(--blue)",
  "To do": "var(--violet)",
  Doing: "var(--orange)",
  Blocked: "var(--red)",
  Pendiente: "rgb(255, 21, 138)",
  "En Proceso (2)": "rgb(253, 171, 61)",
  "No encontrada": "rgb(226, 68, 92)",
  "Solicitada a cliente (1)": "rgb(87, 155, 252)",
  "Encontrada (3)": "rgb(0, 200, 117)",
  "Campaña pausada": "rgb(120, 75, 209)",
  Rtg: "rgb(0, 134, 192)",
  Banneado: "rgb(3, 127, 76)",
  "No responde": "rgb(120, 75, 209)",
  "En Integración": "rgb(253, 171, 61)",
  Inactivo: "rgb(226, 68, 92)",
  Activo: "rgb(0, 200, 117)",
  "Para activar": "rgb(87, 155, 252)",
  "Para reactivar": "rgb(255, 21, 138)",
  "En Proceso": "rgb(253, 171, 61)",
  Descartado: "rgb(226, 68, 92)",
  Prospectado: "rgb(196, 196, 196)",
  "1 contacto": "rgb(0, 134, 192)",
  Integrado: "rgb(0, 200, 117)",
  "2 Contacto": "rgb(255, 203, 0)",
  Negociando: "rgb(255, 21, 138)",
  "Problemas de negociación": "rgb(120, 75, 209)",
  "Cierre no recibido por parte del cliente": "rgb(226, 68, 92)",
  Invoiced: "rgb(23, 90, 99)",
  "Cierre recibido por parte del cliente": "rgb(253, 171, 61)",
  Paid: "rgb(189, 168, 249)",
  "Info Pendiente por parte del cliente": "rgb(162, 93, 220)",
  "Pendiente Reporte ClickID": "rgb(161, 227, 246)",
  "Pending Affise": "rgb(87, 155, 252)",
  "It won't be paid": "rgb(3, 127, 76)",
  "Campañas pendientes por recibir cierre": "rgb(196, 196, 196)",
  "Facturación pendiente. No cumple con monto minimo para facturar":
    "rgb(250, 161, 241)",
  "Ready to Invoice": "rgb(0, 200, 117)",
  "Facturación Pendiente": "rgb(0, 134, 192)",
  "Nuevo formato": "rgb(133, 214, 255)",
  "Aumento de PO": "rgb(213, 197, 103)",
  "Cupones/Descuentos": "rgb(53, 153, 112)",
  "Landing No Funciona": "rgb(213, 197, 103)",
  "Aprobacion de Subjects": "rgb(176, 220, 81)",
  "Ajuste Conversiones": "rgb(147, 111, 218)",
  "Ajuste BAT": "var(--ajusteBat)",
  "PIXEL INTEGRATION": "var(--pink)",
  "COMERCIAL WORKING ON IT": "var(--violetDark)",
  "CSM WORKING ON IT": "var(--orange)",
  "Working On It": "var(--orange)",
  "Working on it": "var(--orange)",
  Waiting: "rgb(156, 211, 38)",

  "AFFISE SETUP": "var(--skyBlue)",
  "PENDING AFFISE": "var(--skyBlue)",
  ACTIVE: "var(--greenYellow)",
  RUNNING: "var(--violet)",
  AON: "var(--blue)",
  PAUSED: "var(--paused)",
  "Non Reached": "var(--paused)",
  "PAUSED - CAP REACHED": "var(--greenDark)",
  "NOT PROFITABLE": "var(--blackLow)",
  INACTIVE: "var(--orangeElectric)",
  "THE BOX": "var(--greenGrass)",
  "Pixel Required": "var(--pinkGlow)",
  "Pixel Integration": "var(--blueOcean)",
  "Pending Info Cliente": "var(--uva)",
  "Bat Preview": "var(--mustard)",
  "Pending Test": "var(--cafe)",
  "PENDING TEST": "var(--cafe)",
  "Conversiones Manuales": "var(--blueTwo)",
  "Nuevos Creativos": "var(--blueOceanTwo)",
  "Nuevo Tracking": "var(--greenHoja)",
  "Nueva Campaña": "var(--mustardTwo)",
  "Nuevo CAP": "var(--lila)",
  "Nuevo Revenue": "var(--greenGrassTwo)",
  "Tracking Issues": "var(--issues)",
  "TRACKING ISSUES": "var(--issues)",
  Done: "var(--done)",
  "Reporte Invalidaciones": "var(--aqua)",
  "Global Postback": "var(--salmon)",
  "Hacer Seguimiento": "var(--pinkTwo)",
  "Reporte de Calidad": "var(--greenGrass)",
  "Nuevos Formatos": "var(--blueTwo)",
  "PIXEL REQUIRED": "var(--blueTwo)",
  Discrepancia: "var(--issues)",
  Stuck: "var(--issues)",
  "Aumento de CAP": "var(--cafe)",
  "Solicitud de Flow": "var(--pinkThree)",
  "BAT/PREVIEW": "var(--pinkThree)",
  "Not Taken": "var(--blackLow)",
  Varias: "var(--orange)",
  "Punto de Fuga": "var(--red)",
  "Aumento de PAY": "var(--blueThree)",
  Reached: "var(--blueThree)",
  "Verificar Integracion": "var(--salmonTwo)",
  "Reporte de Invalidaciones": "var(--aqua)",
  "Aprobación de subjects": "var(--greenHoja)",
  "Reactivar?": "var(--mustard)",
  "Declined-Pending": "var(--gray)",
  "Segmentacion Sugerida": "var(--brown)",
  "Cambiar Pay de Afiliado": "var(--salmon)",
  Flow: "var(--orangeElectric)",
  "Verificando con cliente": "rgb(51, 158, 205)",
  "BAT/PREVIEW REQUIRED BEFORE RUNNING": "var(--pinkThree)",
  null: "rgb(196, 196, 196)",
  Empty: "rgb(196, 196, 196)",
  "": "rgb(196, 196, 196)",
  Verificado: "rgb(0, 200, 117)",
  "No verificado": "var(--issues)",
  "Sin Pixel": "var(--blackLow)",
  Working: "var(--aqua)",
  Open: "var(--blueThree)",
  "Not started": "var(--blueThree)",
  TERMINADO: "rgb(78, 204, 198)",
  Administrative: "rgb(0, 134, 192)",
  Affise: "rgb(126, 59, 138)",
  Cotizacion: "rgb(161, 227, 246)",
  Marketing: "rgb(255, 21, 138)",
  IT: "rgb(162, 93, 220)",
  "Pre lanzamiento": "rgb(255, 100, 46)",
  Operations: "rgb(120, 75, 209)",
  Anunciantes: "rgb(255, 203, 0)",
  Invalidaciones: "rgb(202, 182, 65)",
  Logistics: "rgb(3, 127, 76)",
  Performance: "rgb(255, 90, 196)",
  Reportes: "rgb(187, 51, 84)",
  "The Box": "rgb(253, 171, 61)",
  Afiliados: "rgb(78, 204, 198)",
  Mediabuying: "rgb(87, 155, 252)",
  Recibida: "rgb(253, 171, 61)",
  Subida: "rgb(0, 200, 117)",
  "En revisión": "rgb(156, 211, 38)",
  Pausada: "rgb(34, 80, 145)",
  Finalizada: "rgb(226, 68, 92)",
  "Solicitud a CSM": "rgb(255, 90, 196)",
  APPROVED: "var(--greenYellow)",
  CONFIRMED: "var(--greenYellow)",
  PENDING: "var(--paused)",
  DECLINED: "var(--orangeElectric)",
  "Propuesta Enviada": "rgb(162, 93, 220)",
  "Feedback cliente": "rgb(156, 211, 38)",
  Negociacion: "rgb(87, 155, 252)",
  "Orden Inicial": "rgb(3, 127, 76)",
  Perdido: "var(--red)",
  "Creación de cliente": "rgb(250, 161, 241)",
  "Cotización Solicitada": "rgb(255, 100, 46)",
};

export const batPreviewOptions = [
  {
    value: "BAT/PREVIEW REQUIRED BEFORE RUNNING",
    label: "BAT/PREVIEW REQUIRED BEFORE RUNNING",
  },
  { value: null, label: "Empty" },
];

export const pixelStatus = [
  {
    value: "Verificado",
    label: "Verificado",
  },
  {
    value: "No verificado",
    label: "No verificado",
  },
  {
    value: "Sin Pixel",
    label: "Sin Pixel",
  },
];

export const estados = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "RUNNING", label: "RUNNING" },
  { value: "TERMINADO", label: "TERMINADO" },
  { value: "PAUSED", label: "PAUSED" },
  { value: null, label: "Empty" },
  { value: "BAT/PREVIEW", label: "BAT/PREVIEW" },
];
