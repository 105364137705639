import { Box } from "@mui/material";
import React from "react";
import styles from "../../../styles/CampaignCreator.module.css";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import PhotoIcon from "@mui/icons-material/Photo";

const Logo = ({ logo, setLogo }) => {
  const handleLogo = (e) => {
    setLogo(e.target.files[0]);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "800px",
        }}
      >
        <div className={styles.uploadForm}>
          <AddToPhotosIcon sx={{ fontSize: "100px", color: "#784af4" }} />
          <input
            accept="image/*"
            type="file"
            name="file"
            id="file"
            className={styles.input}
            onChange={handleLogo}
          />
          <label for="">
            <p style={{ color: "#784af4", marginTop: "15px" }}>
              Seleccionar imagen
            </p>
          </label>
        </div>
        {logo && logo.length !== 0 ? (
          <div className={styles.logoContainer}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {typeof logo === "string" ? (
                <img src={logo} className={styles.logo} alt="logo" />
              ) : (
                <img
                  src={URL.createObjectURL(logo)}
                  className={styles.logo}
                  alt="logo"
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.logoContainer}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <PhotoIcon
                sx={{
                  fontSize: "200px",
                  color: "#eaeaf0",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Logo;
