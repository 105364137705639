import React, { useContext, useRef, useState } from "react";
import { editComment, editThreadForm } from "../../utils/ApiCall";
import inlineStyles from "../../inlineStyles/threadsStyles";
import styles from "../../styles/Threads.module.css";
import { Box, Button, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import ReactQuill from "react-quill";
import excelimage from "../../assets/excel_v2.svg";
import pdfimage from "../../assets/pdf_v2.svg";
import zipimage from "../../assets/zip_v2.svg";
import wordimage from "../../assets/word_v2.svg";
import { UserContext } from "../../UserContext";
import { useLocation } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { FileUploader } from "react-drag-drop-files";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomFileUploader from "../../components/global/CustomFileUploader";
import { fileTypes } from "../../json/FileTypes";

const BodyContent = ({
  contenidoRef,
  imgRefs,
  message,
  handleImageClick,
  messageType,
  editThread,
  setEditThread,
  editingThreadId,
  row,
  setSelectedUsers,
  setSelectedGroup,
  editCommentForm,
  setEditCommentForm,
  editingCommentId,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [messageLength, setMessageLength] = useState(true);
  const [content, setContent] = useState(message.content?.slice(0, 1000) ?? "");
  const [media, setMedia] = useState(message.media ?? []);
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));
  const quillRef = useRef(null);
  const [originalContent, setOriginalContent] = useState(message.content);
  const location = useLocation();
  const board = location?.pathname?.slice(1);

  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  function handleScrollIntoView(id) {
    const element = document.getElementById(`${messageType}${id}`);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  function handleExpand(id) {
    if (!messageLength) {
      setMessageLength(true);
      let newContent = content?.slice(0, 400) ?? "";
      newContent = newContent + "</p>";
      setContent(newContent);
      setMedia(message.media ?? []);
    } else {
      setMessageLength(false);
      setContent(message?.content ?? "");
      setMedia(message.media ?? []);
    }

    setTimeout(() => {
      handleScrollIntoView(id);
    }, 0);
  }

  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: function (searchTerm, renderList, mentionChar) {
        if (mentionChar === "@") {
          fetch(
            `${backendUrl}/api/users?filters[username][$containsi]=${searchTerm}`
          )
            .then((response) => response.json())
            .then((data) => {
              const mentions = data.map((user) => ({
                id: user.id,
                value: user.username,
                email: user.email,
              }));
              renderList(mentions, searchTerm);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (mentionChar === "#") {
          fetch(
            `${backendUrl}/api/groups?filters[name][$containsi]=${searchTerm}`
          )
            .then((response) => response.json())
            .then((data) => {
              const hashtag = data.data.map((group) => ({
                value: group.attributes.name,
                id: group.id,
              }));
              renderList(hashtag, searchTerm);
            });
        }
      },
      onSelect: function (item, insertItem) {
        if (item.denotationChar === "@") {
          setSelectedUsers((prevMentions) => [...prevMentions, item]);
          insertItem(item);
        }
        if (item.denotationChar === "#") {
          setSelectedGroup((prevMentions) => [...prevMentions, item]);
          insertItem(item);
        }
      },
    },
  }).current;

  // const filterSelectedUsers = (userValue) => {
  //   if (valueWritten) {
  //   }
  //   const selectedUserIds = selectedUsers.map((user) => user.id);
  //   const filteredSelectedUsers = selectedUsers.filter((user) =>
  //     userValue.includes(user.value)
  //   );
  //   const filteredSelectedUserIds = filteredSelectedUsers.map(
  //     (user) => user.id
  //   );

  //   if (selectedUserIds.length !== filteredSelectedUserIds.length) {
  //     setSelectedUsers(filteredSelectedUsers);
  //   }
  // };

  // const filterSelectedGroups = (groupValue) => {
  //   const selectedGroupId = selectedGroup.map((group) => group.id);
  //   const filteredSelectedGroup = selectedGroup.filter((group) =>
  //     groupValue.includes(group.value)
  //   );
  //   const filteredSelectedGroupIds = filteredSelectedGroup.map(
  //     (group) => group.id
  //   );
  //   if (selectedGroupId.length !== filteredSelectedGroupIds.length) {
  //     setSelectedGroup(filteredSelectedGroup);
  //   }
  // };

  // useEffect(() => {
  //   if (valueWritten) {
  //     filterSelectedUsers(valueWritten);
  //     filterSelectedGroups(valueWritten);
  //   }

  //   if (commentWritten) {
  //     filterSelectedUsers(commentWritten);
  //     filterSelectedGroups(commentWritten);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedUsers, valueWritten, content, commentWritten]);

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    if (editThread) {
      const editedThread = await editThreadForm(
        key,
        message.id,
        content,
        board,
        row.id,
        file,
        media
      );
      let thread = editedThread.data.threads.filter(
        (thread) => thread.id === message.id
      );
      setContent(thread[0].content);
      setEditThread(false);
      setMedia(thread[0].media ?? []);
      setOriginalContent(thread[0].content);
      setSelectedFile([]);
      setFile("");
    }
    if (editCommentForm) {
      const editedComment = await editComment(
        key,
        editingCommentId,
        content,
        board,
        row.id,
        file,
        media
      );

      const findComment = (threads, idComment) => {
        let comment = [];
        for (let index = 0; index < threads.length; index++) {
          threads[index].comments.forEach((e) => {
            if (e.id === idComment) {
              comment.push(e);
            }
          });
        }
        return comment;
      };
      let comment = findComment(editedComment.data.threads, editingCommentId); // TODO aca si no había ningun media y se agrega no se renderiza
      // console.log(comment[0].media, "MEDIA")
      setContent(comment[0].content);
      setEditCommentForm(false);
      setOriginalContent(comment[0].content);
      setMedia(comment[0].media ?? []);
      setSelectedFile([]);
      setFile("");
    }
  };
  // console.log(media, "MEDIA")
  const handleCancelButton = () => {
    if (editThread) {
      setEditThread(false);
    }
    if (editCommentForm) {
      setEditCommentForm(false);
    }
    setContent(originalContent);
    setMedia(message.media ?? []);
    setSelectedFile([]);
    setFile("");
  };

  const handleDeleteMedia = (id) => {
    let newMedia = media.filter((file) => file.id !== id);
    setMedia(newMedia);
  };

  const handleUploadFile = (file) => {
    setFile(file);
    const newFiles = Array.from(file);
    const showFiles = selectedFile.concat(newFiles);
    setSelectedFile(showFiles);
  };

  const handleDelete = async (file) => {
    let files = selectedFile.filter(
      (e) => e.lastModified !== file.lastModified
    );
    setFile(files);
    setSelectedFile(files);
  };

  return (
    <Box
      style={{
        paddingBottom: message?.content?.length > 380 ? "50px" : "0px",
        overflow: "hidden",
      }}
      component="form"
      onSubmit={handleSubmitEdit}
    >
      <div
        style={message.content ? { minHeight: "50px" } : { minHeight: "40px" }}
      >
        {message.content ? (
          <>
            {(editThread === true && editingThreadId === message.id) ||
            (editCommentForm === true && editingCommentId === message.id) ? (
              <div style={{ padding: "20px 5px", margin: "10px" }}>
                <ReactQuill
                  theme="snow"
                  value={content}
                  onChange={setContent}
                  placeholder="Contenido"
                  modules={modules}
                  ref={quillRef}
                />
                <FileUploader
                  handleChange={handleUploadFile}
                  name="file"
                  types={fileTypes}
                  multiple={true}
                  label="Elegir archivos o arrastrar aqui"
                  hoverTitle="Arrastrar aqui"
                  children={<CustomFileUploader isMobile={isMobile} />}
                />
                <div
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    {message?.media ? (
                      <div style={{ overflow: "hidden" }}>
                        {media &&
                          media.map((file) => {
                            if (
                              file.mime === "image/jpeg" ||
                              file.mime === "image/png" ||
                              file.mime === "image/bmp" ||
                              file.mime === "image/x-png" ||
                              file.mime === "image/gif" ||
                              file.mime === "video/mp4"
                            ) {
                              return (
                                <div
                                  key={file.id}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    key={file.id}
                                    style={inlineStyles.fileGradientDiv(true)}
                                  >
                                    <div ref={contenidoRef}>
                                      <img
                                        alt="img"
                                        src={`${backendUrl}${file.url}`}
                                        className={
                                          !messageLength
                                            ? [
                                                styles.imgFileThread,
                                                styles.imageclick,
                                              ].join(" ")
                                            : styles.imgFileThread
                                        }
                                        onLoad={(e) => {
                                          imgRefs.current[file.id] = e.target;
                                        }}
                                        onClick={(e) =>
                                          handleImageClick(e, file)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <IconButton>
                                    <DeleteOutlinedIcon
                                      onClick={() => handleDeleteMedia(file.id)}
                                    />
                                  </IconButton>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={file.id}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "220px",
                                      height: "132px",
                                      backgroundColor:
                                        file.mime ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                          ? "rgb(35, 127, 76)"
                                          : file.mime === "application/pdf"
                                          ? "rgb(250, 15, 0)"
                                          : file.mime === "application/zip"
                                          ? "rgb(255, 204, 0)"
                                          : file.mime === "text/html"
                                          ? "rgb(35, 104, 196)"
                                          : "",
                                      borderRadius: "4px",
                                      border: "1px solid #dee2ff",
                                      position: "relative",
                                      margin: "0px 16px 40px 16px",
                                      marginTop:
                                        message.title || message.content
                                          ? "0px"
                                          : "8px",
                                      zIndex: messageLength ? "100" : "1001",
                                    }}
                                    key={file.id}
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          file.mime ===
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            ? excelimage
                                            : file.mime === "application/pdf"
                                            ? pdfimage
                                            : file.mime === "application/zip"
                                            ? zipimage
                                            : wordimage
                                        }
                                        alt=""
                                        style={{
                                          height: "70%",
                                          width: "70%",
                                          objectFit: "contain",
                                          objectPosition: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) =>
                                          handleImageClick(e, file)
                                        }
                                      />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                      <Tooltip title={file.name}>
                                        <p
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "100%",
                                            margin: "10px 0 20px 0",
                                          }}
                                        >
                                          {file.name}
                                        </p>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <IconButton>
                                    <DeleteOutlinedIcon
                                      onClick={() => handleDeleteMedia(file.id)}
                                    />
                                  </IconButton>
                                </div>
                              );
                            }
                          })}
                      </div>
                    ) : null}
                  </div>
                  {selectedFile &&
                    selectedFile.map((file) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p key={file.name}>{file.name}</p>
                        <Button onClick={() => handleDelete(file)}>
                          <DeleteIcon style={{ width: "23px" }} />
                        </Button>
                      </div>
                    ))}
                  <Button
                    color="primary"
                    sx={{
                      marginRight: "10px",
                      backgroundColor: "transparent",
                      color: "var(--lowBlack)",
                      textTransform: "none",
                      padding: "4px 8px",
                      minWidth: "80px",
                      height: "35px",
                      fontSize: "16px",
                      "&: hover": {
                        backgroundColor: "var(--blueLow)",
                      },
                    }}
                    onClick={handleCancelButton}
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    sx={{
                      backgroundColor: "var(--blueFour)",
                      color: "#fcfaff",
                      textTransform: "none",
                      padding: "4px 8px",
                      minWidth: "80px",
                      height: "35px",
                      fontSize: "16px",
                      "&: hover": {
                        backgroundColor: "var(--blue)",
                      },
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            ) : (
              <p
                style={{
                  fontSize: "18px",
                  padding: "8px 16px 20px",
                  maxWidth: "800px",
                  height: "auto",
                  zIndex: "9999",
                  position: "relative",
                }}
                dangerouslySetInnerHTML={{
                  __html: content.replace("<img", "<img width= 100%"),
                }}
                key={message.id}
              />
            )}
          </>
        ) : null}
        {message?.content?.length > 1000 ||
        (message?.media?.length > 2 && !editCommentForm && !editThread) ? (
          <div
            className={
              messageLength
                ? styles.transpGradientClose
                : styles.transpGradientOpen
            }
          >
            {" "}
            <Button
              onClick={() => handleExpand(message.id)}
              style={inlineStyles.viewMoreButtom}
            >
              {!messageLength ? "Cerrar" : "Ver más"}
              <ArrowBackIos
                className={messageLength ? styles.leerMas : styles.cerrar}
              />
            </Button>
          </div>
        ) : null}
      </div>

      {message?.media && !editCommentForm && !editThread ? (
        <div style={{ overflow: "hidden" }}>
          {media &&
            media.map((file) => {
              if (
                file.mime === "image/jpeg" ||
                file.mime === "image/png" ||
                file.mime === "image/bmp" ||
                file.mime === "image/x-png" ||
                file.mime === "image/gif" ||
                file.mime === "video/mp4"
              ) {
                return (
                  <div
                    key={file.id}
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      key={file.id}
                      style={inlineStyles.fileGradientDiv(true)}
                    >
                      <div ref={contenidoRef}>
                        <img
                          alt="img"
                          src={`${backendUrl}${file.url}`}
                          className={
                            !messageLength
                              ? [styles.imgFileThread, styles.imageclick].join(
                                  " "
                                )
                              : styles.imgFileThread
                          }
                          onLoad={(e) => {
                            imgRefs.current[file.id] = e.target;
                          }}
                          onClick={(e) => handleImageClick(e, file)}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={file.id}>
                    <div
                      style={{
                        width: "220px",
                        height: "132px",
                        backgroundColor:
                          file.mime ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ? "rgb(35, 127, 76)"
                            : file.mime === "application/pdf"
                            ? "rgb(250, 15, 0)"
                            : file.mime === "application/zip"
                            ? "rgb(255, 204, 0)"
                            : file.mime === "text/html"
                            ? "rgb(35, 104, 196)"
                            : "",
                        borderRadius: "4px",
                        border: "1px solid #dee2ff",
                        position: "relative",
                        margin: "0px 16px 40px 16px",
                        marginTop:
                          message.title || message.content ? "0px" : "8px",
                        zIndex: messageLength ? "100" : "1001",
                      }}
                      key={file.id}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            file.mime ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ? excelimage
                              : file.mime === "application/pdf"
                              ? pdfimage
                              : file.mime === "application/zip"
                              ? zipimage
                              : wordimage
                          }
                          alt=""
                          style={{
                            height: "70%",
                            width: "70%",
                            objectFit: "contain",
                            objectPosition: "center",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleImageClick(e, file)}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Tooltip title={file.name}>
                          <p
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "100%",
                              margin: "10px 0 20px 0",
                            }}
                          >
                            {file.name}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      ) : null}
    </Box>
  );
};

export default BodyContent;
