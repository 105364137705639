import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { fetchData } from "../../../utils/ApiCall";
import { sortAlphabetically } from "../../../json/CampaignCreator";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const TrackingAndTargeting = ({
  dataForm,
  setDataForm,
  apiUrl,
  isEdit,
  personalTargeting,
  setPersonalTargeting,
  transformedPersonalTargeting,
  setTransformedPersonalTargeting,
}) => {
  const [countriesTargeting, setCountriesTargeting] = useState([]);
  const [countriesTracking, setCountriesTracking] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const restPersonalTargeting = personalTargeting?.slice(1);

  const transformPersonalTargetingData = (personalTargeting) => {
    return personalTargeting?.map((targeting) => {
      const { urls, affiliate_id, country } = targeting;
      return {
        urls: urls?.map((obj) => obj.url) ?? [],
        affiliates: affiliate_id,
        country: country.allow,
      };
    });
  };

  useEffect(() => {
    if (isEdit) {
      const transformedData = transformPersonalTargetingData(
        restPersonalTargeting
      );
      setTransformedPersonalTargeting(transformedData);
    }
  }, [isEdit]);

  // console.log(transformedPersonalTargeting, "TRANSFORMED");

  useEffect(() => {
    const fetchApi = async () => {
      const resCountries = await fetchData(
        `${apiUrl}offer-service/getCountries`
      );
      setCountriesTargeting(sortAlphabetically(resCountries, "title"));
      setCountriesTracking(sortAlphabetically(resCountries, "title"));

      const resAffiliates = await fetchData(
        `${apiUrl}affiliate/getAllAffiliates`
      );
      setAffiliates(resAffiliates);
    };

    fetchApi();
  }, [apiUrl]);

  const handleSetFieldInPersonalTargeting = (index, fieldName, value) => {
    const updatedTransformedPersonalTargeting = [
      ...transformedPersonalTargeting,
    ];
    updatedTransformedPersonalTargeting[index][fieldName] = value;
    setTransformedPersonalTargeting(updatedTransformedPersonalTargeting);
  };

  const handleAddTracking = () => {
    setTransformedPersonalTargeting([
      ...transformedPersonalTargeting,
      { urls: [], affiliates: [], country: [] },
    ]);
  };

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "800px",
      }}
    >
      <div style={{ width: "100%" }}>
        {isEdit && (
          <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
            General Targeting
          </p>
        )}

        <div style={{ width: "100%", padding: isEdit ? "20px" : "0px" }}>
          <div style={{ marginBottom: "20px" }}>
            <TextField
              id="preview-url"
              label="Preview URL"
              variant="standard"
              sx={{ width: "100%" }}
              name="previewUrl"
              onChange={handleChangeDataForm}
              value={dataForm.previewUrl}
            />
          </div>

          <div>
            <Autocomplete
              size="small"
              multiple
              disablePortal
              id="combo-box-countries-targeting"
              options={countriesTargeting}
              getOptionLabel={(countries) => countries?.name}
              sx={{ width: "100%" }}
              value={
                dataForm.countriesTargeting !== null
                  ? dataForm?.countriesTargeting?.map((country) =>
                      countriesTargeting.find((c) => c.code === country)
                    )
                  : []
              }
              onChange={(event, newValue) =>
                setDataForm((prevDataForm) => ({
                  ...prevDataForm,
                  countriesTargeting: newValue.map((item) => item.code),
                }))
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Countries"
                  variant="standard"
                  value={dataForm.countriesTargeting}
                />
              )}
            />
          </div>
        </div>
      </div>

      {isEdit && (
        <div style={{ width: "100%" }}>
          <Divider sx={{ margin: "2em 0px " }} />
          <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
            Personal Targeting
          </p>
          {transformedPersonalTargeting?.map((tracking, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "var(--blueLow)",
                margin: "20px 0",
                padding: "20px",
              }}
            >
              <div style={{ marginBottom: "20px", display: "flex" }}>
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  id={`affiliates-select-${index}`}
                  options={affiliates}
                  getOptionLabel={(aff) => aff?.title}
                  sx={{ width: "385px", marginRight: "30px" }}
                  value={tracking.affiliates.map((affiliateId) =>
                    affiliates.find((aff) => aff.affiseID === affiliateId)
                  )}
                  onChange={(event, newValue) =>
                    handleSetFieldInPersonalTargeting(
                      index,
                      "affiliates",
                      newValue.map((aff) => aff.affiseID)
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Afiliates"
                      variant="standard"
                    />
                  )}
                />
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  id={`country-select-${index}`}
                  options={countriesTracking}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: "385px" }}
                  value={tracking.country.map((countryCode) =>
                    countriesTargeting.find((c) => c.code === countryCode)
                  )}
                  onChange={(event, newValue) =>
                    handleSetFieldInPersonalTargeting(
                      index,
                      "country",
                      newValue.map((c) => c.code)
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries"
                      variant="standard"
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {tracking?.urls && tracking?.urls?.map((urlObj, urlIndex) => (
                  <div key={urlIndex} style={{ marginBottom: "20px" }}>
                    <TextField
                      id={`url-${index}-${urlIndex}`}
                      label={`URL ${urlIndex + 1}`}
                      variant="standard"
                      sx={{ width: "760px" }}
                      value={urlObj}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const updatedTransformedPersonalTargeting = [
                          ...transformedPersonalTargeting,
                        ];
                        updatedTransformedPersonalTargeting[index].urls[
                          urlIndex
                        ] = newValue;
                        setTransformedPersonalTargeting(
                          updatedTransformedPersonalTargeting
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
              <Button
                variant="outlined"
                onClick={() => {
                  const updatedTransformedPersonalTargeting = [
                    ...transformedPersonalTargeting,
                  ];
                  updatedTransformedPersonalTargeting[index].urls.push([]);
                  setTransformedPersonalTargeting(
                    updatedTransformedPersonalTargeting
                  );
                }}
              >
                Agregar URL
              </Button>
            </div>
          ))}
          <ThemeProvider theme={theme}>
            <Button onClick={handleAddTracking}>Agregar Tracking</Button>
          </ThemeProvider>
        </div>
      )}
    </Box>
  );
};

export default TrackingAndTargeting;
