import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { fetchData } from "../../../utils/ApiCall";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  divStyle,
  paymentType,
  sortAlphabetically,
} from "../../../json/CampaignCreator";

const today = new Date();
const minDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate()
);

const MandatoryFieldTab = ({
  isEdit,
  dataForm,
  setDataForm,
  apiUrl,
  header,
  title,
}) => {
  const [advertisers, setAdvertisers] = useState([]);

  useEffect(() => {
    const fetchApi = async () => {
      const resAdvertisers = await fetchData(
        `${apiUrl}advertiser/getAllAdvertisers`
      );
      setAdvertisers(sortAlphabetically(resAdvertisers, "name"));
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAutocompleteChange = (name, newValue) => {
    setDataForm((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue : "",
    }));
  };

  const handleDateChange = (dateRange) => {
    setDataForm((prevState) => ({
      ...prevState,
      dateRange: [
        {
          startDate: dateRange.selection.startDate,
          endDate: dateRange.selection.endDate,
          key: "selection",
        },
      ],
    }));
  };

  const getOptionLabel = (advertiser) => {
    if (advertiser && typeof advertiser === "object" && advertiser.name) {
      return advertiser.name;
    } else if (advertiser && typeof advertiser === "string") {
      return advertiser;
    } else {
      return "INHOUSE TEST";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "800px",
      }}
    >
      <div style={divStyle}>
        <TextField
          id="filled-basic"
          label="Offer Title"
          variant="standard"
          sx={{ width: "385px", marginRight: "30px" }}
          name="offerTitle"
          onChange={handleChangeDataForm}
          value={dataForm.offerTitle}
          required
        />
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={advertisers}
          getOptionLabel={getOptionLabel}
          sx={{ width: "385px" }}
          onChange={(event, newValue) =>
            handleAutocompleteChange("advertisers", newValue)
          }
          value={
            advertisers.find(
              (advertiser) => advertiser.affiseID === dataForm.advertisers
            ) || dataForm.advertisers
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Advertisers"
              variant="standard"
              value={dataForm.advertisers}
              required
            />
          )}
        />
      </div>

      <div style={divStyle}>
        <TextField
          id="filled-basic"
          label="Tracking URL"
          variant="standard"
          sx={{ width: "840px" }}
          name="trackingUrl"
          onChange={handleChangeDataForm}
          value={dataForm.trackingUrl}
          required
        />
      </div>

      {isEdit ? (
        <></>
      ) : (
        <>
          <div style={divStyle}>
            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={paymentType}
              getOptionLabel={(option) => option.name}
              sx={{ width: "385px", marginRight: "30px" }}
              onChange={(event, newValue) =>
                handleAutocompleteChange("paymentType", newValue.value)
              }
              value={
                paymentType.find(
                  (payment) => payment.value === dataForm.paymentType
                ) || dataForm.paymentType
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Type"
                  variant="standard"
                  value={dataForm.paymentType}
                  required
                />
              )}
            />

            <Autocomplete
              disableClearable
              disablePortal
              id="combo-box-demo"
              options={["USD"]}
              getOptionLabel={(option) => option}
              sx={{ width: "385px" }}
              onChange={handleAutocompleteChange}
              value={"USD"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Currencies"
                  variant="standard"
                  value={dataForm.paymentType}
                  required
                />
              )}
            />
          </div>
          <div style={divStyle}>
            <DateRange
              onChange={handleDateChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dataForm.dateRange}
              direction="horizontal"
              minDate={minDate}
              editableDateInputs={true}
            />
          </div>
        </>
      )}
    </Box>
  );
};

export default MandatoryFieldTab;
