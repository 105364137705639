import React from "react";
import { InView } from "react-intersection-observer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BodyContent from "./BodyContent";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";

const ThreadContent = ({
  mouseOverThread,
  positionSeenThread,
  index,
  SeenBy,
  handleMouseOnSeenThread,
  handleMouseLeftSeenThread,
  thread,
  handleImageClick,
  contenidoRef,
  imgRefs,
  handleSeen,
  editThread,
  setEditThread,
  threadState,
  setThreadState,
  positionEdit,
  editingThreadId,
  row,
  key,
  setSelectedUsers,
  setSelectedGroup,
  selectedUsers,
  selectedGroup,
  valueWritten,
  contenidoScroll,
  handleSnackbarOpen,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  function eliminarEtiquetasHTML(textoHTML) {
    var temporal = document.createElement("div");
    temporal.innerHTML = textoHTML;
    return temporal.textContent || temporal.innerText || "";
  }

  return (
    <div style={{ position: "relative" }} key={thread.id}>
      {/* {thread.title ? (
        <h4
          className={styles.threadTitle}
          style={{
            padding: thread.media ? "8px 16px 20px 16px" : "8px 16px 0px",
          }}
        >
          {thread.title}
        </h4>
      ) : (
        <></>
      )} */}
      <InView
        onChange={(inView) => {
          if (inView) {
            handleSeen(thread, "thread");
          }
        }}
        rootMargin={"100px"}
        threshold={1}
        key={thread.id}
      >
        {({ ref }) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div ref={ref}>
              <BodyContent
                contenidoRef={contenidoRef}
                imgRefs={imgRefs}
                message={thread}
                handleImageClick={handleImageClick}
                messageType={"thread"}
                editThread={editThread}
                setEditThread={setEditThread}
                index={index}
                threadState={threadState}
                setThreadState={setThreadState}
                positionEdit={positionEdit}
                editingThreadId={editingThreadId}
                row={row}
                key={key}
                setSelectedUsers={setSelectedUsers}
                setSelectedGroup={setSelectedGroup}
                selectedUsers={selectedUsers}
                selectedGroup={selectedGroup}
                valueWritten={valueWritten}
                contenidoScroll={contenidoScroll}
                // style={{"width": "80%"}}
              />
            </div>
            <CopyToClipboard
              text={eliminarEtiquetasHTML(thread.content)}
              onCopy={handleSnackbarOpen}
            >
              <Tooltip title="Copiar texto">
                <ContentCopyIcon
                  style={{
                    margin: "0px 15px",
                    cursor: "pointer",
                    width: "18px",
                    zIndex: "1000",
                  }}
                />
              </Tooltip>
            </CopyToClipboard>
          </div>
        )}
      </InView>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
        }}
      >
        {mouseOverThread && positionSeenThread === index ? (
          <div style={{ position: "relative", zIndex: "1001" }}>
            <SeenBy
              seenby={[thread?.seen_by]}
              key={{ key }}
              backendUrl={{ backendUrl }}
              // style = {{styleSeenThread}}
            />{" "}
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            minWidth: "200px",
            zIndex: "1001",
          }}
        >
          {thread?.likes?.length > 0 && (
            <p
              style={{
                color: "var(--blackLow)",
              }}
            >
              A{" "}
              <span
                style={{
                  color: "var(--blackLow)",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                {thread.likes.length}
              </span>{" "}
              {thread?.likes.length === 1
                ? "persona le gusta"
                : "personas les gusta"}
            </p>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            zIndex: "1001",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "default",
            }}
            onMouseOver={() => handleMouseOnSeenThread(index)}
            onMouseLeave={handleMouseLeftSeenThread}
          >
            <VisibilityOutlinedIcon
              style={{
                color: "var(--gray)",
                marginRight: "6px",
                fontSize: "18px",
                marginTop: "3px",
              }}
            />

            <p
              style={{
                color: "var(--blackLow)",
              }}
            >
              Visto por{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {thread?.seen_by?.length}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadContent;
