import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  divStyle,
  paymentType,
  sortAlphabetically,
} from "../../../json/CampaignCreator";
import { fetchData } from "../../../utils/ApiCall";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const Payouts = ({
  dataForm,
  setDataForm,
  apiUrl,
  isEdit,
  personalPayout,
  setPersonalPayout,
}) => {
  const [countriesPayout, setCountriesPayout] = useState([]);
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    const fetchApi = async () => {
      const resCountries = await fetchData(
        `${apiUrl}offer-service/getCountries`
      );
      setCountriesPayout(sortAlphabetically(resCountries, "title"));

      const resAffiliates = await fetchData(
        `${apiUrl}affiliate/getAllAffiliates`
      );
      setAffiliates(resAffiliates);
    };

    fetchApi();
  }, [apiUrl]);

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleMultipleAutocompleteChange = (name, newValue) => {
    const selectedCode = newValue.map((item) => item.code);

    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [name]: selectedCode,
    }));
  };

  const handleAutocompleteChange = (name, newValue) => {
    setDataForm((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue : "",
    }));
  };

  const handleSetFieldInPersonalPayout = (e, index, fieldName) => {
    const updatedPersonalPayout = personalPayout.map((payout, i) => {
      if (i === index) {
        return {
          ...payout,
          [fieldName]: e.target.value,
        };
      }
      return payout;
    });
    setPersonalPayout(updatedPersonalPayout);
  };

  const handleAddPayout = () => {
    setPersonalPayout([
      ...personalPayout,
      {
        countries: [],
        type: "",
        total: "",
        revenue: "",
        currency: "usd",
        value: "",
        title: "",
        partners: [],
      },
    ]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "800px",
      }}
    >
      {!isEdit ? (
        <>
          <div style={divStyle}>
            <TextField
              id="filled-basic"
              label="Revenue"
              variant="standard"
              sx={{ width: "385px", marginRight: "30px" }}
              name="revenue"
              onChange={handleChangeDataForm}
              value={dataForm.revenue}
              type="number"
            />

            <TextField
              id="filled-basic"
              label="Payouts"
              variant="standard"
              sx={{ width: "385px" }}
              name="payouts"
              onChange={handleChangeDataForm}
              value={dataForm.payouts}
              type="number"
            />
          </div>

          <div style={divStyle}>
            <TextField
              id="filled-basic"
              label="Goal Value"
              variant="standard"
              sx={{ width: "385px", marginRight: "30px" }}
              name="goalValue"
              onChange={handleChangeDataForm}
              value={dataForm.goalValue}
              type="number"
            />

            <TextField
              id="filled-basic"
              label="Goal Title"
              variant="standard"
              sx={{ width: "385px" }}
              name="goalTitle"
              onChange={handleChangeDataForm}
              value={dataForm.goalTitle}
            />
          </div>

          <div style={divStyle}>
            <Autocomplete
              size="small"
              multiple
              disablePortal
              id="combo-box-demo"
              options={countriesPayout}
              getOptionLabel={(countries) => countries?.name}
              sx={{ width: "800px" }}
              onChange={(event, newValue) =>
                handleMultipleAutocompleteChange("countriesPayout", newValue)
              }
              value={
                dataForm.countriesPayout !== null
                  ? dataForm.countriesPayout.map((country) =>
                      countriesPayout.find((c) => c.code === country)
                    )
                  : []
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Countries"
                  variant="standard"
                  value={dataForm.countriesPayout}
                />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ width: "100%" }}>
            <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
              General Payouts
            </p>

            <div style={{ padding: "20px" }}>
              <div style={divStyle}>
                <TextField
                  id="filled-basic"
                  label="Revenue"
                  variant="standard"
                  sx={{ width: "385px", marginRight: "30px" }}
                  name="revenue"
                  onChange={handleChangeDataForm}
                  value={dataForm.revenue}
                  type="number"
                />

                <TextField
                  id="filled-basic"
                  label="Payouts"
                  variant="standard"
                  sx={{ width: "385px" }}
                  name="payouts"
                  onChange={handleChangeDataForm}
                  value={dataForm.payouts}
                  type="number"
                />
              </div>
              <div style={divStyle}>
                <TextField
                  id="filled-basic"
                  label="Goal Value"
                  variant="standard"
                  sx={{ width: "385px", marginRight: "30px" }}
                  name="goalValue"
                  onChange={handleChangeDataForm}
                  value={dataForm.goalValue}
                  type="number"
                />

                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={paymentType}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "385px" }}
                  onChange={(event, newValue) =>
                    handleAutocompleteChange("paymentType", newValue.value)
                  }
                  value={
                    paymentType.find(
                      (payment) => payment.value === dataForm.paymentType
                    ) || dataForm.paymentType
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payment Type"
                      variant="standard"
                      value={dataForm.paymentType}
                      required
                    />
                  )}
                />
              </div>
              <div style={divStyle}>
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={countriesPayout}
                  getOptionLabel={(countries) => countries?.name}
                  sx={{ width: "800px" }}
                  onChange={(event, newValue) =>
                    handleMultipleAutocompleteChange(
                      "countriesPayout",
                      newValue
                    )
                  }
                  value={
                    dataForm.countriesPayout !== null
                      ? dataForm.countriesPayout.map((country) =>
                          countriesPayout.find((c) => c.code === country)
                        )
                      : []
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries Payout"
                      variant="standard"
                      value={dataForm.countriesPayout}
                    />
                  )}
                />
              </div>
            </div>

            <Divider sx={{ margin: "2em 0px" }} />
          </div>
          <div style={{ zIndex: "999", width: "100%" }}>
            <p style={{ fontWeight: "bold" }}> Personal payouts</p>

            {personalPayout?.map((payout, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "var(--blueLow)",
                  margin: "20px 0",
                  padding: "20px",
                }}
              >
                <div style={divStyle}>
                  <Autocomplete
                    size="small"
                    multiple
                    disablePortal
                    id={`country-select-${index}`}
                    options={countriesPayout}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: "385px", marginRight: "30px" }}
                    onChange={(event, newValue) =>
                      handleSetFieldInPersonalPayout(
                        {
                          target: {
                            value: newValue.map((country) => country.code),
                          },
                        },
                        index,
                        "countries"
                      )
                    }
                    value={personalPayout[index].countries.map((code) =>
                      countriesPayout.find((country) => country.code === code)
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="standard"
                      />
                    )}
                  />

                  <Autocomplete
                    size="small"
                    multiple
                    disablePortal
                    id={`afiliates-select-${index}`}
                    options={affiliates}
                    getOptionLabel={(aff) => aff?.title}
                    sx={{ width: "385px" }}
                    value={
                      personalPayout[index].partners
                        ? personalPayout[index].partners.map((affiseID) =>
                            affiliates.find(
                              (affiliate) => affiliate.affiseID === affiseID
                            )
                          )
                        : []
                    }
                    onChange={(event, newValue) => {
                      const selectedAffiliateIDs = newValue.map(
                        (affiliate) => affiliate.affiseID
                      );
                      handleSetFieldInPersonalPayout(
                        {
                          target: {
                            value: selectedAffiliateIDs,
                          },
                        },
                        index,
                        "partners"
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Afiliates"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div style={divStyle}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id={`payment-type-autocomplete-${index}`}
                    options={paymentType}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "385px", marginRight: "30px" }}
                    onChange={(event, newValue) =>
                      handleSetFieldInPersonalPayout(
                        {
                          target: {
                            value: newValue.value,
                          },
                        },
                        index,
                        "type"
                      )
                    }
                    value={
                      personalPayout[index].type
                        ? paymentType.find(
                            (payment) =>
                              payment.value === personalPayout[index].type
                          )
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Type"
                        variant="standard"
                        required
                      />
                    )}
                  />

                  <TextField
                    label="Revenue"
                    value={personalPayout[index].total}
                    onChange={(e) =>
                      handleSetFieldInPersonalPayout(e, index, "total")
                    }
                    type="number"
                    sx={{ width: "385px" }}
                    variant="standard"
                  />
                </div>

                <div style={divStyle}>
                  <TextField
                    label="Payouts"
                    value={personalPayout[index].revenue}
                    onChange={(e) =>
                      handleSetFieldInPersonalPayout(e, index, "revenue")
                    }
                    sx={{ width: "385px", marginRight: "30px" }}
                    type="number"
                    variant="standard"
                  />
                  <TextField
                    label="Goal Value"
                    value={personalPayout[index].goal}
                    sx={{ width: "385px" }}
                    onChange={(e) =>
                      handleSetFieldInPersonalPayout(e, index, "goal")
                    }
                    type="number"
                    variant="standard"
                  />
                </div>
              </div>
            ))}
            <ThemeProvider theme={theme}>
              <Button onClick={handleAddPayout} sx={{ zIndex: "9999" }}>
                Agregar Payout
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </Box>
  );
};

export default Payouts;
