import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/CampaignCreator.module.css";
import { v4 as uuidv4 } from "uuid";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const LandingPages = ({ dataLanding, setDataLanding }) => {
  const [formLanding, setFormLanding] = useState({
    title: "",
    url: "",
    url_preview: "",
    id: "",
  });

  const [modalLanding, setModalLanding] = useState(false);
  const [modalDeleteLanding, setModalDeleteLanding] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const openCloseModalLanding = () => {
    setModalLanding(!modalLanding);
    setFormLanding({
      title: "",
      url: "",
      url_preview: "",
      id: "",
    });
    setIsEditMode(false);
  };

  const selectLanding = (landing, action) => {
    if (action === "Editar") {
      setFormLanding(landing);
      setIsEditMode(true);
      setModalLanding(true);
    } else if (action === "Eliminar") {
      setFormLanding(landing);
      setModalDeleteLanding(true);
    }
  };

  const openCloseModalDeleteLanding = () => {
    setModalDeleteLanding(!modalDeleteLanding);
  };

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target;
    setFormLanding((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const postLanding = () => {
    if (
      formLanding.title !== "" &&
      formLanding.url !== "" &&
      formLanding.url_preview !== ""
    ) {
      if (isEditMode) {
        const updatedDataLanding = dataLanding.map((landing) =>
          landing.id === formLanding.id ? formLanding : landing
        );
        setDataLanding(updatedDataLanding);
      } else {
        setDataLanding((prevDataLandings) => [
          ...prevDataLandings,
          { ...formLanding, id: uuidv4() },
        ]);
      }
      openCloseModalLanding();
    } else {
      alert("Debes completar todos los campos");
    }
  };

  const exterminateLanding = () => {
    const removeData = dataLanding.filter((el) => el.id !== formLanding.id);
    setDataLanding(removeData);
    openCloseModalDeleteLanding();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "800px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Button
            onClick={openCloseModalLanding}
            sx={{
              marginBottom: "10px",
              width: "180px",
            }}
            variant="contained"
          >
            Nueva Landing
          </Button>
        </ThemeProvider>

        <br />
        <TableContainer style={{ width: "100%" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Tracking URL</TableCell>
                <TableCell>Preview URL</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataLanding?.map((el) => (
                <TableRow key={el.id}>
                  <TableCell>{el.title}</TableCell>
                  <TableCell>{el.url}</TableCell>
                  <TableCell>{el.url_preview}</TableCell>
                  <TableCell>
                    <Tooltip title="Editar landing">
                      <IconButton onClick={() => selectLanding(el, "Editar")}>
                        <Edit
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar landing">
                      <IconButton onClick={() => selectLanding(el, "Eliminar")}>
                        <Delete style={{ cursor: "pointer" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={modalLanding} onClose={openCloseModalLanding}>
          <div className={styles.wrapperCap}>
            <h3 style={{ fontWeight: "600" }}>
              {isEditMode ? "Editar Landing" : "Nueva Landing"}
            </h3>
            <br />
            <TextField
              style={{ width: "100%" }}
              label="Title"
              name="title"
              onChange={handleChangeDataForm}
              value={formLanding.title}
              variant="standard"
            />
            <TextField
              style={{ width: "100%", marginTop: "26px" }}
              label="Tracking URL"
              name="url"
              onChange={handleChangeDataForm}
              value={formLanding.url}
              variant="standard"
            />
            <TextField
              style={{ width: "100%", marginTop: "26px", marginBottom: "26px" }}
              label="Preview URL"
              name="url_preview"
              onChange={handleChangeDataForm}
              value={formLanding.url_preview}
              variant="standard"
            />
            <br /> <br />
            <div align="right">
              <ThemeProvider theme={theme}>
                <Button
                  color="primary"
                  onClick={postLanding}
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                >
                  {isEditMode ? "Guardar cambios" : "Agregar"}
                </Button>
              </ThemeProvider>

              <Button color="primary" onClick={openCloseModalLanding}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>

        <Modal open={modalDeleteLanding} onClose={openCloseModalDeleteLanding}>
          <div
            style={{
              position: "absolute",
              width: 600,
              backgroundColor: "white",
              padding: "16px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <h2>¿Estás seguro que deseas eliminar esta Landing?</h2>
            <div align="center" style={{ marginTop: "30px" }}>
              <Button
                color="primary"
                onClick={openCloseModalDeleteLanding}
                variant="outlined"
                sx={{ marginRight: "10px" }}
              >
                Cancelar
              </Button>
              <Button
                color="error"
                onClick={exterminateLanding}
                variant="contained"
              >
                Eliminar
              </Button>
            </div>
          </div>
        </Modal>
      </Box>
    </>
  );
};

export default LandingPages;
