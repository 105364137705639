import React, { useEffect, useState } from "react";
import container from "../styles/Global.module.css";
import HomeDashBoard from "../pages/BusinessUnit/aluna/dashboards/HomeDashBoard"


const Home = () => {

  const [itAllowed, setItAllowed] = useState(null);
  const [allowDashboard, setAllowDashboard] = useState(false);

 

  useEffect(() => {
    setItAllowed(localStorage?.getItem("role"));
   if(itAllowed === "Authenticated" || itAllowed ==="aluna-management"){
    setAllowDashboard(true)
   }
  }, [itAllowed]);


  return (
    <>
    {allowDashboard?
    <HomeDashBoard /> :
    <div className={container.homeContainer}>
      <div className={container.homeImage}>
      <img src="./ionvo.png" alt="aluna" height="200px" width="auto"/>
      </div>
    </div>
}
    </>
  );
};

export default Home;
