import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function FileViewer({ url }) {
  const docs = [
    {
      uri: url,
    },
  ];

  return (
    <DocViewer
      prefetchMethod="GET"
      pluginRenderers={DocViewerRenderers}
      documents={docs}
      config={{
        header: {
          disableHeader: false,
          disableFileName: false,
          retainURLParams: false,
        },
      }}
      style={{ width: "auto" }}
      className="fileviewer"
    />
  );
}
