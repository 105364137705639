import React from "react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { Home } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import SideBar from "./SideBar";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.replace("/", "");
  const { toggleSidebar } = useProSidebar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [openMenu, setOpenMenu] = useState(false);
  const role = localStorage?.getItem("role");

  function PageNameConvert(str) {
    var words = str.split("-");
    var transformedString = words
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return transformedString;
  }

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("session");
    localStorage.removeItem("role");
    navigate(`/`, { replace: true });
  };

  const handleOpenSideBar = () => {
    toggleSidebar(true); // Optional: Open the sidebar using the toggleSidebar function from react-pro-sidebar
  };
  return (
    <>
      {openMenu && <SideBar openMenu={openMenu} setOpenMenu={setOpenMenu} />}
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        alignItems="center"
        width="100%"
        borderBottom="1px solid #dee2ff"
        background="#fefcfd"
      >
        {" "}
        {isMobile && <Button onClick={handleOpenSideBar}>Abrir</Button>}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/home">
            <IconButton>
              <Home />
            </IconButton>
          </Link>
          <h3 style={{ fontWeight: "500" }}>{PageNameConvert(pageName)}</h3>
        </Box>
        <Box display="flex">
          {/* <IconButton onClick={handleClickNotifications}>
            <NotificationsOutlined />
          </IconButton> */}

          {/* {notificationsOpen && (
            <Notifications open={handleOpen} onClose={handleClose} />
          )} */}
          {/* <Link to="/configuracion">
            <IconButton>
              <SettingsOutlined />
            </IconButton>
          </Link> */}
          {/* <Link to="/perfil">
            <IconButton>
              <PersonOutline />
            </IconButton>
          </Link> */}
          {role && role === "Authenticated" ? (
            <Link to="/admin">
              <IconButton>
                <AdminPanelSettingsIcon />
              </IconButton>
            </Link>
          ) : null}

          <IconButton onClick={logout}>
            <PowerSettingsNewOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default NavBar;
