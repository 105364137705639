import React from "react";
import SaveIcon from "@mui/icons-material/Save";

import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import RowCreator from "../../pages/global/RowCreator/RowCreator";

const CustomToolBar = ({ apiRef, selectedView, pathname, updateBoard }) => {
  const handleAddFilter = () => {
    let filter = apiRef.current
      .exportState()
      .filter.filterModel.items?.map((e) => e);
    let logicOperator =
      apiRef.current.exportState().filter.filterModel.logicOperator;
    let columns = apiRef.current.exportState().columns.columnVisibilityModel;
    let density = apiRef.current.state.density.value;

    let pinnedColumns = apiRef.current.exportState().pinnedColumns;

    let views = [
      {
        filters: {
          items: filter,
          logicOperator,
        },
        columns,
        density,
        pinnedColumns,
      },
    ];

    localStorage.setItem("views", JSON.stringify(views));
  };

  return (
    <GridToolbarContainer
      style={{
        display: "flex",
        justifyContent: "space-Between",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {selectedView === localStorage.email ? (
          <Button onClick={() => handleAddFilter()}>
            Guardar mi filtro
            <SaveIcon />
          </Button>
        ) : (
          <></>
        )}
        {(pathname === "/tasks-aluna" ||
          pathname === "/inhouse-media-buying" ||
          pathname === "/prospecting-afiliado" ||
          pathname === "/info-trafico-afiliado" ||
          pathname === "/solicitud-campana" ||
          pathname === "/validaciones-cierre-aluna" ||
          pathname === "/it-request" ||
          pathname === "/master-tag-roundler" ||
          pathname === "/crm" ||
          pathname === "/prospecting") && (
          <RowCreator pathname={pathname} updateBoard={updateBoard} />
        )}
      </Box>
      <div>
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(",").map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) =>
            quickFilterValues.join(", ")
          }
          debounceMs={1000} // time before applying the new quick filter value
        />
      </div>
    </GridToolbarContainer>
  );
};

export default CustomToolBar;
