import { Stack } from "@mui/material";
import React from "react";
import loadingimage from "../../assets/Ellipsis-2s-200px.svg";
function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src={loadingimage} alt="loading" />
    </Stack>
  );
}

export default NoRowsOverlay;
