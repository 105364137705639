import { Box } from "@mui/material";
import React from "react";
import MandatoryFieldTab from "./MandatoryFieldTab";
import GeneralTab from "./GeneralTab";
import TrackingAndTargeting from "./TrackingAndTargeting";
import Payouts from "./Payouts";
import Caps from "./Caps";
import LandingPages from "./LandingPages";
import Logo from "./Logo";
import Creatives from "./Creatives";
import Kala from "./Kala";

const FormTabs = ({
  isEdit,
  activeStep,
  dataForm,
  setDataForm,
  apiUrl,
  header,
  dataCaps,
  setDataCaps,
  dataLanding,
  setDataLanding,
  logo,
  setLogo,
  oldCreatives,
  setCreatives,
  newCreatives,
  setNewCreatives,
  creatives,
  offerId,
  bat,
  setBat,
  direct,
  setDirect,
  checkboxBat,
  setCheckboxBat,
  checkboxDirect,
  setCheckboxDirect,
  descriptionCreatives,
  setDescriptionCreatives,
  descriptionTargeting,
  setDescriptionTargeting,
  selectedCategories,
  setSelectedCategories,
  selectedTags,
  setSelectedTags,
  title,
  description,
  setDescription,
  personalPayout,
  setPersonalPayout,
  personalTargeting,
  setPersonalTargeting,
  transformedPersonalTargeting,
  setTransformedPersonalTargeting,
}) => {
  const renderForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <MandatoryFieldTab
            isEdit={isEdit}
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            title={title}
          />
        );
      case 1:
        return (
          <GeneralTab
            isEdit={isEdit}
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            descriptionCreatives={descriptionCreatives}
            setDescriptionCreatives={setDescriptionCreatives}
            descriptionTargeting={descriptionTargeting}
            setDescriptionTargeting={setDescriptionTargeting}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            description={description}
            setDescription={setDescription}
          />
        );
      case 2:
        return (
          <TrackingAndTargeting
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            isEdit={isEdit}
            personalTargeting={personalTargeting}
            setPersonalTargeting={setPersonalTargeting}
            transformedPersonalTargeting={transformedPersonalTargeting}
            setTransformedPersonalTargeting={setTransformedPersonalTargeting}
          />
        );
      case 3:
        return (
          <Payouts
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            isEdit={isEdit}
            personalPayout={personalPayout}
            setPersonalPayout={setPersonalPayout}
          />
        );
      case 4: {
        return isEdit ? (
          <LandingPages
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            dataLanding={dataLanding}
            setDataLanding={setDataLanding}
          />
        ) : (
          <Caps
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            dataCaps={dataCaps}
            setDataCaps={setDataCaps}
          />
        );
      }

      case 5: {
        return isEdit ? (
          <Logo
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            logo={logo}
            setLogo={setLogo}
          />
        ) : (
          <LandingPages
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            dataLanding={dataLanding}
            setDataLanding={setDataLanding}
          />
        );
      }

      case 6: {
        return isEdit ? (
          <Creatives
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            oldCreatives={oldCreatives}
            setCreatives={setCreatives}
            newCreatives={newCreatives}
            setNewCreatives={setNewCreatives}
            offerId={offerId}
          />
        ) : (
          <Logo
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            logo={logo}
            setLogo={setLogo}
          />
        );
      }

      case 7:
        return (
          <Creatives
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            oldCreatives={oldCreatives}
            setCreatives={setCreatives}
            newCreatives={creatives}
            setNewCreatives={setNewCreatives}
            offerId={offerId}
            creatives={creatives}
          />
        );

      case 8:
        return (
          <Kala
            dataForm={dataForm}
            setDataForm={setDataForm}
            apiUrl={apiUrl}
            header={header}
            bat={bat}
            setBat={setBat}
            direct={direct}
            setDirect={setDirect}
            checkboxBat={checkboxBat}
            setCheckboxBat={setCheckboxBat}
            checkboxDirect={checkboxDirect}
            setCheckboxDirect={setCheckboxDirect}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        // minHeight: "750px",
        minWidth: "600px",
        backgroundColor: "white",
        margin: "20px 0 0",
        display: "flex",
        justifyContent: "center",
        padding: "40px ",
        overflow: "hidden",
      }}
    >
      {renderForm()}
    </Box>
  );
};

export default FormTabs;
