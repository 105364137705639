import {
  Box,
  createTheme,
  LinearProgress,
  Menu,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { editDate } from "../../utils/ApiCall";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
require("dayjs/locale/es");

const Duration = ({ row, value, selectionRow }) => {
  let pathname = window.location.pathname.replace("/", "");
  const minDate = new Date();

  // Calculate default date range: from today to the end of the month
  const defaultStartDate = dayjs();
  const defaultEndDate = dayjs().endOf("month");

  const [dateFrom, setDateFrom] = useState(
    row?.dateStart || defaultStartDate.toDate()
  );
  const [dateTo, setDateTo] = useState(row?.dateTo || defaultEndDate.toDate());
  const renderStartDate = dateFrom
    ? dayjs(dateFrom).locale("es").format("MMM D")
    : "";
  const renderEndDate = dateTo
    ? dayjs(dateTo).subtract(1, "day").locale("es").format("MMM D")
    : "";

  const [windowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 600;

  const theme = createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: "50px",
            backgroundColor: "var(--blackLow)",
          },
          bar: {
            backgroundColor: "var(--done)",
          },
        },
      },
    },
  });

  const [{ open }, setState] = useState({
    open: false,
    selectValue: value,
  });

  const cellRef = useRef(null);

  useEffect(() => {
    setTimeout(window.unlock, 100);
  }, []);

  const cellStyle = {
    position: "relative",
    cursor: "pointer",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState((prevState) => ({ ...prevState, open: true }));
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: "auto",
  };

  const menuProps = {
    anchorEl,
    id: "account-menu",
    open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "212px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleMenuOpen = () => {
    const cellRect = cellRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const isMiddle =
      cellRect.top > viewportHeight / 2 &&
      cellRect.bottom < (viewportHeight * 2) / 3;
    const isBottom = cellRect.bottom > (viewportHeight * 2) / 3;
    if (isMiddle || isBottom) {
      menuProps.anchorOrigin.vertical = "top";
      menuProps.transformOrigin.vertical = "bottom";
    } else {
      menuProps.anchorOrigin.vertical = "bottom";
      menuProps.transformOrigin.vertical = "top";
    }
  };

  const handleDateChange = (dateRange) => {
    const [start, end] = dateRange;

    setDateFrom(start?.toDate());
    const newDateTo = dayjs(end?.toDate()).add(1, "day");
    setDateTo(newDateTo.toDate());
    let newDateFrom = dayjs(start?.$d).format("YYYY-MM-DD");

    if (dateFrom === newDateFrom) {
      newDateFrom = "";
    } else {
      newDateFrom = start?.$d;
    }

    if (start?.$d && end?.$d) {
      if (selectionRow.length !== 0) {
        editDate(pathname, newDateFrom, end.$d, selectionRow);
      } else {
        editDate(pathname, newDateFrom, end.$d, [row.id]);
      }
      row.dateStart = start.$d;
      row.dateTo = end.$d;
    }
  };

  const getProgress = () => {
    const currentDate = dayjs();
    const startDate = dayjs(dateFrom);
    const endDate = dayjs(dateTo);

    if (endDate.isBefore(currentDate)) {
      return 100;
    }

    const totalDuration = endDate.diff(startDate, "day");
    const elapsedDuration = currentDate.diff(startDate, "day");
    const progress = (elapsedDuration / totalDuration) * 100;
    return Math.round(progress);
  };

  return (
    <div ref={cellRef} style={cellStyle}>
      <ThemeProvider theme={theme}>
        <LinearProgress
          variant="determinate"
          sx={{ height: "25px", width: "120px", borderRadius: "50px" }}
          value={getProgress()}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "120px",
          }}
          onClick={handleClick}
        >
          <Typography variant="caption" component="div" color="white">
            {row.dateStart && row.dateTo !== null
              ? `${renderStartDate} - ${renderEndDate}`
              : "Sin duración"}
          </Typography>
        </Box>
      </ThemeProvider>
      <Menu {...menuProps} onMouseEnter={handleMenuOpen}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateRangePicker"]}>
            <DateRangePicker
              defaultValue={
                row.dateFrom !== null && row.dateTo !== null
                  ? [dayjs(dateFrom), dayjs(dateTo).subtract(1, "day")]
                  : [defaultStartDate, defaultEndDate]
              }
              disablePast
              onChange={handleDateChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Menu>
    </div>
  );
};

export default Duration;
