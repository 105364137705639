import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";


const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LoginRedirect = (props) => {
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();



  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    const searchPath = location.search;
    const searchparse = searchPath.replace("id_token", "access_token");
    fetch(
      `${backendUrl}/api/auth/${params.providerName}/callback${searchparse}`
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        let headersList = {
          "Accept": "*/*",
          "Authorization": `Bearer ${res.jwt}`
        }
        let reqOptions = {
          url: `${backendUrl}/api/users/${res.user.id}?populate=role&populate=groups`,
          method: "GET",
          headers: headersList,
        }
        
        let response = await axios.request(reqOptions);
        let role = response.data.role.name
        let groups = response.data.groups.map((group) => {return group.name})

        const session = Date.now()

        localStorage.setItem("jwt", res.jwt);
        localStorage.setItem("username", res.user.username);
        localStorage.setItem("email", res.user.email);
        localStorage.setItem("id", res.user.id);
        localStorage.setItem("session", session);
        localStorage.setItem("role", role);
        localStorage.setItem("groups", groups);
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );
        navigate(`/home`, { replace: true }); // Redirect to homepage after 3 sec
      })
      .catch((err) => {
        console.log(err);
        setText("An error occurred, please see the developer console.");
      });
  }, [navigate, location.search, params.providerName]);

  return <p>{text}</p>;
};

export default LoginRedirect;
