import React from "react";
import Header from "../../../../components/global/Header";
import container from "../../../../styles/Global.module.css";
import { useState } from "react";
import Threads from "../../../Threads/Threads";
import { countries } from "../../../../json/Countries";
import { Divider, Tooltip } from "@mui/material";
import ViewsCPS from "../../../others/ViewsCPS";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import {
  csmStatusCPSOptions,
  afiliacionStatusCPSOptions,
  offerStatusCPSOptions,
  pixelStatus,
} from "../../../../json/StatusOptions";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/ColumnStatus";
import EditableText from "../../../../components/global/EditableText";
import { getStatus } from "../../../../utils/ApiCall";
import EditableSelectMultiple from "../../../../components/global/EditableSelectMultiple";
import { otherOptions } from "../../../../json/CampaignCreator";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AlunaMasterCPS = () => {
  const [groups, setGroups] = useState([
    "Offer ID",
    "Inactive",
    "Pixel Integration",
  ]);
  const [offerID, setOfferID] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [pixelIntegration, setPixelIntegration] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tableByParams = searchParams.get("table");
  const [statusByParams] = useState(tableByParams ? tableByParams : "Offer ID");
  const [status, setStatus] = useState(statusByParams);
  const apiUrl = `${backendUrl}/api/master-cps/items?status=${status}`;
  const [selectedView, setSelectedView] = useState("tabla-principal");
  const [selectionRow, setSelectionRow] = useState([]);

  const [viewFilter, setViewFilter] = useState({
    items: [],
    quickFilterLogicOperator: GridLogicOperator.Or,
  });
  const [columnsFilter, setColumnsFilter] = useState({
    revenueRetargeting: false,
    id: false,
  });
  const [pinnedColumns, setPinnedColumns] = useState({
    left: [ "__check__", "id", "offerID", "threads", "offerTitle" ],
    right: []
  });
  const [modalThreadOpen, setModalThreadOpen] = useState(false);
  const [initialViews] = useState(true);

  const mapFilterOptionsCode = (options) => {
    const filterOptions = options.map((option) => {
      return option.code;
    });
    return filterOptions;
  };

  const [updateRows, setUpdateRows] = useState([]);

  const filterItemsByStatus = (items, status) => {
    let itemsFiltered = items.filter(item => {
      if (item.offerStatus === status) return true
      else if (status === "Offer ID" && item.offerStatus !== "INACTIVE" && item.offerStatus !== "PIXEL INTEGRATION") {
        return true
      }
      return false
    })
    return itemsFiltered
  };

  const filterItemsAndSetGroups = async (items) => {
    setOfferID(filterItemsByStatus(await items, "Offer ID"));
    setInactive(filterItemsByStatus(items, "INACTIVE"));
    setPixelIntegration(filterItemsByStatus(items, "PIXEL INTEGRATION"));
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "master-cps");
    } else {
      status = newValue;
    }

    if (status === "PIXEL INTEGRATION") {
      const updatedRowsPixelI = pixelIntegration.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setPixelIntegration(updatedRowsPixelI);
    } else if (status === "INACTIVE") {
      const updatedRowsInactives = inactive.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setInactive(updatedRowsInactives);
    } else {
      const updatedRowsOfferID = offerID.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      setOfferID(updatedRowsOfferID);
    }
  };

  const getGroupRows = (groupName) => {
    return groupName === "Offer ID"
      ? offerID
      : groupName === "Inactive"
        ? inactive
        : groupName === "Pixel Integration"
          ? pixelIntegration
          : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...updateRows, newData];

    setUpdateRows(updatedRows);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return (
          <Threads
            {...params}
            modalThreadOpen={modalThreadOpen}
            setModalThreadOpen={setModalThreadOpen}
            row={params.row}
          />
        );
      },
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerStatus",
      headerName: "OFFER Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"offerStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={offerStatusCPSOptions}
        />
      ),
    },
    {
      field: "other",
      headerName: "Other",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"other"}
          handleEditResponseChange={handleEditResponseChange}
          options={otherOptions.map((opt) => opt.title)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.other}
          label="Other"
        />
      ),
    },
    {
      field: "csmStatus",
      headerName: "CSM Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"csmStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={csmStatusCPSOptions}
        />
      ),
    },
    {
      field: "affiliacionStatus",
      headerName: "Affiliacion Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"affiliacionStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={afiliacionStatusCPSOptions}
        />
      ),
    },
    {
      field: "pixelStatus",
      headerName: "Verificación de Pixel",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={selectionRow}
          field={"pixelStatus"}
          columnPosition={122}
          optionsAmount={"low"}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={pixelStatus}
        />
      ),
    },
    {
      field: "advertiserName",
      headerName: "Advertiser Name",
      width: 160,
      headerAlign: "center",
      cellClassName: "cell-content",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip
            title={params.value}
          >
            <p className="cell-data">{params.value}</p>
          </Tooltip>        </div>
      ),
    },
    {
      field: "typeOfClient",
      headerName: "Tipo de cliente",
      width: 120,
      headerAlign: "center",
      cellClassName: "cell-content",
      align: "center",

      renderCell: (params) => (
        <div>
          <p className="cell-data">{params.value}</p>
        </div>
      ),
    },
    {
      field: "encargadaCsm",
      headerName: "Encargada CSM",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div>
          <p className="cell-data">{params.value}</p>
        </div>
      ),
    },
    {
      field: "affiseLink",
      headerName: "Affise Link",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip
            title={params.value}
          >

            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "var(--blueFour)" }}
            >
              {params.value}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "BAT_Preview",
      headerName: "BAT/Preview",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div>
          <p className="cell-data">{params.value}</p>
        </div>
      ),
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <Tooltip
            title={params.value}
          >
            <p className="cell-data">{params.value}</p>
          </Tooltip>        
          </div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptionsCode(countries),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          {countries.map((el) => {
            if (params?.value === el.label || params?.value === el.code) {
              return (
                <>
                  <img
                    src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                    style={{ marginRight: "8px" }}
                    alt="img"
                  />
                </>
              );
            } else return null;
          })}
          {countries.map((el) => {
            if (
              params?.value?.toLowerCase() === el.label.toLowerCase() ||
              params?.value?.toLowerCase() === el.code.toLowerCase()
            ) {
              return <p>{el.code}</p>;
            } else return null;
          })}
        </div>
      ),
    },

    {
      field: "vertical",
      headerName: "Vertical",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip
            title={params.value}
          >
            <p className="cell-data">{params.value}</p>
          </Tooltip>        </div>
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Sources Allowed",
      width: 220,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip
            title={params.value}
          >
            <p className="cell-data">{params.value}</p>
          </Tooltip>        </div>
      ),
    },
    {
      field: "revenueRetargeting",
      headerName: "Revenue Retargeting",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "monedaAnunciante",
      headerName: "Moneda Anunciante",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "payout",
      headerName: "Payout",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "previewLink",
      headerName: "Preview Link",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "var(--blueFour)" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 160,

      align: "center",
      headerAlign: "center",
    },
    {
      field: "timeToValidation",
      headerName: "Tiempo de validación",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"timeToValidation"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params?.row?.timeToValidation}
        />
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "revMaximo",
      headerName: "Revenue Máximo",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiseClicks",
      headerName: "Affise clicks",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingPayout",
      headerName: "Pending payout",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingRevenue",
      headerName: "Pending revenue",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingQty",
      headerName: "Pending QTY",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ticketPromedio",
      headerName: "Ticket Promedio",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"ticketPromedio"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.ticketPromedio}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <Header title="" />
      <ViewsCPS
        setViewFilter={setViewFilter}
        setColumnsFilter={setColumnsFilter}
        setTables={setGroups}
        initialViews={initialViews}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        setPinnedColumns={setPinnedColumns}
      />
      <Divider sx={{ marginBottom: "20px" }} />
      <BaseBoard
        columns={columns}
        groups={groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={status}
        setStatus={setStatus}
        updateBoard={updateBoard}
        updateRows={updateRows}
        setSelectionRow={setSelectionRow}
        selectionRow={selectionRow}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columnsFilter={columnsFilter}
        setColumnsFilter={setColumnsFilter}
        pinnedColumns={pinnedColumns}
        setPinnedColumns={setPinnedColumns}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default AlunaMasterCPS;
