import React from "react";
import { IconButton, Breadcrumbs } from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileViewer from "../../components/global/FileViewer";
import { saveAs } from "file-saver";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import styles from "../../styles/Threads.module.css";

const ModalImage = ({
  setModalImage,
  modalImage,
  row,
  backendUrl,
  pathname,
}) => {
  const handleDownload = async () => {
    const { url, name } = modalImage;
    const fileUrl = `${backendUrl}${url}`;
    saveAs(fileUrl, name);
  };
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        onClick={() => setModalImage(null)}
      ></div>

      <div
        style={{
          width: "95vw",
          height: "82vh",
          backgroundColor: "#fcfaff",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 4%)",
          borderRadius: "25px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "#fcfaff",
            borderBottom: "1px solid #dee2ff",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <ImageOutlinedIcon sx={{ color: "var(--blue)", fontSize: "50px" }} />
          <div style={{ marginLeft: "8px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p style={{ fontWeight: "600" }}>
                {modalImage?.url?.split("/").slice(-1)}
              </p>
              <CloudDownloadOutlinedIcon
                onClick={handleDownload}
                className={styles.downloadIcon}
              />
            </div>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <div underline="hover" color="inherit" href="/" disabled>
                  {pathname}
                </div>
                <div underline="hover" color="inherit" href="/">
                  {row.offerID}
                </div>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => setModalImage(null)}
        >
          <IconButton>
            <CloseIcon />
          </IconButton>
        </div>
        {/* <button onClick={()=>handleDownload(modalImage.url, `${modalImage.name}${modalImage.ext}`)}>Descargar archivo</button>; */}

        <FileViewer url={`${backendUrl}${modalImage.url}`} />
      </div>
    </>
  );
};

export default ModalImage;
